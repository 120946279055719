/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from "mobx-react";
import { store } from 'Models/Store';
import { Link } from 'react-router-dom';

export interface ISecuredAdminPageProps {
	canDo: boolean;
}

@observer
export default class SecuredAdminPage extends React.Component<ISecuredAdminPageProps, any> {

	public render() {
		const { canDo } = this.props;
		if (!canDo){
			return (
				<section>
					Access denied. Click <Link to="/admin">Here</Link> to return to the admin main page
				</section>
			);
		}
		else{
			return this.props.children;
		}
	}
}