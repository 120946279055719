import React, { ChangeEvent, FC, useEffect } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

import { useObserver, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import moment from 'moment';
import alert from '../../Util/ToastifyUtils';
import { RadiologistEntity } from '../../Models/Entities';
import { store } from '../../Models/Store';
import { ILOReportState } from "./ProduceILOReportTile";

interface AdjudicationProps {
	readOnly: boolean;
	reportStateObject?: string;
	radiologistId?: string;
	dateOfBirth?: string;
	firstName?: string;
	lastName?: string;
	completedDate?: string;
	urgent?: boolean;
	previewFunc?: (reportState: ILOReportState) => void;
	openAccordionItemFunc?: (itemNumber: string[]) => void;
	openTabOverride?: number;
	furtherReads?: () => void;
	reportCount?: number;
	backToList?: () => void;
}

enum AdjudicationPageList {
	FormPage,
	DetailsPage,
}

const getJsonReportState = (reportStateObject: string): ILOReportState => {
    let lowerCaseKeyReportState: string = reportStateObject;
    JSON.parse(reportStateObject, (key, value) => {
        let lowerCaseFirstChar: string = key.slice(0, 1);
        if (lowerCaseFirstChar === lowerCaseFirstChar.toLowerCase() || key === "READ_DATE") {
            return value;
        }
        let lowerCaseKey: string = lowerCaseFirstChar.toLowerCase() + key.slice(1, key.length);
        lowerCaseKeyReportState = lowerCaseKeyReportState.replace("\"" + key + "\":", "\"" + lowerCaseKey + "\":");
        return value;
    });
    return JSON.parse(lowerCaseKeyReportState);
}

const AdjudicationForm: FC<AdjudicationProps> = (
	{
		reportStateObject, 
		readOnly,
		dateOfBirth,
		firstName,
		lastName,
		radiologistId,
		completedDate,
		urgent,
		previewFunc,
		openAccordionItemFunc,
		openTabOverride,
		furtherReads,
		reportCount,
		backToList,
	},
) => {
	const reportState: ILOReportState = useLocalStore(() => {
		if (reportStateObject && readOnly) {
			return getJsonReportState(reportStateObject);
		}
		
		return ({
			imageQuality: 0,
			imageDefects: [],
			imageDefectsOther: '',
			parenchymalAbnormalities: false,
			smallOpacities: {
				shapePrimary: '',
				shapeSecondary: '',
				zones: [],
				profusion: '',
			},
			largeOpacities: '',
			pleuralAbnormalities: false,
			pleuralPlaques: {
				site: [],
				calcification: [],
				extent: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
				width: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
			},
			costophrenicAngleObliteration: ['No'],
			costophrenicAngleObliterations: {
				site: [],
				calcification: [],
				extent: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
				width: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
			},
			approveStudy: false,
			otherAbnormalities: false,
			otherSymbols: [],
			otherComments: "",
			seePhysician: 'No',
			dateDoctorNotified: null,
			otherAbnormalitiesList: [],
		});
	});
	
	const adjudicationPage = useLocalStore(() => ({
		currentPage: AdjudicationPageList.FormPage,
		radiologistName: '',
	}));
	
	const setRadiologistName = action(() => {
		store.apolloClient.query({
			query: RadiologistEntity.getFetchSingleQueryProfilePage(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: radiologistId,
				}],
			},
		}).then(res => runInAction(() => adjudicationPage.radiologistName = `${res.data.radiologistEntity.firstName} ${res.data.radiologistEntity.lastName}`));
	});
	
	const setReportState = action((value: ILOReportState): void => {
		runInAction(() => {
			reportState.imageQuality = value.imageQuality;
			reportState.imageDefects = value.imageDefects;
			reportState.imageDefectsOther = value.imageDefectsOther;

			reportState.parenchymalAbnormalities = value.parenchymalAbnormalities;
			reportState.smallOpacities = value.smallOpacities;
			reportState.largeOpacities = value.largeOpacities;

			reportState.pleuralAbnormalities = value.pleuralAbnormalities;
			if (value.pleuralPlaques !== null) {
				reportState.pleuralPlaques = value.pleuralPlaques;
			} else {
				reportState.pleuralPlaques = {
					site: [],
					calcification: [],
					extent: {
						right: {
							letter: '',
							number: 0,
						},
						left: {
							letter: '',
							number: 0,
						},
					},
					width: {
						right: {
							letter: '',
							number: 0,
						},
						left: {
							letter: '',
							number: 0,
						},
					},
				};
			}

			reportState.costophrenicAngleObliteration = value.costophrenicAngleObliteration;
			if (value.costophrenicAngleObliterations !== null) {
				reportState.costophrenicAngleObliterations = value.costophrenicAngleObliterations;
			} else {
				reportState.costophrenicAngleObliterations = {
					site: [],
					calcification: [],
					extent: {
						right: {
							letter: '',
							number: 0,
						},
						left: {
							letter: '',
							number: 0,
						},
					},
					width: {
						right: {
							letter: '',
							number: 0,
						},
						left: {
							letter: '',
							number: 0,
						},
					},
				};
			}
			reportState.approveStudy = value.approveStudy;
			reportState.otherAbnormalities = value.otherAbnormalities;
			
			if (value.otherSymbols !== null) {
				reportState.otherSymbols = value.otherSymbols;
			} else {
				reportState.otherSymbols = [];
			}

			reportState.otherComments = value.otherComments;
			reportState.seePhysician = value.seePhysician;
			
			reportState.dateDoctorNotified = value.dateDoctorNotified;
			
			if (value.otherAbnormalitiesList !== null) {
				reportState.otherAbnormalitiesList = value.otherAbnormalitiesList;
			} else {
				reportState.otherAbnormalitiesList = [];
			}
		});
	});
	
	useEffect(() => {
		if (radiologistId) {
			setRadiologistName();
		}
		if (reportStateObject && !readOnly) {
			setReportState(getJsonReportState(reportStateObject));
		}
	}, []);
	
	const addImageDefect = action((value: string): void => {
		if (!readOnly) {
			if (reportState.imageDefects.includes(value)) {
				reportState.imageDefects = reportState.imageDefects.filter(e => e !== value);
			} else {
				reportState.imageDefects.push(value);
			}
		}
	});
	
	const setImageQuality = action((value: number): void => {
		if (!readOnly) {
			reportState.imageQuality = value;
			reportState.imageDefects = [];
		}
	});
	
	const setParenchymalAbnormalities = action((value: boolean): void => {
		if (!readOnly) {
			reportState.parenchymalAbnormalities = value;
			reportState.smallOpacities = {
				shapePrimary: '',
				shapeSecondary: '',
				zones: [],
				profusion: '',
			};
		}
	});
	
	const addSmallOpacitiesShapePrimary = action((value: string): void => {
		if (!readOnly) {
			if (reportState.smallOpacities.shapePrimary === value) {
				reportState.smallOpacities.shapePrimary = '';
			} else {
				reportState.smallOpacities.shapePrimary = value;
			}
		}
	});
	
	const addSmallOpacitiesShapeSecondary = action((value: string): void => {
		if (!readOnly) {
			if (reportState.smallOpacities.shapeSecondary === value) {
				reportState.smallOpacities.shapeSecondary = '';
			} else {
				reportState.smallOpacities.shapeSecondary = value;
			}
		}
	});
	
	const addOpacityZone = action((value: string): void => {
		if (!readOnly) {
			if (reportState.smallOpacities.zones.includes(value)) {
				reportState.smallOpacities.zones = reportState.smallOpacities.zones.filter(e => e !== value);
			} else {
				reportState.smallOpacities.zones.push(value);
			}
		}
	});
	
	const addOpacityProfusion = action((value: string): void => {
		if (!readOnly) {
			if (reportState.smallOpacities.profusion === value) {
				reportState.smallOpacities.profusion = '';
			} else {
				reportState.smallOpacities.profusion = value;
			}
		}
	});
	
	const addLargeOpacities = action((value: string): void => {
		if (!readOnly) {
			if (reportState.largeOpacities === value) {
				reportState.largeOpacities = '';
			} else {
				reportState.largeOpacities = value;
			}
		}
	});
	
	const setPleuralAbnormalities = action((value: boolean): void => {
		if (!readOnly) {
			reportState.pleuralAbnormalities = value;
			reportState.pleuralPlaques = {
				site: [],
				calcification: [],
				extent: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
				width: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
			};
		}
	});
	
	const addPleuralPlaquesSite = action((value: string): void => {
		if (!readOnly) {
			const type = value.slice(0, -1);
			const area = value.slice(-1);

			if (reportState.pleuralPlaques.site.includes(value)) {
				reportState.pleuralPlaques.site = reportState.pleuralPlaques.site.filter(
					(e) => e !== value
				);
			} else {
				if (area === "O") {
					reportState.pleuralPlaques.site = reportState.pleuralPlaques.site.filter(
						(e) => e !== type + "R" && e !== type + "L"
					);
				} else if (["R", "L"].includes(area)) {
					reportState.pleuralPlaques.site = reportState.pleuralPlaques.site.filter(
						(e) => e !== type + "O"
					);
				}

				reportState.pleuralPlaques.site.push(value);
			}

			checkPleuralPlaques();
		}
	});
	
	const addPleuralPlaquesCalcification = action((value: string): void => {
		if (!readOnly) {
			const type = value.slice(0, -1);
			const area = value.slice(-1);

			if (reportState.pleuralPlaques.calcification.includes(value)) {
				reportState.pleuralPlaques.calcification = reportState.pleuralPlaques.calcification.filter(
					(e) => e !== value
				);
			} else {
				// Can only specify "O" or "R &| L"
				if (area === "O") {
					reportState.pleuralPlaques.calcification = reportState.pleuralPlaques.calcification.filter(
						(e) => e !== type + "R" && e !== type + "L"
					);
				} else if (["R", "L"].includes(area)) {
					reportState.pleuralPlaques.calcification = reportState.pleuralPlaques.calcification.filter(
						(e) => e !== type + "O"
					);
				}

				reportState.pleuralPlaques.calcification.push(value);
			}

			checkPleuralPlaques();
		}
	});

	const checkPleuralPlaques = action(() => {
		let pleuralPlaquesCombined = reportState.pleuralPlaques.site.concat(
			reportState.pleuralPlaques.calcification
		);
		pleuralPlaquesCombined = pleuralPlaquesCombined.map((item) =>
			item.slice(-1)
		);

		const setAll = (value: string, left: boolean) => {
			setExtentLetter(value, left);
			setWidthLetter(value, left);

			if (value === "O") {
				setExtentNumber(0, left);
				setWidthNumber(0, left);
			}
		};

		if (pleuralPlaquesCombined.includes("R")) {
			setAll("R", false);
		} else {
			setAll("O", false);
		}

		if (pleuralPlaquesCombined.includes("L")) {
			setAll("L", true);
		} else {
			setAll("O", true);
		}
	});
	
	const setExtentLetter = action((value: string, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.pleuralPlaques.extent.left.letter = value;
			} else {
				reportState.pleuralPlaques.extent.right.letter = value;
			}
		}
	});
	
	const setWidthLetter = action((value: string, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.pleuralPlaques.width.left.letter = value;
			} else {
				reportState.pleuralPlaques.width.right.letter = value;
			}
		}
	});
	
	const setExtentNumber = action((value: number, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.pleuralPlaques.extent.left.number = value;
			} else {
				reportState.pleuralPlaques.extent.right.number = value;
			}
		}
	});
	
	const setWidthNumber = action((value: number, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.pleuralPlaques.width.left.number = value;
			} else {
				reportState.pleuralPlaques.width.right.number = value;
			}
		}
	});
	
	const setCostophrenicAngleObliteration = action((value: string): void => {
		if (!readOnly) {
			if (value === 'No') {
				reportState.costophrenicAngleObliteration = [value];
				reportState.costophrenicAngleObliterations = {
					site: [],
					calcification: [],
					extent: {
						right: {
							letter: '',
							number: 0,
						},
						left: {
							letter: '',
							number: 0,
						},
					},
					width: {
						right: {
							letter: '',
							number: 0,
						},
						left: {
							letter: '',
							number: 0,
						},
					},
				};
			} else if (reportState.costophrenicAngleObliteration.indexOf(value) > -1) {
				reportState.costophrenicAngleObliteration.splice(reportState.costophrenicAngleObliteration.indexOf(value), 1);
			} else {
				if (reportState.costophrenicAngleObliteration.indexOf('No') > -1) {
					reportState.costophrenicAngleObliteration = [];
				}
				reportState.costophrenicAngleObliteration.push(value);
			}
		}
	});

	const checkCostophrenicAngleObliteration = action(() => {
		let combined = reportState.costophrenicAngleObliterations.site.concat(
			reportState.costophrenicAngleObliterations.calcification
		);
		combined = combined.map((item) =>
			item.slice(-1)
		);

		const setAll = (value: string, left: boolean) => {
			setCostophrenicExtentLetter(value, left);
			setCostophrenicWidthLetter(value, left);

			if (value === "O") {
				setCostophrenicExtentNumber(0, left);
				setCostophrenicWidthNumber(0, left);
			}
		};

		if (combined.includes("R")) {
			setAll("R", false);
		} else {
			setAll("O", false);
		}

		if (combined.includes("L")) {
			setAll("L", true);
		} else {
			setAll("O", true);
		}
	});
	
	const addCostophrenicAngleObliterationSite = action((value: string): void => {
		if (!readOnly) {
			const type = value.slice(0, -1);
			const area = value.slice(-1);

			if (reportState.costophrenicAngleObliterations.site.includes(value)) {
				reportState.costophrenicAngleObliterations.site = reportState.costophrenicAngleObliterations.site.filter(
					(e) => e !== value
				);
			} else {
				if (area === "O") {
					reportState.costophrenicAngleObliterations.site = reportState.costophrenicAngleObliterations.site.filter(
						(e) => e !== type + "R" && e !== type + "L"
					);
				} else if (["R", "L"].includes(area)) {
					reportState.costophrenicAngleObliterations.site = reportState.costophrenicAngleObliterations.site.filter(
						(e) => e !== type + "O"
					);
				}

				reportState.costophrenicAngleObliterations.site.push(value);
			}

			checkCostophrenicAngleObliteration();
		}
	});
	
	const addCostophrenicAngleObliterationsCalcification = action((value: string): void => {
		if (!readOnly) {
			const type = value.slice(0, -1);
			const area = value.slice(-1);

			if (reportState.costophrenicAngleObliterations.calcification.includes(value)) {
				reportState.costophrenicAngleObliterations.calcification = reportState.costophrenicAngleObliterations.calcification.filter(
					(e) => e !== value
				);
			} else {
				// Can only specify "O" or "R &| L"
				if (area === "O") {
					reportState.costophrenicAngleObliterations.calcification = reportState.costophrenicAngleObliterations.calcification.filter(
						(e) => e !== type + "R" && e !== type + "L"
					);
				} else if (["R", "L"].includes(area)) {
					reportState.costophrenicAngleObliterations.calcification = reportState.costophrenicAngleObliterations.calcification.filter(
						(e) => e !== type + "O"
					);
				}

				reportState.costophrenicAngleObliterations.calcification.push(value);
			}

			checkCostophrenicAngleObliteration();
		}
	});
	
	const setCostophrenicExtentLetter = action((value: string, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.costophrenicAngleObliterations.extent.left.letter = value;
			} else {
				reportState.costophrenicAngleObliterations.extent.right.letter = value;
			}
		}
	});
	
	const setCostophrenicWidthLetter = action((value: string, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.costophrenicAngleObliterations.width.left.letter = value;
			} else {
				reportState.costophrenicAngleObliterations.width.right.letter = value;
			}
		}
	});
	
	const setCostophrenicExtentNumber = action((value: number, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.costophrenicAngleObliterations.extent.left.number = value;
			} else {
				reportState.costophrenicAngleObliterations.extent.right.number = value;
			}
		}
	});
	
	const setCostophrenicWidthNumber = action((value: number, left: boolean): void => {
		if (!readOnly) {
			if (left) {
				reportState.costophrenicAngleObliterations.width.left.number = value;
			} else {
				reportState.costophrenicAngleObliterations.width.right.number = value;
			}
		}
	});
	
	const setOtherAbnormalities = action((value: boolean): void => {
		if (!readOnly) {
			reportState.otherAbnormalities = value;
			reportState.otherSymbols = [];
		}
	});
	
	const addOtherSymbols = action((value: string): void => {
		if (!readOnly) {
			if (reportState.otherSymbols.includes(value)) {
				reportState.otherSymbols = reportState.otherSymbols.filter(e => e !== value);
			} else {
				reportState.otherSymbols.push(value);
			}
		}
	});
	
	const setSeePhysician = action((value: string): void => {
		if (!readOnly) {
			if (reportState.seePhysician === value) {
				reportState.seePhysician = '';
			} else {
				reportState.seePhysician = value;
			}

			if (reportState.seePhysician === 'Yes') {
				reportState.dateDoctorNotified = moment.utc().add(10, 'h').format("DD-MM-YYYY");
			} else {
				reportState.dateDoctorNotified = null;
			}
		}
	});
	
	const validateForm = (): boolean => {
		// Image quality validation
		if (reportState.imageQuality === 0) {
			alert('Indicate image quality', 'error');
			return false;
		}
		if (reportState.imageQuality === 2 || reportState.imageQuality === 3) {
			if (reportState.imageDefects.length === 0) {
				alert('Indicate image quality defects', 'error');
				return false;
			}

			if (reportState.imageDefects.includes("Other") && reportState.imageDefectsOther.length === 0) {
				alert("Indicate image defect comments", "error");
				return false;
			}
		}

		if (reportState.imageQuality !== 4) {
			if (reportState.parenchymalAbnormalities === null) {
				alert('Indicate if any parenchymal abnormalities', 'error');
				return false;
			}
	
			if (reportState.parenchymalAbnormalities) {
				if (reportState.smallOpacities.shapePrimary === '') {
					alert('Indicate small primary opacity shape / size', 'error');
					return false;
				}
	
				if (reportState.smallOpacities.shapeSecondary === '') {
					alert('Indicate small opacity secondary shape / size', 'error');
					return false;
				}
	
				if (reportState.smallOpacities.zones.length === 0) {
					alert('Indicate small opacity zones', 'error');
					return false;
				}
	
				if (reportState.smallOpacities.profusion === '') {
					alert('Indicate small opacity profusion', 'error');
					return false;
				}
	
				if (reportState.largeOpacities.length === 0) {
					alert('Indicate large opacities', 'error');
					return false;
				}
			}
	
			// ----- Pleural Plaques -----
			if (reportState.pleuralAbnormalities === null) {
				alert("Indicate pleural abnormalities", "error");
				return false;
			}
	
			if (reportState.pleuralAbnormalities) {
				// Check if each section (row) has a value
				if (
					!["In profile", "Face on", "Diaphragm", "Other site(s)"].every((val) =>
						reportState.pleuralPlaques.site
							.map((e) => e.slice(0, -1))
							.includes(val)
					)
				) {
					alert("Indicate pleural plaque sites", "error");
					return false;
				}
	
				if (
					!["In profile", "Face on", "Diaphragm", "Other site(s)"].every((val) =>
						reportState.pleuralPlaques.calcification
							.map((e) => e.slice(0, -1))
							.includes(val)
					)
				) {
					alert("Indicate pleural plaque calcifications", "error");
					return false;
				}
	
				// If value is not "O", a number must be selected
				if (
					reportState.pleuralPlaques.extent.right.letter === "" ||
					(reportState.pleuralPlaques.extent.right.letter === "R" &&
						reportState.pleuralPlaques.extent.right.number === 0)
				) {
					alert("Indicate pleural plaque extent (right)", "error");
					return false;
				}
	
				if (
					reportState.pleuralPlaques.extent.left.letter === "" ||
					(reportState.pleuralPlaques.extent.left.letter === "L" &&
						reportState.pleuralPlaques.extent.left.number === 0)
				) {
					alert("Indicate pleural plaque extent (left)", "error");
					return false;
				}
	
				if (
					reportState.pleuralPlaques.width.right.letter === "" ||
					(reportState.pleuralPlaques.width.right.letter === "R" &&
						reportState.pleuralPlaques.width.right.number === 0)
				) {
					alert("Indicate pleural plaque width (right)", "error");
					return false;
				}
	
				if (
					reportState.pleuralPlaques.width.left.letter === "" ||
					(reportState.pleuralPlaques.width.right.letter === "L" &&
						reportState.pleuralPlaques.width.left.number === 0)
				) {
					alert("Indicate pleural plaque width", "error");
					return false;
				}
			}
	
			// ---- Costophrenic Angle Obliteration -----
			if (reportState.costophrenicAngleObliteration.length === 0) {
				alert("Indicate costophrenic angle obliteration", "error");
				return false;
			}
	
			if (
				reportState.costophrenicAngleObliteration.length !== 0 &&
				!reportState.costophrenicAngleObliteration.includes("No")
			) {
				// Check if each section (row) has a value
				if (
					!["In profile", "Face on"].every((val) =>
						reportState.costophrenicAngleObliterations.site
							.map((e) => e.slice(0, -1))
							.includes(val)
					)
				) {
					alert("Indicate diffuse pleural thickening site", "error");
					return false;
				}
	
				if (
					!["In profile", "Face on"].every((val) =>
						reportState.costophrenicAngleObliterations.calcification
							.map((e) => e.slice(0, -1))
							.includes(val)
					)
				) {
					alert("Indicate diffuse pleural thickening calcification", "error");
					return false;
				}
	
				// If value is not "O", a number must be selected
				if (
					reportState.costophrenicAngleObliterations.extent.right.letter ===
						"R" &&
					reportState.costophrenicAngleObliterations.extent.right.number ===
						0
				) {
					alert("Indicate pleural thickening extent (right)", "error");
					return false;
				}
				if (
					reportState.costophrenicAngleObliterations.extent.left.letter ===
						"L" &&
					reportState.costophrenicAngleObliterations.extent.left.number === 0
				) {
					alert("Indicate pleural thickening extent (left)", "error");
					return false;
				}
	
				if (
					reportState.costophrenicAngleObliterations.width.right.letter ===
						"R" &&
					reportState.costophrenicAngleObliterations.width.right.number === 0
				) {
					alert("Indicate diffuse pleural thickening width (right)", "error");
					return false;
				}
				if (
					reportState.costophrenicAngleObliterations.width.left.letter ===
						"L" &&
					reportState.costophrenicAngleObliterations.width.left.number === 0
				) {
					alert("Indicate diffuse pleural thickening width (left)", "error");
					return false;
				}
			}
	
			if (reportState.otherAbnormalities === null) {
				alert('Indicate if any other abnormalities', 'error');
				return false;
			}
	
			if (reportState.otherAbnormalities) {
				if (reportState.otherSymbols.length === 0 && reportState.otherAbnormalitiesList.length === 0 && reportState.otherComments.length === 0) {
					alert('Indicate other abnormalities', 'error');
					return false;
				}
			}
	
			if (!reportState.seePhysician) {
				alert('Indicate whether worker should see physician', 'error');
				return false;
			}
		}

		
		
		return true;
	};
	
	const detailsNext = action(() => {
		if (validateForm() && previewFunc) {
			previewFunc(reportState);
		}
	});
	
	const changeOpenState = (openState: any[]) => {
		// If image quality is 4 (U/R), can't open any other sections
		if (reportState.imageQuality === 4) {
			if (openAccordionItemFunc && openState[0] === "1") {
				openAccordionItemFunc(openState);
			} else {
				// Don't display error if clicking on image quality when section is already open
				if (openState.length > 0) alert('Image is marked unreadable', 'error');
			}
		} else if (openAccordionItemFunc) {
			openAccordionItemFunc(openState);
		}
	};
	
	const setOtherAbnormalitiesList = action((value: string): void => {
		if (!readOnly) {
			if (reportState.otherAbnormalitiesList.includes(value)) {
				reportState.otherAbnormalitiesList = reportState.otherAbnormalitiesList.filter(e => e !== value);
			} else {
				reportState.otherAbnormalitiesList.push(value);
			}
		}
	});

	const handleOtherChange = action((e: ChangeEvent<HTMLTextAreaElement>): void => {
		if (!readOnly) {
			reportState.imageDefectsOther = e.target.value;
		}
	});

	const handleOtherCommentsChange = action((e: ChangeEvent<HTMLTextAreaElement>): void => {
		if (!readOnly) {
			reportState.otherComments = e.target.value;
		}
	});
	
	const renderWidthExtentMatrix = (
		side: "R" | "L",
		numberFunction: (x: number, y: boolean) => void,
		letterProperty: string,
		numberProperty: number
	): React.ReactNode => {
		return (
			<div className="button-grid grid-col-3">
				<button
					type="button"
					className={`btn ${
						letterProperty.includes("O") ? "active" : "inactive"
					}`}
				>
					O
				</button>

				<button
					type="button"
					className={`btn ${
						letterProperty.includes(side) ? "active" : "inactive"
					}`}
				>
					{side}
				</button>

				<div className="empty-cell" />

				{[1, 2, 3].map((number) => (
					<button
						type="button"
						onClick={() => {
							if (letterProperty.includes(side))
								numberFunction(number, side === "L");
						}}
						className={`btn ${numberProperty === number && "active"} ${
							!letterProperty.includes(side) && "inactive"
						}`}
						key={number}
					>
						{number}
					</button>
				))}
			</div>
		);
	};

	const renderOtherConditions = (): React.ReactNode => {
		const otherConditions = {
			"Abnormalities of the Diaphragm": ["Eventration", "Hiatal hernia"],
			"Airway Disorders": [
				"Bronchovascular markings, heavy or increased",
				"Hyperinflation",
			],
			"Bony Abnormalities": [
				"Bony chest cage abnormality",
				"Fracture, healed (non-rib)",
				"Fracture, not healed (non-rib)",
				"Scoliosis",
				"Vertebral column abnormality",
			],
			"Vascular Disorders": ["Aorta, anomaly of", "Vascular abnormality"],
			"Lung Parenchymal Abnormalities": [
				"Azygos lobe",
				"Density, lung",
				"Infiltrate",
				"Nodule, nodular lesion",
			],
			"Miscellaneous Abnormalities": [
				"Foreign body",
				"Post-surgical changes / sternal wire",
				"Cyst",
			],
		};

		return (
			<>
				<div className="report-section space">
					<h4>4C. Mark all boxes that apply</h4>
					<div className="report-subsection cols">
						{Object.keys(otherConditions).map((category) => (
							<div className="report-subsection-element" key={category}>
								<span className="label" key={category}>
									{category}
								</span>

								{otherConditions[category].map((condition: string) => (
									<div
										className="other-abnormalities-col-inner"
										key={`${category}.${condition}`}
									>
										<button
											type="button"
											onClick={() => setOtherAbnormalitiesList(condition)}
											className={`btn ${
												reportState.otherAbnormalitiesList.includes(
													condition
												) && "active"
											}`}
										>
											x
										</button>
										<p>{condition}</p>
									</div>
								))}
							</div>
						))}
					</div>
				</div>

				<div className="report-section space">
					<h4>4D. Other Comments</h4>
					<div className="comment-box">
						<textarea
							value={reportState.otherComments}
							onChange={handleOtherCommentsChange}
							rows={5}
						/>
					</div>
				</div>
			</>
		);
	};

	return useObserver(() => (
		<>
			<div className={`adjudication-form ${readOnly ? "read-only" : ""}`}>
				{adjudicationPage.currentPage === AdjudicationPageList.FormPage && (
					<>
						<div className="report-details">
							{!readOnly ? (
								<>
									<h4>
										{firstName} {lastName}
									</h4>
									<span>D.O.B: {dateOfBirth}</span>
								</>
							) : (
								<>
									<span>
										Completed: {moment(completedDate).format("MM/DD/YYYY")}
									</span>
									<h4>{adjudicationPage.radiologistName}</h4>
								</>
							)}
						</div>

						<Accordion
							allowZeroExpanded
							onChange={(openTab) => changeOpenState(openTab)}
						>
							<AccordionItem
								uuid="1"
								dangerouslySetExpanded={openTabOverride === 1}
							>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="numberCircle">1</div>
										Indicate Image Quality
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="report-tile image-quality-tile">
										<div className="report-section">
											<h4>1. Image Quality</h4>
											<div className="button-group">
												{[1, 2, 3, 4].map((value) => (
													<button
														type="button"
														className={`btn ${
															reportState.imageQuality === value && "active"
														}`}
														onClick={() => setImageQuality(value)}
														key={value}
													>
														{value != 4 ? value : "U/R"}
													</button>
												))}
											</div>

											{reportState.imageQuality === 1 && (
												<span className="bold gap">Good.</span>
											)}

											{reportState.imageQuality === 2 && (
												<span className="bold gap">
													Acceptable, with no technical defects likely to impair
													classification of the radiograph for pneumoconiosis.
												</span>
											)}

											{reportState.imageQuality === 3 && (
												<span className="bold gap">
													Acceptable, with some technical defect but still
													adequate for classification purposes.
												</span>
											)}

											{reportState.imageQuality === 4 && (
												<span className="bold gap">Un-readable.</span>
											)}

											{reportState.imageQuality !== 0 &&
												reportState.imageQuality !== 1 &&
												reportState.imageQuality !== 4 && (
													<div className="button-group">
														{[
															"Overexposed (dark)",
															"Underexposed (light)",
															"Artifacts",
															"Improper position",
															"Poor contrast",
															"Poor Processing",
															"Underinflation",
															"Mottle",
															"Excessive Edge Enhancement",
															"Other",
														].map((value) => (
															<button
																type="button"
																className={`btn ${
																	reportState.imageDefects.includes(value) &&
																	"active"
																}`}
																onClick={(): void => addImageDefect(value)}
																key={value}
															>
																{value}
															</button>
														))}
													</div>
												)}

											{reportState.imageDefects.includes("Other") && (
												<div className="comment-box">
													<span className="bold">Other Defects:</span>
													<textarea
														value={reportState.imageDefectsOther}
														onChange={handleOtherChange}
														rows={3}
													/>
												</div>
											)}
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem
								uuid="2"
								dangerouslySetExpanded={openTabOverride === 2}
							>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="numberCircle">2</div>
										Parenchymal Abnormalities
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="report-tile">
										<div className="report-section">
											<h4>2A. Any parenchymal abnormalities?</h4>
											<div className="button-group">
												<button
													type="button"
													onClick={(): void =>
														setParenchymalAbnormalities(true)
													}
													className={`btn ${
														reportState.parenchymalAbnormalities && "active"
													}`}
												>
													Yes
												</button>

												<button
													type="button"
													onClick={() => setParenchymalAbnormalities(false)}
													className={`btn ${
														reportState.parenchymalAbnormalities === false &&
														"active"
													}`}
												>
													No
												</button>
											</div>
										</div>

										{reportState.parenchymalAbnormalities && (
											<div className="report-section space">
												<h4>2B. Small opacities</h4>
												<div className="report-subsection flex ignore-cols">
													<div className="report-subsection-element">
														<h5>a. Shape / Size</h5>

														<div className="flex">
															<div>
																<h5>Primary</h5>
																<div className="button-grid grid-col-2">
																	{["p", "s", "q", "t", "r", "u"].map(
																		(shape) => (
																			<button
																				type="button"
																				className={`btn small-opacity-button ${
																					reportState.smallOpacities.shapePrimary.includes(
																						shape
																					) && "active"
																				}`}
																				onClick={(): void =>
																					addSmallOpacitiesShapePrimary(shape)
																				}
																				key={shape}
																			>
																				{shape}
																			</button>
																		)
																	)}
																</div>
															</div>

															<div>
																<h5>Secondary</h5>
																<div className="button-grid grid-col-2">
																	{["p", "s", "q", "t", "r", "u"].map(
																		(shape) => (
																			<button
																				type="button"
																				className={`btn small-opacity-button ${
																					reportState.smallOpacities.shapeSecondary.includes(
																						shape
																					) && "active"
																				}`}
																				onClick={(): void =>
																					addSmallOpacitiesShapeSecondary(shape)
																				}
																				key={shape}
																			>
																				{shape}
																			</button>
																		)
																	)}
																</div>
															</div>
														</div>
													</div>

													<div className="report-subsection-element">
														<h5>b. Zones</h5>
														<div className="button-grid grid-col-3">
															<div className="small-opacities-zones-label"></div>
															<span className="top-label bold">R</span>
															<span className="top-label bold">L</span>

															{["Upper", "Middle", "Lower"].map((height) => (
																<React.Fragment key={height}>
																	<span className="bold align-right">
																		{height}
																	</span>

																	{["R", "L"].map((side) => {
																		const zone = height[0] + side;

																		return (
																			<button
																				type="button"
																				onClick={() => addOpacityZone(zone)}
																				className={`btn ${
																					reportState.smallOpacities.zones.includes(
																						zone
																					) && "active"
																				}`}
																				key={zone}
																			>
																				X
																			</button>
																		);
																	})}
																</React.Fragment>
															))}
														</div>
													</div>

													<div className="report-subsection-element">
														<h5>c. Profusions</h5>
														<div className="button-grid grid-col-3">
															{[
																"0-",
																"00",
																"01",
																"10",
																"11",
																"12",
																"21",
																"22",
																"23",
																"32",
																"33",
																"3+",
															].map((profusion) => (
																<button
																	type="button"
																	onClick={() => addOpacityProfusion(profusion)}
																	className={`btn ${
																		reportState.smallOpacities.profusion.includes(
																			profusion
																		) && "active"
																	}`}
																	key={profusion}
																>
																	{profusion[0] + "/" + profusion[1]}
																</button>
															))}
														</div>
													</div>
												</div>

												<div className="report-section space">
													<h4>2C. Large opacities</h4>
													<h5>Size</h5>
													<div className="button-group">
														{["O", "A", "B", "C"].map((size) => (
															<button
																type="button"
																className={`btn large-opactiy-button ${
																	reportState.largeOpacities === size &&
																	"active"
																}`}
																onClick={() => addLargeOpacities(size)}
																key={size}
															>
																{size}
															</button>
														))}
													</div>
												</div>
											</div>
										)}
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem
								uuid="3"
								dangerouslySetExpanded={openTabOverride === 3}
							>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="numberCircle">3</div>
										Pleural Abnormalities
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="report-tile">
										<div className="report-section">
											<h4>3A. Any classifiable pleural abnormalities?</h4>
											<div className="button-group">
												<button
													type="button"
													onClick={(): void => setPleuralAbnormalities(true)}
													className={`btn ${
														reportState.pleuralAbnormalities && "active"
													}`}
												>
													Yes
												</button>

												<button
													type="button"
													onClick={(): void => setPleuralAbnormalities(false)}
													className={`btn ${
														reportState.pleuralAbnormalities === false &&
														"active"
													}`}
												>
													No
												</button>
											</div>

											{reportState.pleuralAbnormalities && (
												<>
													<div className="report-section space">
														<h4>3B. Pleural Plaques</h4>

														<div className="report-subsection flex">
															<div className="report-subsection-element">
																<span className="bold medium">Site</span>
																<div className="button-grid grid-col-4">
																	{[
																		"In profile",
																		"Face on",
																		"Diaphragm",
																		"Other site(s)",
																	].map((site) => (
																		<React.Fragment key={site}>
																			<span
																				className="bold no-gap align-right"
																				key={site}
																			>
																				{site}
																			</span>

																			{["O", "R", "L"].map((grade) => (
																				<button
																					type="button"
																					onClick={() =>
																						addPleuralPlaquesSite(
																							`${site}${grade}`
																						)
																					}
																					className={`btn ${
																						reportState.pleuralPlaques.site.includes(
																							`${site}${grade}`
																						) && "active"
																					}`}
																					key={`${site}.${grade}`}
																				>
																					{grade}
																				</button>
																			))}
																		</React.Fragment>
																	))}
																</div>
															</div>

															<div className="report-subsection-element">
																<span className="bold medium">Calcification</span>
																<div className="button-grid grid-col-4">
																	{[
																		"In profile",
																		"Face on",
																		"Diaphragm",
																		"Other site(s)",
																	].map((site) => (
																		<React.Fragment key={site}>
																			<span
																				className="bold no-gap align-right"
																				key={site}
																			>
																				{site}
																			</span>

																			{["O", "R", "L"].map((grade) => (
																				<button
																					type="button"
																					onClick={() =>
																						addPleuralPlaquesCalcification(
																							`${site}${grade}`
																						)
																					}
																					className={`btn ${
																						reportState.pleuralPlaques.calcification.includes(
																							`${site}${grade}`
																						) && "active"
																					}`}
																					key={`${site}.${grade}`}
																				>
																					{grade}
																				</button>
																			))}
																		</React.Fragment>
																	))}
																</div>
															</div>

															<div className="report-subsection-element">
																<span className="bold medium">
																	Extent (chest wall: combined for in profile
																	and face on)
																</span>

																<div className="flex">
																	{renderWidthExtentMatrix(
																		"R",
																		setExtentNumber,
																		reportState.pleuralPlaques.extent.right
																			.letter,
																		reportState.pleuralPlaques.extent.right
																			.number
																	)}

																	{renderWidthExtentMatrix(
																		"L",
																		setExtentNumber,
																		reportState.pleuralPlaques.extent.left
																			.letter,
																		reportState.pleuralPlaques.extent.left
																			.number
																	)}
																</div>
															</div>

															<div className="report-subsection-element">
																<span className="bold medium">
																	Width (In profile only)
																</span>

																<div className="flex">
																	{renderWidthExtentMatrix(
																		"R",
																		setWidthNumber,
																		reportState.pleuralPlaques.width.right
																			.letter,
																		reportState.pleuralPlaques.width.right
																			.number
																	)}

																	{renderWidthExtentMatrix(
																		"L",
																		setWidthNumber,
																		reportState.pleuralPlaques.width.left
																			.letter,
																		reportState.pleuralPlaques.width.left.number
																	)}
																</div>
															</div>
														</div>
													</div>
												</>
											)}
											<div className="report-section space">
												<h4>3C. Costophrenic angle obliteration</h4>
												<div className="button-group">
													<button
														type="button"
														onClick={(): void =>
															setCostophrenicAngleObliteration("R")
														}
														className={`btn ${
															reportState.costophrenicAngleObliteration.includes(
																"R"
															) && "active"
														}`}
													>
														R
													</button>

													<button
														type="button"
														onClick={(): void =>
															setCostophrenicAngleObliteration("L")
														}
														className={`btn ${
															reportState.costophrenicAngleObliteration.includes(
																"L"
															) && "active"
														}`}
													>
														L
													</button>

													<button
														type="button"
														onClick={(): void =>
															setCostophrenicAngleObliteration("No")
														}
														className={`btn ${
															reportState.costophrenicAngleObliteration.includes(
																"No"
															) && "active"
														}`}
													>
														No
													</button>
												</div>

												{(reportState.costophrenicAngleObliteration.includes(
													"L"
												) ||
													reportState.costophrenicAngleObliteration.includes(
														"R"
													)) && (
													<>
														<div className="report-section space">
															<h4>3D. Diffuse Pleural Thickening</h4>

															<div className="report-subsection flex">
																<div className="report-subsection-element">
																	<span className="bold medium">Site</span>
																	<div className="button-grid grid-col-4">
																		{["In profile", "Face on"].map((site) => (
																			<React.Fragment key={site}>
																				<span className="bold no-gap align-right">
																					{site}
																				</span>

																				{["O", "R", "L"].map((grade) => (
																					<button
																						type="button"
																						onClick={() =>
																							addCostophrenicAngleObliterationSite(
																								`${site}${grade}`
																							)
																						}
																						className={`btn ${
																							reportState.costophrenicAngleObliterations.site.includes(
																								`${site}${grade}`
																							) && "active"
																						}`}
																						key={`${site}.${grade}`}
																					>
																						{grade}
																					</button>
																				))}
																			</React.Fragment>
																		))}
																	</div>
																</div>

																<div className="report-subsection-element">
																	<span className="bold medium">Calcification</span>
																	<div className="button-grid grid-col-4">
																		{["In profile", "Face on"].map((site) => (
																			<React.Fragment key={site}>
																				<span className="bold no-gap align-right">
																					{site}
																				</span>

																				{["O", "R", "L"].map((grade) => (
																					<button
																						type="button"
																						onClick={() =>
																							addCostophrenicAngleObliterationsCalcification(
																								`${site}${grade}`
																							)
																						}
																						className={`btn ${
																							reportState.costophrenicAngleObliterations.calcification.includes(
																								`${site}${grade}`
																							) && "active"
																						}`}
																						key={`${site}.${grade}`}
																					>
																						{grade}
																					</button>
																				))}
																			</React.Fragment>
																		))}
																	</div>
																</div>

																<div className="report-subsection-element">
																	<span className="bold medium">
																		Extent (chest wall: combined for in profile and face on)
																	</span>

																	<div className="flex">
																		{renderWidthExtentMatrix(
																			"R",
																			setCostophrenicExtentNumber,
																			reportState.costophrenicAngleObliterations
																				.extent.right.letter,
																			reportState.costophrenicAngleObliterations
																				.extent.right.number
																		)}

																		{renderWidthExtentMatrix(
																			"L",
																			setCostophrenicExtentNumber,
																			reportState.costophrenicAngleObliterations
																				.extent.left.letter,
																			reportState.costophrenicAngleObliterations
																				.extent.left.number
																		)}
																	</div>
																</div>

																<div className="report-subsection-element">
																	<span className="bold medium">
																		Width (In profile only)
																	</span>

																	<div className="flex">
																		{renderWidthExtentMatrix(
																			"R",
																			setCostophrenicWidthNumber,
																			reportState.costophrenicAngleObliterations
																				.width.right.letter,
																			reportState.costophrenicAngleObliterations
																				.width.right.number
																		)}
																		{renderWidthExtentMatrix(
																			"L",
																			setCostophrenicWidthNumber,
																			reportState.costophrenicAngleObliterations
																				.width.left.letter,
																			reportState.costophrenicAngleObliterations
																				.width.left.number
																		)}
																	</div>
																</div>
															</div>
														</div>
													</>
												)}
											</div>
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem
								uuid="4"
								dangerouslySetExpanded={openTabOverride === 4}
							>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="numberCircle">4</div>
										Other Abnormalities
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="other-abnormalities-tile report-tile">
										<div className="report-section">
											<h4>4A. Any other abnormalities?</h4>
											<div className="button-group">
												<button
													type="button"
													onClick={(): void => setOtherAbnormalities(true)}
													className={`btn ${
														reportState.otherAbnormalities && "active"
													}`}
												>
													Yes
												</button>

												<button
													type="button"
													onClick={(): void => setOtherAbnormalities(false)}
													className={`btn ${
														reportState.otherAbnormalities === false && "active"
													}`}
												>
													No
												</button>
											</div>
										</div>

										{reportState.otherAbnormalities && (
											<div className="report-section space">
												<h4>4B. Other Symbols (Obligatory)</h4>
												
												<div className="button-group">
													{['aa', 'at', 'ax', 'bu', 'ca', 'cg', 'cn', 'co', 'cp', 'cv',
														'di', 'ef', 'em', 'es', 'fr', 'hi', 'ho', 'id', 'ih', 'kl',
														'me', 'pa', 'pb', 'pi', 'px', 'ra', 'rp', 'tb'].map((symbol) => (
															<button
																type="button"
																onClick={() => addOtherSymbols(symbol)}
																className={`btn ${reportState.otherSymbols.includes(symbol) && 'active'}`}
																key={symbol}
															>
																{symbol}
															</button>
														))}
												</div>
											</div>
										)}

										{reportState.otherAbnormalities && renderOtherConditions()}
										<div className="report-section space">
											
										</div>
										<div className="report-section space">
											<h4>
												4E. Should worker see personal physician because of
												findings?
											</h4>
											<div className="button-group">
												<button
													type="button"
													onClick={() => setSeePhysician("Yes")}
													className={`btn ${
														reportState.seePhysician === "Yes" && "active"
													}`}
												>
													Yes
												</button>
												<button
													type="button"
													onClick={() => setSeePhysician("No")}
													className={`btn ${
														reportState.seePhysician === "No" && "active"
													}`}
												>
													No
												</button>
											</div>
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
					</>
				)}
			</div>
			{!readOnly && (
				<>
					<div className="report-footer">
						{backToList && (
							<button
								type="button"
								className="btn btn--solid"
								onClick={(): void => backToList()}
							>
								Back To List
							</button>
						)}
						
						{(reportCount === 2 || reportCount === 3) && furtherReads != null && (
							<button
								type="button"
								className="btn btn--solid"
								onClick={(): void => furtherReads()}
							>
								Further Reads Required
							</button>
						)}
						
						<div className="primary-btns">
							<button
								type="button"
								className="btn btn--solid"
								onClick={(): void => detailsNext()}
							>
								Next
							</button>
						</div>
						
					</div>
				</>
			)}
		</>
	));
};

export default AdjudicationForm;
