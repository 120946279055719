/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import * as Models from 'Models/Entities';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { Model } from 'Models/Model';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import * as Enums from 'Models/Enums';
import { IWhereCondition } from 'Views/Components/ModelCollection/CustomModelQuery';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { store } from 'Models/Store';
import { observable, action } from 'mobx';
import { IconPositions } from '../Components/Helpers/Common';
import * as ReportUtils from '../../Util/ReportUtils';
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] on begin
interface OutstandingCardiologyListProps extends RouteComponentProps {
	setReport: (report: Models.ReportRequestEntity) => void;
}

// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] on begin
export default class OutstandingCardiologyRequestsTile extends React.Component<OutstandingCardiologyListProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin

	@observable
	radiologists: Models.RadiologistEntity[];


	// % protected region % [Add class properties here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const {
			match, location, history, staticContext,
		} = this.props;

		contents = (
			<EntityCRUD
				modelType={Models.ReportRequestEntity}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
				additionalTableActions={this.getTableActionsMore()}
				removeViewAction
				extraConditions={OutstandingCardiologyRequestsTile.extraConditions()}
				addColumns={OutstandingCardiologyRequestsTile.addColumns}
				customOrderBy={OutstandingCardiologyRequestsTile.orderBy()}
				removeCreateAction
				removeEditAction
				removeDeleteAction
				entityCollectionProps={{ variables: { compareCounts: true } }}
			/>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin


	protected getTableActionsMore() {
		const tableActionsMore = [];
		if (store.radiologist) {
			tableActionsMore.push(
				{
					action: (report: Models.ReportRequestEntity): void => {
						this.startReport(report);
					},
					label: 'Start Reporting',
					showIcon: true,
					icon: 'chevron-right',
					iconPos: 'icon-right' as IconPositions,
				},
			);
		}
		return tableActionsMore;
	}

	//order table in assending order
	protected static orderBy(): IOrderByCondition<Models.ReportRequestEntity>[] {
		return [
			{
				path: 'created',
				descending: false,
			},
		];
	}

		// this function filters the cardiology report request table
	protected static extraConditions(): IWhereCondition<Models.ReportRequestEntity>[][] {
		const conditions: IWhereCondition<Model>[][] = [];
		
		// only show CARDIOLOGY reports
		conditions.push( 
			[
				{
					path:'reportModality' ,
					comparison:'equal',
					value: 'CARDIOLOGY',
				} as IWhereCondition<Model>,
			],
		);

		// only show reports that are in progress
		conditions.push( 
			[
				{
					comparison: 'equal',
					path: 'requestStatus',
					value: 'IN_PROGRESS',
				} as IWhereCondition<Model>,
			],
		);
		return conditions;
	}




	/**
	 * Callback function for starting a report
	 * @param report
	 */
	protected async startReport(report: Models.ReportRequestEntity) {
		// Sync the report with the latest model data
		const updatedReport = await ReportUtils.syncReportDataWithModel(report);

		// Verify that the report can actually be opened
		if (updatedReport != null && await ReportUtils.verifyReportCanBeOpened(updatedReport)) {
			// Assign the verified report
			const { setReport } = this.props;
			setReport(updatedReport);
		}
	}


	protected static addColumns(tableHeaders: ICollectionHeaderProps<Models.ReportRequestEntity>[]) {
		tableHeaders.unshift({
			displayName: ' ',
			name: '',
			sortable: false,
			transformItem: (item) => {
				const displayFunction = (attr: string, that: Models.ReportRequestEntity) => {
					if(that.reportModality === 'CARDIOLOGY') {
						return <div className={"category cardiology"} />;
					}
					return <div className={"category"} />
				};
				return displayFunction('', item);
			},
		});
	}
	//% protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
