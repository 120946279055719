/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { action, observable, runInAction } from 'mobx';
import { IAttributeGroup, Model, IModelAttributes, attribute, entity, jsonReplacerFn } from 'Models/Model';
import * as Validators from 'Validators';
import * as Models from '../Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import { makeFetchManyToManyFunc, makeFetchOneToManyFunc, makeJoinEqualsFunc, makeEnumFetchFunction } from 'Util/EntityUtils';
import { VisitorsCheckOutEntity } from 'Models/Security/Acl/VisitorsCheckOutEntity';
import { AdminCheckOutEntity } from 'Models/Security/Acl/AdminCheckOutEntity';
import { RadiologistCheckOutEntity } from 'Models/Security/Acl/RadiologistCheckOutEntity';
import * as Enums from '../Enums';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
// % protected region % [Add any further imports here] end

export interface ICheckOutEntityAttributes extends IModelAttributes {
	expiry: Date;

	reportRequestId: string;
	reportRequest: Models.ReportRequestEntity | Models.IReportRequestEntityAttributes;
	radiologistId: string;
	radiologist: Models.RadiologistEntity | Models.IRadiologistEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CheckOutEntity', 'Check Out')
// % protected region % [Customise your entity metadata here] end
export default class CheckOutEntity extends Model implements ICheckOutEntityAttributes {
	public static acls: IAcl[] = [
		new VisitorsCheckOutEntity(),
		new AdminCheckOutEntity(),
		new RadiologistCheckOutEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Expiry'] off begin
	/**
	 * When the check out will expire
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Expiry',
		displayType: 'datetimepicker',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public expiry: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Expiry'] end

	/**
	 * Check Outs
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Request',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequestId: string;
	@observable
	@attribute({isReference: true})
	public reportRequest: Models.ReportRequestEntity;

	/**
	 * Check Out
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Radiologist'] off begin
		name: 'Radiologist',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.RadiologistEntity,
		// % protected region % [Modify props to the crud options here for reference 'Radiologist'] end
	})
	public radiologistId: string;
	@observable
	@attribute({isReference: true})
	public radiologist: Models.RadiologistEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	// [LUNSD-132] Unable to enable searching for reportRequestName and radiologistName until the bot can be upgrade.
	// Missing some important functions in QueryableExtensions and ReflectionCache
	@CRUD({
		name: 'Report Request',
		displayType: 'hidden',
		headerColumn: true,
		searchable: false,
		// eslint-disable-next-line dot-notation
		displayFunction: (_attr, that) => that['reportRequest']['patientId'] || '',
	})
	@computed
	public get reportRequestName(): string {
		return `${this.reportRequest.patientId}`;
	}

	@CRUD({
		name: 'Radiologist',
		displayType: 'hidden',
		headerColumn: true,
		searchable: false,
		// eslint-disable-next-line dot-notation
		displayFunction: (_attr, that) => (that['radiologist'] ? `${that['radiologist']['firstName']} ${that['radiologist']['lastName']}` : ''),
	})
	@computed
	public get radiologistName(): string {
		return `${this.radiologist.firstName} ${this.radiologist.lastName}`;
	}
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ICheckOutEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICheckOutEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.expiry) {
				this.expiry = moment(attributes.expiry).toDate();
			}
			if (attributes.reportRequest) {
				if (attributes.reportRequest instanceof Models.ReportRequestEntity) {
					this.reportRequest = attributes.reportRequest;
					this.reportRequestId = attributes.reportRequest.id;
				} else {
					this.reportRequest = new Models.ReportRequestEntity(attributes.reportRequest);
					this.reportRequestId = this.reportRequest.id;
				}
			} else if (attributes.reportRequestId !== undefined) {
				this.reportRequestId = attributes.reportRequestId;
			}
			if (attributes.radiologist) {
				if (attributes.radiologist instanceof Models.RadiologistEntity) {
					this.radiologist = attributes.radiologist;
					this.radiologistId = attributes.radiologist.id;
				} else {
					this.radiologist = new Models.RadiologistEntity(attributes.radiologist);
					this.radiologistId = this.radiologist.id;
				}
			} else if (attributes.radiologistId !== undefined) {
				this.radiologistId = attributes.radiologistId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		reportRequest {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
		radiologist {
			${Models.RadiologistEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'radiologist',
						],
					},
				],
			}
		);
	}

	public async saveWithRadiologistAndReport() {
		const relationPath = {
			radiologist: {},
			reportRequest: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'radiologist',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		// TODO: Figure out how to display radiologist email/name here
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}