/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { action, observable, runInAction } from 'mobx';
import { IAttributeGroup, Model, IModelAttributes, attribute, entity, jsonReplacerFn } from 'Models/Model';
import * as Validators from 'Validators';
import * as Models from '../Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import { makeFetchManyToManyFunc, makeFetchOneToManyFunc, makeJoinEqualsFunc, makeEnumFetchFunction } from 'Util/EntityUtils';
import { VisitorsReportSubmissionEntity } from 'Models/Security/Acl/VisitorsReportSubmissionEntity';
import { AdminReportSubmissionEntity } from 'Models/Security/Acl/AdminReportSubmissionEntity';
import { RadiologistReportSubmissionEntity } from 'Models/Security/Acl/RadiologistReportSubmissionEntity';
import * as Enums from '../Enums';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IReportSubmissionEntityAttributes extends IModelAttributes {
	firstName: string;
	lastName: string;
	patientId: string;
	dateOfBirth: Date;
	imageDate: Date;
	requireAppointment: boolean;
	timeStarted: Date;
	timeCompleted: Date;
	orderingFacility: string;
	classificationPurpose: string;
	reportState: string;
	finalReport: boolean;
	adjudicated: boolean;
	archived: boolean;
	formModel: string;

	radiologistId: string;
	radiologist: Models.RadiologistEntity | Models.IRadiologistEntityAttributes;
	reportRequestId: string;
	reportRequest: Models.ReportRequestEntity | Models.IReportRequestEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ReportSubmissionEntity', 'Report Submission')
// % protected region % [Customise your entity metadata here] end
export default class ReportSubmissionEntity extends Model implements IReportSubmissionEntityAttributes {
	public static acls: IAcl[] = [
		new VisitorsReportSubmissionEntity(),
		new AdminReportSubmissionEntity(),
		new RadiologistReportSubmissionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	/**
	 * First Name
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] off begin
	/**
	 * Last Name
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Patient Id'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Patient Id',
		displayType: 'textfield',
		order: 5,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public patientId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Patient Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date Of Birth'] on begin
	/**
	 * Date Of Birth
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Date Of Birth',
		displayType: 'datepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date Of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Image Date'] on begin
	/**
	 * Image Date
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Image Date',
		displayType: 'datepicker',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public imageDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Image Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Require Appointment'] on begin
	/**
	 * Boolean determining if the patient should see the doctor to follow up on the reading
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Require Appointment',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public requireAppointment: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Require Appointment'] end

	// % protected region % [Modify props to the crud options here for attribute 'Time Started'] off begin
	/**
	 * Time Started
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Time Started',
		displayType: 'datetimepicker',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public timeStarted: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Time Started'] end

	// % protected region % [Modify props to the crud options here for attribute 'Time Completed'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Time Completed',
		displayType: 'datetimepicker',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public timeCompleted: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Time Completed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ordering Facility'] off begin
	/**
	 * Ordering Facility
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Ordering Facility',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public orderingFacility: string;
	// % protected region % [Modify props to the crud options here for attribute 'Ordering Facility'] end

	// % protected region % [Modify props to the crud options here for attribute 'Classification Purpose'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Classification Purpose',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public classificationPurpose: string;
	// % protected region % [Modify props to the crud options here for attribute 'Classification Purpose'] end

	// % protected region % [Modify props to the crud options here for attribute 'Report State'] off begin
	/**
	 * The filled out report details
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report State',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportState: string;
	// % protected region % [Modify props to the crud options here for attribute 'Report State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Final Report'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Final Report',
		displayType: 'checkbox',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public finalReport: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Final Report'] end

	// % protected region % [Modify props to the crud options here for attribute 'Adjudicated'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Adjudicated',
		displayType: 'checkbox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public adjudicated: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Adjudicated'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	// % protected region % [Modify props to the crud options here for attribute 'Form Model'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Form Model',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public formModel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Form Model'] end

	/**
	 * Report Submission
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Radiologist'] off begin
		name: 'Radiologist',
		displayType: 'reference-combobox',
		order: 160,
		referenceTypeFunc: () => Models.RadiologistEntity,
		// % protected region % [Modify props to the crud options here for reference 'Radiologist'] end
	})
	public radiologistId: string;
	@observable
	@attribute({isReference: true})
	public radiologist: Models.RadiologistEntity;

	/**
	 * Report Submission
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Request',
		displayType: 'reference-combobox',
		order: 170,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequestId: string;
	@observable
	@attribute({isReference: true})
	public reportRequest: Models.ReportRequestEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IReportSubmissionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IReportSubmissionEntityAttributes>) {
		// % protected region % [Override assign attributes here] on begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.firstName) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName) {
				this.lastName = attributes.lastName;
			}
			if (attributes.patientId) {
				this.patientId = attributes.patientId;
			}
			if (attributes.dateOfBirth) {
				this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
			}
			if (attributes.imageDate) {
				this.imageDate = moment(attributes.imageDate).toDate();
			}
			if (attributes.requireAppointment) {
				this.requireAppointment = attributes.requireAppointment;
			}
			if (attributes.timeStarted) {
				this.timeStarted = moment(attributes.timeStarted).toDate();
			}
			if (attributes.timeCompleted) {
				this.timeCompleted = moment(attributes.timeCompleted).toDate();  
			}
			if (attributes.orderingFacility) {
				this.orderingFacility = attributes.orderingFacility;
			}
			if (attributes.classificationPurpose) {
				this.classificationPurpose = attributes.classificationPurpose;
			}
			if (attributes.reportState) {
				this.reportState = attributes.reportState;
			}
			if (attributes.formModel) {
				this.formModel = attributes.formModel;
			}
			if (attributes.finalReport) {
				this.finalReport = attributes.finalReport;
			}
			if (attributes.adjudicated) {
				this.adjudicated = attributes.adjudicated;
			}
			if (attributes.archived) {
				this.archived = attributes.archived;
			}
			if (attributes.reportRequest) {
				if (attributes.reportRequest instanceof Models.ReportRequestEntity) {
					this.reportRequest = attributes.reportRequest;
					this.reportRequestId = attributes.reportRequest.id;
				} else {
					this.reportRequest = new Models.ReportRequestEntity(attributes.reportRequest);
					this.reportRequestId = this.reportRequest.id;
				}
			} else if (attributes.reportRequestId !== undefined) {
				this.reportRequestId = attributes.reportRequestId;
			}
			if (attributes.radiologist) {
				if (attributes.radiologist instanceof Models.RadiologistEntity) {
					this.radiologist = attributes.radiologist;
					this.radiologistId = attributes.radiologist.id;
				} else {
					this.radiologist = new Models.RadiologistEntity(attributes.radiologist);
					this.radiologistId = this.radiologist.id;
				}
			} else if (attributes.radiologistId !== undefined) {
				this.radiologistId = attributes.radiologistId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		radiologist {
			${Models.RadiologistEntity.getAttributes().join('\n')}
		}
		reportRequest {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}

	public async saveWithRadiologistAndReport() {
		const relationPath = {
			radiologist: {},
			reportRequest: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'radiologist',
							'reportRequest',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.patientId + (this.radiologist?.email ? `, ${this.radiologist.email}` : '');
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}