/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { Model, IModelType } from 'Models/Model';
import EntityCollection, {AdditionalBulkActions} from './EntityCollection';
import EntityAttributeList from './EntityAttributeList';
import EntityEdit from './EntityEdit';
import { getModelDisplayName } from 'Util/EntityUtils';
import SecuredAdminPage from '../Security/SecuredAdminPage';
import { SecurityService } from 'Services/SecurityService';
import { expandFn, ICollectionItemActionProps } from '../Collection/Collection';
import { IEntityContextMenuActions } from '../EntityContextMenu/EntityContextMenu';
import { EntityFormMode } from '../Helpers/Common';
// % protected region % [Add any further imports here] on begin
import { ICollectionHeaderProps } from '../Collection/CollectionHeaders';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { IFilter } from '../Collection/CollectionFilterPanel';
import { IWhereCondition } from '../ModelCollection/CustomModelQuery';
import { IEntityCollectionProps } from "./EntityCollection";
// % protected region % [Add any further imports here] end

interface IEntityCRUDProps<T extends Model> extends RouteComponentProps {
	/** The type of model to render */
	modelType: IModelType;
	/** Function to determine the expanded content of the list */
	expandList?: expandFn<T>;
	/** Number of entities per page */
	perPage?: number;
	/** Context menu actions for each row */
	actionsMore?: IEntityContextMenuActions<T>;
	/** Url suffix to add to the route */
	URLExtension?: string;
	/** Additional actions for the bulk actions menu */
	additionalBulkActions?: Array<AdditionalBulkActions<T>>;
	/** Additional table actions for the collection view */
	additionalTableActions?: Array<ICollectionItemActionProps<T>>;
	/** Remove the view action from the collection */
	removeViewAction?: boolean;
	/** Override for the collection component */
	collectionComponent?: (routeProps: RouteComponentProps) => React.ReactNode;
	/** Override for the create component */
	createComponent?: (routeProps: RouteComponentProps) => React.ReactNode;
	/** Override for the view component */
	viewComponent?: (routeProps: RouteComponentProps) => React.ReactNode;
	/** Override for the edit component */
	editComponent?: (routeProps: RouteComponentProps) => React.ReactNode;
	// % protected region % [Add extra IEntityCRUDProps fields here] on begin
	createTitle?: string;
	sortData?: (data: any) => void;
	addColumns?: (tableheaders: ICollectionHeaderProps<T>[]) => void;
	removeCreateAction?: boolean;
	removeEditAction?: boolean;
	removeDeleteAction?: boolean;
	additionalFilters?: Array<IFilter<T>>;
	customOrderBy?: IOrderByCondition<T>[];
	extraConditions?: IWhereCondition<T>[][];
	adminBackend?: boolean;
	entityCollectionProps?: Partial<IEntityCollectionProps<T>>;
	filterCollection?: Array<Array<IWhereCondition<T>>>;
	// % protected region % [Add extra IEntityCRUDProps fields here] end
}

/**
 * This component is used to render a CRUD (create, read, update, delete) view for a specific entity type.
 */
@observer
class EntityCRUD<T extends Model> extends React.Component<IEntityCRUDProps<T>> {
	private url = () => {
		// % protected region % [Override url function here] off begin
		const { URLExtension, match } = this.props;

		if (URLExtension) {
			return `${match.url}/${URLExtension}`;
		}

		return match.url;
		// % protected region % [Override url function here] end
	};

	public render() {
		const { match, modelType, collectionComponent, createComponent, editComponent, viewComponent } = this.props;

		// Wrap the pages with secured page component
		// % protected region % [Override read list view here] off begin
		const entityCollectionPage = (pageProps: RouteComponentProps) => {
			return (
				<SecuredAdminPage canDo={SecurityService.canRead(modelType)}>
					<this.renderEntityCollection {...pageProps} />
				</SecuredAdminPage>
			);
		};
		// % protected region % [Override read list view here] end

		// % protected region % [Override create view here] off begin
		const entityCreatePage = (pageProps: RouteComponentProps) => {
			return (
				<SecuredAdminPage canDo={SecurityService.canCreate(modelType)}>
					<this.renderEntityCreate {...pageProps} />
				</SecuredAdminPage>
			);
		};
		// % protected region % [Override create view here] end

		// % protected region % [Override read view here] off begin
		const entityViewPage = (pageProps: RouteComponentProps) => {
			return (
				<SecuredAdminPage canDo={SecurityService.canRead(modelType)}>
					<this.renderEntityView {...pageProps} />
				</SecuredAdminPage>
			);
		};
		// % protected region % [Override read view here] end

		// % protected region % [Override edit view here] off begin
		const entityEditPage = (pageProps: RouteComponentProps) => {
			return (
				<SecuredAdminPage canDo={SecurityService.canUpdate(modelType)}>
					<this.renderEntityEdit {...pageProps} />
				</SecuredAdminPage>
			);
		};
		// % protected region % [Override edit view here] end

		// % protected region % [Override return value here] off begin
		return (
			<div>
				<Switch>
					<Route exact={true} path={`${match.url}`} render={collectionComponent ?? entityCollectionPage} />
					<Route path={`${this.url()}/view/:id`} render={viewComponent ?? entityViewPage} />
					<Route exact={true} path={`${this.url()}/create`} render={createComponent ?? entityCreatePage} />
					<Route path={`${this.url()}/edit/:id`} render={editComponent ?? entityEditPage} />
				</Switch>
			</div>
		);
		// % protected region % [Override return value here] end
	};

	protected renderEntityCollection = (routeProps: RouteComponentProps) => {
		// % protected region % [Override entity collection render here] on begin
		const {
			modelType, 
			expandList, 
			perPage, 
			actionsMore, 
			additionalBulkActions, 
			additionalTableActions, 
			additionalFilters, 
			removeViewAction, 
			removeCreateAction, 
			removeEditAction, 
			removeDeleteAction, 
			extraConditions, 
			addColumns, 
			customOrderBy, 
			adminBackend,
			filterCollection,
		} = this.props;
		
		return (
			<EntityCollection
				{...routeProps}
				modelType={modelType}
				expandList={expandList}
				perPage={perPage}
				actionsMore={actionsMore}
				url={this.url()}
				additionalBulkActions={additionalBulkActions}
				additionalTableActions={additionalTableActions}
				removeViewAction={removeViewAction}
				addColumns={addColumns}
				removeCreateAction={removeCreateAction}
				removeEditAction={removeEditAction}
				removeDeleteAction={removeDeleteAction}
				filters={additionalFilters}
				customOrderBy={customOrderBy}
				extraConditions={extraConditions}
				adminBackend={adminBackend}
				conditions={filterCollection}
				{...this.props.entityCollectionProps}
			/>
		);
		// % protected region % [Override entity collection render here] end
	};

	protected renderEntityCreate = (routeProps: RouteComponentProps) => {
		// % protected region % [Override create component render here] on begin
		const { modelType, createTitle } = this.props;
		const modelDisplayName = getModelDisplayName(modelType);
		const title = createTitle ? createTitle : `Create New ${modelDisplayName}`;
		
		return (
			<EntityAttributeList
				{...routeProps}
				model={new modelType()}
				sectionClassName="crud__create"
				title={title}
				formMode={EntityFormMode.CREATE}
				modelType={modelType}
			/>
		);
		// % protected region % [Override create component render here] end
	};

	protected renderEntityEdit = (routeProps: RouteComponentProps) => {
		// % protected region % [Override edit component render here] off begin
		const { modelType } = this.props;
		return <EntityEdit {...routeProps} modelType={modelType} formMode={EntityFormMode.EDIT} />;
		// % protected region % [Override edit component render here] end
	};

	protected renderEntityView = (routeProps: RouteComponentProps) => {
		// % protected region % [Override read component render here] off begin
		const { modelType } = this.props;
		return <EntityEdit {...routeProps} modelType={modelType} formMode={EntityFormMode.VIEW} />;
		// % protected region % [Override read component render here] end
	};

	// % protected region % [Add any extra fields here] off begin
	// % protected region % [Add any extra fields here] end
}

// % protected region % [Override default export here] off begin
export default EntityCRUD;
// % protected region % [Override default export here] end
