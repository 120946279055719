/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { action, observable, runInAction } from 'mobx';
import { IAttributeGroup, Model, IModelAttributes, attribute, entity, jsonReplacerFn } from 'Models/Model';
import * as Validators from 'Validators';
import * as Models from '../Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import { makeFetchManyToManyFunc, makeFetchOneToManyFunc, makeJoinEqualsFunc, makeEnumFetchFunction } from 'Util/EntityUtils';
import { VisitorsAdjudicationAttemptEntity } from 'Models/Security/Acl/VisitorsAdjudicationAttemptEntity';
import { AdminAdjudicationAttemptEntity } from 'Models/Security/Acl/AdminAdjudicationAttemptEntity';
import { RadiologistAdjudicationAttemptEntity } from 'Models/Security/Acl/RadiologistAdjudicationAttemptEntity';
import * as Enums from '../Enums';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
// % protected region % [Add any further imports here] end

export interface IAdjudicationAttemptEntityAttributes extends IModelAttributes {
	status: Enums.adjudicationStatus;
	adjudicatedState: string;

	reportRequestId: string;
	reportRequest: Models.ReportRequestEntity | Models.IReportRequestEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AdjudicationAttemptEntity', 'Adjudication Attempt')
// % protected region % [Customise your entity metadata here] end
export default class AdjudicationAttemptEntity extends Model implements IAdjudicationAttemptEntityAttributes {
	public static acls: IAcl[] = [
		new VisitorsAdjudicationAttemptEntity(),
		new AdminAdjudicationAttemptEntity(),
		new RadiologistAdjudicationAttemptEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Status'] on begin
	/**
	 * Status
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr.toUpperCase(), Enums.adjudicationStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.adjudicationStatusOptions),
		displayFunction: (attribute: Enums.adjudicationStatus) => Enums.adjudicationStatusOptions[attribute],
	})
	public status: Enums.adjudicationStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Adjudicated State'] on begin
	/**
	 * The result of an adjudication attempt
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Adjudicated State',
		displayType: 'textfield',
		order: 20,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public adjudicatedState: string;
	// % protected region % [Modify props to the crud options here for attribute 'Adjudicated State'] end

	/**
	 * Adjudication Attempt
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Request',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequestId: string;
	@observable
	@attribute({isReference: true})
	public reportRequest: Models.ReportRequestEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@CRUD({
		name: 'Report Request',
		displayType: 'hidden',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		customAttributeName: 'reportRequest.patientId',
		displayFunction: (attr, that) => that['reportRequest'] ? that['reportRequest']['patientId'] : "",
	})
	@computed
	public get reportRequestName(): string {
		return this.reportRequest.patientId;
	}
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IAdjudicationAttemptEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAdjudicationAttemptEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.status) {
				this.status = attributes.status;
			}
			if (attributes.adjudicatedState) {
				this.adjudicatedState = attributes.adjudicatedState;
			}
			if (attributes.reportRequest) {
				if (attributes.reportRequest instanceof Models.ReportRequestEntity) {
					this.reportRequest = attributes.reportRequest;
					this.reportRequestId = attributes.reportRequest.id;
				} else {
					this.reportRequest = new Models.ReportRequestEntity(attributes.reportRequest);
					this.reportRequestId = this.reportRequest.id;
				}
			} else if (attributes.reportRequestId !== undefined) {
				this.reportRequestId = attributes.reportRequestId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		reportRequest {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public listExpands = `
    reportRequest {
        patientId
    }
	`;
	// % protected region % [Add any further custom model features here] end
}