/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

export type adjudicationStatus =
	// % protected region % [Override adjudicationStatus keys here] off begin
	'PASSED' |
		'FAILED';
	// % protected region % [Override adjudicationStatus keys here] end

export const adjudicationStatusOptions: { [key in adjudicationStatus]: string } = {
	// % protected region % [Override adjudicationStatus display fields here] off begin
	PASSED: 'PASSED',
	FAILED: 'FAILED',
	// % protected region % [Override adjudicationStatus display fields here] end
};

export type reportModality =
	// % protected region % [Override reportModality keys here] off begin
	'ICOERD' |
		'ILO' |
		'CARDIOLOGY';
	// % protected region % [Override reportModality keys here] end

export const reportModalityOptions: { [key in reportModality]: string } = {
	// % protected region % [Override reportModality display fields here] off begin
	ICOERD: 'ICOERD',
	ILO: 'ILO',
	CARDIOLOGY: 'CARDIOLOGY',
	// % protected region % [Override reportModality display fields here] end
};

export type requestStates =
	// % protected region % [Override requestStates keys here] off begin
	'IN_PROGRESS' |
		'COMPLETED' |
		'AWAITING_MANUAL_ADJUDICATION' |
		'INVALID';
	// % protected region % [Override requestStates keys here] end

export const requestStatesOptions: { [key in requestStates]: string } = {
	// % protected region % [Override requestStates display fields here] off begin
	IN_PROGRESS: 'IN PROGRESS',
	COMPLETED: 'COMPLETED',
	AWAITING_MANUAL_ADJUDICATION: 'AWAITING MANUAL ADJUDICATION',
	INVALID: 'INVALID',
	// % protected region % [Override requestStates display fields here] end
};

export type timespan =
	// % protected region % [Override timespan keys here] off begin
	'DAILY' |
		'WEEKLY' |
		'MONTHLY';
	// % protected region % [Override timespan keys here] end

export const timespanOptions: { [key in timespan]: string } = {
	// % protected region % [Override timespan display fields here] off begin
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
	// % protected region % [Override timespan display fields here] end
};

export type viewingClient =
	// % protected region % [Override viewingClient keys here] off begin
	'WEBCLIENT' |
		'THINKCLIENT';
	// % protected region % [Override viewingClient keys here] end

export const viewingClientOptions: { [key in viewingClient]: string } = {
	// % protected region % [Override viewingClient display fields here] off begin
	WEBCLIENT: 'WEBCLIENT',
	THINKCLIENT: 'THINKCLIENT',
	// % protected region % [Override viewingClient display fields here] end
};

// % protected region % [Add any extra enums here] off begin
// % protected region % [Add any extra enums here] end
