/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { action, observable, runInAction } from 'mobx';
import { IAttributeGroup, Model, IModelAttributes, attribute, entity, jsonReplacerFn } from 'Models/Model';
import * as Validators from 'Validators';
import * as Models from '../Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import { makeFetchManyToManyFunc, makeFetchOneToManyFunc, makeJoinEqualsFunc, makeEnumFetchFunction } from 'Util/EntityUtils';
import { VisitorsRadiologistEntity } from 'Models/Security/Acl/VisitorsRadiologistEntity';
import { AdminRadiologistEntity } from 'Models/Security/Acl/AdminRadiologistEntity';
import { RadiologistRadiologistEntity } from 'Models/Security/Acl/RadiologistRadiologistEntity';
import * as Enums from '../Enums';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] on begin
import { gql } from 'apollo-boost';
import { getModelName, getAttributes } from 'Util/EntityUtils';
import { lowerCaseFirst } from '../../Util/StringUtils';
// % protected region % [Add any further imports here] end

export interface IRadiologistEntityAttributes extends IModelAttributes {
	email: string;
	reportTimespan: Enums.timespan;
	firstName: string;
	lastName: string;
	twilioID: number;
	adjudicator: boolean;
	reportLimit: number;
	mobileNumber: string;
	iloCertified: boolean;
	icoerdCertified: boolean;
	medicalRegistrationNumber: string;
	medicalRegistrationNumberExpiry: Date;
	dnrmeDepartmentApprovalRegistration: string;
	dnrmeDepartmentApprovalRegistrationExpiry: Date;
	nioshBReaderCertificate: string;
	nioshBReaderCertificateExpiry: Date;
	annualMedicalIndemnityInsurance: string;
	annualMedicalIndemnityInsuranceExpiry: Date;
	ranzacRegistration: string;
	ranzacRegistrationExpiry: Date;
	portalId: number;
	restrictAccess: boolean;
	viewingClient: Enums.viewingClient;
	multifactorenabled: boolean;
	cardiology: boolean;

	reportSubmissions: Array<Models.ReportSubmissionEntity | Models.IReportSubmissionEntityAttributes>;
	checkOut?: Models.CheckOutEntity | Models.ICheckOutEntityAttributes;
	parentGroups: Array<Models.ParentGroupGroupMember | Models.IParentGroupGroupMemberAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RadiologistEntity', 'Radiologist')
// % protected region % [Customise your entity metadata here] end
export default class RadiologistEntity extends Model implements IRadiologistEntityAttributes {
	public static acls: IAcl[] = [
		new VisitorsRadiologistEntity(),
		new AdminRadiologistEntity(),
		new RadiologistRadiologistEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] on begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 40,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] on begin
	@Validators.Length(6)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		createFieldType: 'hidden',
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] on begin
	@Validators.Custom('Password Match', (e: string, target: RadiologistEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		createFieldType: 'hidden',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Report Timespan'] on begin
	/**
	 * Used with Number of reports to determine how many reports a radiologist can complete in a timespan
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Report Timespan',
		displayType: 'enum-combobox',
		headerColumn: true,
		createFieldType: 'hidden',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.timespanOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.timespanOptions),
		displayFunction: (attribute: Enums.timespan) => Enums.timespanOptions[attribute],
	})
	public reportTimespan: Enums.timespan;
	// % protected region % [Modify props to the crud options here for attribute 'Report Timespan'] end

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] on begin
	/**
	 * First Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] on begin
	/**
	 * Last Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Twilio ID'] on begin
	/**
	 * Twilio ID
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Twilio ID',
		displayType: 'displayfield',
		createFieldType: 'hidden',
		searchTransform: AttrUtils.standardiseString,
	})
	public twilioID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Twilio ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Adjudicator'] on begin
	/**
	 * Adjudicator
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Adjudicator',
		displayType: 'checkbox',
		order: 80,
		headerColumn: true,
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public adjudicator: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Adjudicator'] end

	// % protected region % [Modify props to the crud options here for attribute 'Report Limit'] on begin
	/**
	 * This field is used to limit the number of reports a radiologist can complete in a time period
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Report Limit',
		displayType: 'textfield',
		order: 90,
		searchTransform: AttrUtils.standardiseInteger,
	})
	public reportLimit: number;
	// % protected region % [Modify props to the crud options here for attribute 'Report Limit'] end

	// % protected region % [Modify props to the crud options here for attribute 'Mobile Number'] on begin
	/**
	 * Mobile Number
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Mobile Number',
		displayType: 'textfield',
		order: 40,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public mobileNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Mobile Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'ILO Certified'] on begin
	/**
	 * Can report on ILO reports
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'ILO Certified',
		displayType: 'checkbox',
		order: 110,
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public iloCertified: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'ILO Certified'] end

	// % protected region % [Modify props to the crud options here for attribute 'ICOERD Certified'] on begin
	/**
	 * Can report on ICOERD reports
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'ICOERD Certified',
		displayType: 'checkbox',
		order: 120,
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public icoerdCertified: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'ICOERD Certified'] end

	// % protected region % [Modify props to the crud options here for attribute 'Medical Registration Number'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Medical Registration Number',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public medicalRegistrationNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Medical Registration Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Medical Registration Number Expiry'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Medical Registration Number Expiry',
		displayType: 'datepicker',
		order: 140,
		searchTransform: AttrUtils.standardiseDate,
	})
	public medicalRegistrationNumberExpiry: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Medical Registration Number Expiry'] end

	// % protected region % [Modify props to the crud options here for attribute 'DNRME Department Approval Registration'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'DNRME Department Approval Registration',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public dnrmeDepartmentApprovalRegistration: string;
	// % protected region % [Modify props to the crud options here for attribute 'DNRME Department Approval Registration'] end

	// % protected region % [Modify props to the crud options here for attribute 'DNRME Department Approval Registration Expiry'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'DNRME Department Approval Registration Expiry',
		displayType: 'datepicker',
		order: 160,
		searchTransform: AttrUtils.standardiseDate,
	})
	public dnrmeDepartmentApprovalRegistrationExpiry: Date;
	// % protected region % [Modify props to the crud options here for attribute 'DNRME Department Approval Registration Expiry'] end

	// % protected region % [Modify props to the crud options here for attribute 'Niosh B Reader Certificate'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Niosh B Reader Certificate',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public nioshBReaderCertificate: string;
	// % protected region % [Modify props to the crud options here for attribute 'Niosh B Reader Certificate'] end

	// % protected region % [Modify props to the crud options here for attribute 'Niosh B Reader Certificate Expiry'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Niosh B Reader Certificate Expiry',
		displayType: 'datepicker',
		order: 180,
		searchTransform: AttrUtils.standardiseDate,
	})
	public nioshBReaderCertificateExpiry: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Niosh B Reader Certificate Expiry'] end

	// % protected region % [Modify props to the crud options here for attribute 'Annual Medical Indemnity Insurance'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Annual Medical Indemnity Insurance',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public annualMedicalIndemnityInsurance: string;
	// % protected region % [Modify props to the crud options here for attribute 'Annual Medical Indemnity Insurance'] end

	// % protected region % [Modify props to the crud options here for attribute 'Annual Medical Indemnity Insurance Expiry'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Annual Medical Indemnity Insurance Expiry',
		displayType: 'datepicker',
		order: 200,
		searchTransform: AttrUtils.standardiseDate,
	})
	public annualMedicalIndemnityInsuranceExpiry: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Annual Medical Indemnity Insurance Expiry'] end

	// % protected region % [Modify props to the crud options here for attribute 'RANZAC Registration'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'RANZAC Registration',
		displayType: 'textfield',
		order: 210,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public ranzacRegistration: string;
	// % protected region % [Modify props to the crud options here for attribute 'RANZAC Registration'] end

	// % protected region % [Modify props to the crud options here for attribute 'RANZAC Registration Expiry'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'RANZAC Registration Expiry',
		displayType: 'datepicker',
		order: 220,
		searchTransform: AttrUtils.standardiseDate,
	})
	public ranzacRegistrationExpiry: Date;
	// % protected region % [Modify props to the crud options here for attribute 'RANZAC Registration Expiry'] end

	// % protected region % [Modify props to the crud options here for attribute 'Portal Id'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Portal Id',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public portalId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Portal Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Restrict Access'] on begin
	/**
	 * Restrict radiologist access to groups
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Restrict Access',
		displayType: 'checkbox',
		order: 269,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public restrictAccess: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Restrict Access'] end

	// % protected region % [Modify props to the crud options here for attribute 'Viewing Client'] off begin
	/**
	 * The viewing client used by the radiologist
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Viewing Client',
		displayType: 'enum-combobox',
		order: 250,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.viewingClientOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.viewingClientOptions),
		displayFunction: (attribute: Enums.viewingClient) => Enums.viewingClientOptions[attribute],
	})
	public viewingClient: Enums.viewingClient;
	// % protected region % [Modify props to the crud options here for attribute 'Viewing Client'] end

	// % protected region % [Modify props to the crud options here for attribute 'MultiFactorEnabled'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'MultiFactorEnabled',
		displayType: 'checkbox',
		order: 260,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public multifactorenabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'MultiFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cardiology'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Cardiology',
		displayType: 'checkbox',
		order: 270,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public cardiology: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Cardiology'] end

	/**
	 * Report Submission
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Submission'] on begin
		name: "Report Submissions",
		displayType: 'hidden',
		order: 270,
		referenceTypeFunc: () => Models.ReportSubmissionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportSubmissions',
			oppositeEntity: () => Models.ReportSubmissionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Submission'] end
	})
	public reportSubmissions: Models.ReportSubmissionEntity[] = [];

	/**
	 * Check Out
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Check Out'] off begin
		name: 'Check Out',
		displayType: 'displayfield',
		order: 290,
		inputProps: {
			displayFunction: (model?: Models.CheckOutEntity) => model ? model.getDisplayName() : null,
		},
		// % protected region % [Modify props to the crud options here for reference 'Check Out'] end
	})
	public checkOut?: Models.CheckOutEntity;

	/**
	 * Group Member
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parent Group'] on begin
		name: 'Parent Groups',
		displayType: 'reference-multicombobox',
		order: 270,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ParentGroupGroupMember,
		optionEqualFunc: makeJoinEqualsFunc('parentGroupId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'radiologistEntity',
			oppositeEntityName: 'groupEntity',
			relationName: 'groupMember',
			relationOppositeName: 'parentGroup',
			entity: () => Models.RadiologistEntity,
			joinEntity: () => Models.ParentGroupGroupMember,
			oppositeEntity: () => Models.GroupEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Parent Group'] end
	})
	public parentGroups: Models.ParentGroupGroupMember[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IRadiologistEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRadiologistEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email) {
				this.email = attributes.email;
			}
			if (attributes.reportTimespan) {
				this.reportTimespan = attributes.reportTimespan;
			}
			if (attributes.firstName) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName) {
				this.lastName = attributes.lastName;
			}
			if (attributes.twilioID) {
				this.twilioID = attributes.twilioID;
			}
			if (attributes.adjudicator) {
				this.adjudicator = attributes.adjudicator;
			}
			if (attributes.reportLimit) {
				this.reportLimit = attributes.reportLimit;
			}
			if (attributes.mobileNumber) {
				this.mobileNumber = attributes.mobileNumber;
			}
			if (attributes.iloCertified) {
				this.iloCertified = attributes.iloCertified;
			}
			if (attributes.icoerdCertified) {
				this.icoerdCertified = attributes.icoerdCertified;
			}
			if (attributes.medicalRegistrationNumber) {
				this.medicalRegistrationNumber = attributes.medicalRegistrationNumber;
			}
			if (attributes.medicalRegistrationNumberExpiry) {
				this.medicalRegistrationNumberExpiry = moment(attributes.medicalRegistrationNumberExpiry).toDate();
			}
			if (attributes.dnrmeDepartmentApprovalRegistration) {
				this.dnrmeDepartmentApprovalRegistration = attributes.dnrmeDepartmentApprovalRegistration;
			}
			if (attributes.dnrmeDepartmentApprovalRegistrationExpiry) {
				this.dnrmeDepartmentApprovalRegistrationExpiry = moment(attributes.dnrmeDepartmentApprovalRegistrationExpiry).toDate();
			}
			if (attributes.nioshBReaderCertificate) {
				this.nioshBReaderCertificate = attributes.nioshBReaderCertificate;
			}
			if (attributes.nioshBReaderCertificateExpiry) {
				this.nioshBReaderCertificateExpiry = moment(attributes.nioshBReaderCertificateExpiry).toDate();
			}
			if (attributes.annualMedicalIndemnityInsurance) {
				this.annualMedicalIndemnityInsurance = attributes.annualMedicalIndemnityInsurance;
			}
			if (attributes.annualMedicalIndemnityInsuranceExpiry) {
				this.annualMedicalIndemnityInsuranceExpiry = moment(attributes.annualMedicalIndemnityInsuranceExpiry).toDate();
			}
			if (attributes.ranzacRegistration) {
				this.ranzacRegistration = attributes.ranzacRegistration;
			}
			if (attributes.ranzacRegistrationExpiry) {
				this.ranzacRegistrationExpiry = moment(attributes.ranzacRegistrationExpiry).toDate();
			}
			if (attributes.portalId) {
				this.portalId = attributes.portalId;
			}
			if (attributes.restrictAccess) {
				this.restrictAccess = attributes.restrictAccess;
			}
			if (attributes.viewingClient) {
				this.viewingClient = attributes.viewingClient;
			}
			if (attributes.multifactorenabled) {
				this.multifactorenabled = attributes.multifactorenabled;
			}
			if (attributes.cardiology) {
				this.cardiology = attributes.cardiology;
			}
			if (attributes.reportSubmissions) {
				for (const model of attributes.reportSubmissions) {
					if (model instanceof Models.ReportSubmissionEntity) {
						this.reportSubmissions.push(model);
					} else {
						this.reportSubmissions.push(new Models.ReportSubmissionEntity(model));
					}
				}
			}
			if (attributes.checkOut) {
				if (attributes.checkOut instanceof Models.CheckOutEntity) {
					this.checkOut = attributes.checkOut;
				} else {
					this.checkOut = new Models.CheckOutEntity(attributes.checkOut);
				}
			}
			if (attributes.parentGroups) {
				for (const model of attributes.parentGroups) {
					if (model instanceof Models.ParentGroupGroupMember) {
						this.parentGroups.push(model);
					} else {
						this.parentGroups.push(new Models.ParentGroupGroupMember(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		parentGroups {
			${Models.ParentGroupGroupMember.getAttributes().join('\n')}
			parentGroup {
				${Models.GroupEntity.getAttributes().join('\n')}
			}
		}
		checkOut {
			${Models.CheckOutEntity.getAttributes().join('\n')}
		}
	`;

	public profileExpands = `
		parentGroups {
			${Models.ParentGroupGroupMember.getAttributes().join('\n')}
			parentGroup {
				${Models.GroupEntity.getAttributes().join('\n')}
			}
		}
		reportSubmissions {
			${Models.ReportSubmissionEntity.getAttributes().join('\n')}
		}
		checkOut {
			${Models.CheckOutEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			parentGroups: {},
			reportSubmissions: {},
		};

		if (formMode === 'create') {
			relationPath['password'] = {};

			if (this.password !== this._confirmPassword) {
				throw "Password fields do not match";
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'parentGroups',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public static getFetchSingleQueryProfilePage(reports? : boolean) {
		const model = new RadiologistEntity();
		const modelName = lowerCaseFirst(getModelName(RadiologistEntity));
		// [LUNSD-238] switch query based on if profile page or not
		const expands = reports ? model.profileExpands : model.defaultExpands;

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(RadiologistEntity).join('\n')}
					${expands}
				}
			}`;
	}

	public async saveWithCheckOut() {
		const relationPath = {
			checkOut: {},
		};

		return this.save(
			relationPath,
			{
				options: [],
			},
		);
	}
	// % protected region % [Add any further custom model features here] end
}