import axios, { AxiosError } from 'axios';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import * as React from 'react';
import { ChangeEvent } from 'react';
import alert from '../../Util/ToastifyUtils';
import { FormTile, ReportProps } from './ProduceReportTile';
import moment from 'moment';

export interface ILOReportState {
	imageQuality: number;
	imageDefects: string[];
	imageDefectsOther: string;

	parenchymalAbnormalities: boolean | null;
	smallOpacities: {
		shapePrimary: string;
		shapeSecondary: string;
		zones: string[];
		profusion: string;
	};
	largeOpacities: string;

	pleuralAbnormalities: boolean | null;
	pleuralPlaques: {
		site: string[];
		calcification: string[];
		extent: {
			right: {
				letter: string;
				number: number;
			};
			left: {
				letter: string;
				number: number;
			};
		};
		width: {
			right: {
				letter: string;
				number: number;
			};
			left: {
				letter: string;
				number: number;
			};
		};
	};

	costophrenicAngleObliteration: string[];
	costophrenicAngleObliterations: {
		site: string[];
		calcification: string[];
		extent: {
			right: {
				letter: string;
				number: number;
			};
			left: {
				letter: string;
				number: number;
			};
		};
		width: {
			right: {
				letter: string;
				number: number;
			};
			left: {
				letter: string;
				number: number;
			};
		};
	};
	otherAbnormalities: boolean | null;
	otherSymbols: string[];
	otherAbnormalitiesList: string[];
	otherComments: string;

	approveStudy: boolean;
	seePhysician: string;
	dateDoctorNotified: string | null;
}

@observer
export default class ProduceILOReportTile extends React.Component<ReportProps> {
	@observable
	public reportState: ILOReportState;

	private toggleStandards: Function;

	private openStudy: Function;

	constructor(props: ReportProps) {
		super(props);

		const { toggleStandards, openStudy } = props;
		this.toggleStandards = toggleStandards;
		this.openStudy = openStudy;
	}

	componentDidMount(): void {
		const { reportSnapshot } = this.props;
		if (
			reportSnapshot
			&& 'imageQuality' in reportSnapshot
		) {
			this.setReportState(reportSnapshot);
		} else {
			this.clearReportState();
		}
	}

	public render(): JSX.Element | null {
		let contents = null;

		const { report, formState } = this.props;

		if (this.reportState) {
			contents = (
				<>
					<div className="report-sidebar">
						<div className="personal-identifier">
							<h4>
								{report.firstName} 
								{' '}
								{report.lastName}
							</h4>
							{report.urgent && (
								<span className="report-type urgent">Urgent</span>
							)}
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.IndicateImageQuality)}
							role="button"
							tabIndex={0}
						>
							<div
								className={`numberCircle ${this.imageQualityDone() && 'done'}`}
							>
								1
							</div>
							<span
								className={`${
									formState === FormTile.IndicateImageQuality && 'active'
								}`}
							>
								Indicate Image Quality
							</span>
						</div>

						<div className="sidebar-button-reverse">
							<h4>Abnormalities</h4>
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.ParenchymalAbnormalities)}
							role="button"
							tabIndex={0}
						>
							<div
								className={`numberCircle ${this.parenchymalDone() && 'done'}`}
							>
								2
							</div>
							<span
								className={`${
									formState === FormTile.ParenchymalAbnormalities && 'active'
								}`}
							>
								Parenchymal Abnormalities
							</span>
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.PleuralAbnormalities)}
							role="button"
							tabIndex={0}
						>
							<div className={`numberCircle ${this.pleuralDone() && 'done'}`}>
								3
							</div>
							<span
								className={`${
									formState === FormTile.PleuralAbnormalities && 'active'
								}`}
							>
								Pleural Abnormalities
							</span>
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.OtherAbnormalities)}
							role="button"
							tabIndex={0}
						>
							<div
								className={`numberCircle ${
									this.otherAbnormalitiesDone() && 'done'
								}`}
							>
								4
							</div>
							<span
								className={`${
									formState === FormTile.OtherAbnormalities && 'active'
								}`}
							>
								Other Abnormalities
							</span>
						</div>

						<div className="sidebar-checkbox">
							<button
								onClick={() => this.approveStudy()}
								type="button"
								className={`btn ${this.reportState.approveStudy && 'active'}`}
							>
								X
							</button>
							<span>
								Approve study ILO category 0/0 for pneumoconiosis and no
								evidence of any other abnormalities
							</span>
						</div>

						<br />
						<div className="sidebar-button-reverse standards">
							<div className="sidebar-button-standards-container">
								<button
									type="button"
									className="btn btn--solid"
									onClick={(): void => this.toggleStandards()}
								>
									Standards List
								</button>
							</div>
							<div className="sidebar-button-study-container">
								<button
									type="button"
									className="btn btn--solid"
									onClick={(): void => {
										this.openStudy();
									}}
								>
									Reopen Study
								</button>
							</div>
						</div>
					</div>

					<div className="report-form">
						{formState === FormTile.IndicateImageQuality
							&& this.renderImageQualityTile()}

						{formState === FormTile.ParenchymalAbnormalities
							&& this.renderParenchymalAbnormalities()}

						{formState === FormTile.PleuralAbnormalities
							&& this.renderPleuralAbnormalities()}

						{formState === FormTile.OtherAbnormalities
							&& this.renderOtherAbnormalities()}
					</div>
				</>
			);
		}

		return contents;
	}

	@action
	setReportState = (reportState: ILOReportState): void => {
		this.reportState = reportState;
	};

	@action
	clearReportState = (): void => {
		this.reportState = {
			imageQuality: 0,
			imageDefects: [],
			imageDefectsOther: '',
			parenchymalAbnormalities: null,
			smallOpacities: {
				shapePrimary: '',
				shapeSecondary: '',
				zones: [],
				profusion: '',
			},
			largeOpacities: '',
			pleuralAbnormalities: null,
			pleuralPlaques: {
				site: [],
				calcification: [],
				extent: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
				width: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
			},
			costophrenicAngleObliteration: [],
			costophrenicAngleObliterations: {
				site: [],
				calcification: [],
				extent: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
				width: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
			},
			approveStudy: false,
			otherAbnormalities: null,
			otherSymbols: [],
			seePhysician: 'No',
			dateDoctorNotified: null,
			otherAbnormalitiesList: [],
			otherComments: '',
		};
	};

	imageQualityErrors = (): string[] => {
		const errors: string[] = [];

		if (this.reportState.imageQuality === 0) {
			errors.push('Indicate image quality');
		}

		if (
			(this.reportState.imageQuality === 2
				|| this.reportState.imageQuality === 3)
			&& this.reportState.imageDefects.length === 0
		) {
			errors.push('Indicate image defects');
		}

		if (
			this.reportState.imageDefects.includes('Other')
			&& this.reportState.imageDefectsOther.length === 0
		) {
			errors.push('Indicate other image defects');
		}

		return errors;
	};

	imageQualityDone = (): boolean => this.imageQualityErrors().length === 0;

	parenchymalErrors = (): string[] => {
		const errors = [];

		if (this.reportState.parenchymalAbnormalities === null) {
			errors.push('Indicate parenchymal abnormalities');
		}

		if (this.reportState.parenchymalAbnormalities) {
			if (this.reportState.smallOpacities.shapePrimary === '') {
				errors.push('Indicate small opacities primary shape');
			}

			if (this.reportState.smallOpacities.shapeSecondary === '') {
				errors.push('Indicate small opacities secondary shape');
			}

			if (this.reportState.smallOpacities.zones.length === 0) {
				errors.push('Indicate small opacity zones');
			}

			if (this.reportState.smallOpacities.profusion === '') {
				errors.push('Indicate small opacity profusions');
			}

			if (this.reportState.largeOpacities.length === 0) {
				errors.push('Indicate large opacity size');
			}
		}

		return errors;
	};

	parenchymalDone = (): boolean => this.parenchymalErrors().length === 0;

	pleuralErrors = (): string[] => {
		const errors: string[] = [];

		// ----- Pleural Plaques -----
		if (this.reportState.pleuralAbnormalities === null) {
			errors.push('Indicate pleural abnormalities');
		}

		if (this.reportState.pleuralAbnormalities) {
			// Check if each section (row) has a value
			if (
				!['In profile', 'Face on', 'Diaphragm', 'Other site(s)'].every((val) => this.reportState.pleuralPlaques.site
					.map((e) => e.slice(0, -1))
					.includes(val))
			) {
				errors.push('Indicate pleural plaque sites');
			}

			if (
				!['In profile', 'Face on', 'Diaphragm', 'Other site(s)'].every((val) => this.reportState.pleuralPlaques.calcification
					.map((e) => e.slice(0, -1))
					.includes(val))
			) {
				errors.push('Indicate pleural plaque calcifications');
			}

			// If value is not "O", a number must be selected
			if (
				this.reportState.pleuralPlaques.extent.right.letter === ''
				|| (this.reportState.pleuralPlaques.extent.right.letter === 'R'
					&& this.reportState.pleuralPlaques.extent.right.number === 0)
			) {
				errors.push('Indicate pleural plaque extent (right)');
			}

			if (
				this.reportState.pleuralPlaques.extent.left.letter === ''
				|| (this.reportState.pleuralPlaques.extent.left.letter === 'L'
					&& this.reportState.pleuralPlaques.extent.left.number === 0)
			) {
				errors.push('Indicate pleural plaque extent (left)');
			}

			if (
				this.reportState.pleuralPlaques.width.right.letter === ''
				|| (this.reportState.pleuralPlaques.width.right.letter === 'R'
					&& this.reportState.pleuralPlaques.width.right.number === 0)
			) {
				errors.push('Indicate pleural plaque width (right)');
			}

			if (
				this.reportState.pleuralPlaques.width.left.letter === ''
				|| (this.reportState.pleuralPlaques.width.left.letter === 'L'
					&& this.reportState.pleuralPlaques.width.left.number === 0)
			) {
				errors.push('Indicate pleural plaque width');
			}
		}

		// ---- Costophrenic Angle Obliteration -----
		if (this.reportState.costophrenicAngleObliteration.length === 0) {
			errors.push('Indicate costophrenic angle obliteration');
		}

		if (
			this.reportState.costophrenicAngleObliteration.length !== 0
			&& !this.reportState.costophrenicAngleObliteration.includes('No')
		) {
			// Check if each section (row) has a value
			if (
				!['In profile', 'Face on'].every((val) => this.reportState.costophrenicAngleObliterations.site
					.map((e) => e.slice(0, -1))
					.includes(val))
			) {
				errors.push('Indicate diffuse pleural thickening site');
			}

			if (
				!['In profile', 'Face on'].every((val) => this.reportState.costophrenicAngleObliterations.calcification
					.map((e) => e.slice(0, -1))
					.includes(val))
			) {
				errors.push('Indicate diffuse pleural thickening calcification');
			}

			// If value is not "O", a number must be selected
			if (
				this.reportState.costophrenicAngleObliterations.extent.right.letter
					=== 'R'
				&& this.reportState.costophrenicAngleObliterations.extent.right.number
					=== 0
			) {
				errors.push('Indicate pleural thickening extent (right)');
			}
			if (
				this.reportState.costophrenicAngleObliterations.extent.left.letter
					=== 'L'
				&& this.reportState.costophrenicAngleObliterations.extent.left.number === 0
			) {
				errors.push('Indicate pleural thickening extent (left)');
			}

			if (
				this.reportState.costophrenicAngleObliterations.width.right.letter
					=== 'R'
				&& this.reportState.costophrenicAngleObliterations.width.right.number === 0
			) {
				errors.push('Indicate diffuse pleural thickening width (right)');
			}
			if (
				this.reportState.costophrenicAngleObliterations.width.left.letter
					=== 'L'
				&& this.reportState.costophrenicAngleObliterations.width.left.number === 0
			) {
				errors.push('Indicate diffuse pleural thickening width (left)');
			}
		}

		return errors;
	};

	pleuralDone = (): boolean => this.pleuralErrors().length === 0;

	otherErrors = (): string[] => {
		const errors = [];
		if (this.reportState.otherAbnormalities === null) {
			errors.push('Indicate other abnormalities present');
		}

		if (this.reportState.otherAbnormalities) {
			if (
				this.reportState.otherSymbols.length === 0
				&& this.reportState.otherComments === ''
				&& this.reportState.otherAbnormalitiesList.length === 0
			) {
				errors.push('Indicate other abnormalities');
			}
		}

		if (!this.reportState.seePhysician) {
			errors.push('Should worker see physician?');
		}

		return errors;
	};

	otherAbnormalitiesDone = (): boolean => this.otherErrors().length === 0;

	validateReport = (): boolean => {
		if (
			this.imageQualityDone()
			&& this.parenchymalDone()
			&& this.pleuralDone()
			&& this.otherAbnormalitiesDone()
		) {
			return true;
		} 
		let jointErrors: string[] = [];

		jointErrors = jointErrors.concat(this.imageQualityErrors());
		jointErrors = jointErrors.concat(this.parenchymalErrors());
		jointErrors = jointErrors.concat(this.pleuralErrors());
		jointErrors = jointErrors.concat(this.otherErrors());

		// Show all errors up to a defined number
		const maxErrors = 3;
		const numErrors =				jointErrors.length <= maxErrors ? jointErrors.length : maxErrors;

		// If there are too many errors, print the max - 1, and then say how many more
		// ie. maxErrors = 3 means it will show 2 errors, with the third toast saying "and x more"
		if (numErrors >= maxErrors) {
			for (let i = 0; i < maxErrors - 1; i++) {
				alert(`${jointErrors[i]}`, 'error');
			}

			alert(`...and ${jointErrors.length - maxErrors + 1} more...`, 'error');
		} else {
			for (let i = 0; i < numErrors; i++) {
				alert(`${jointErrors[i]}`, 'error');
			}
		}

		return false;
	};

	@action
	approveStudy = () => {
		if (this.reportState.imageQuality === 4) {
			alert('Study has been marked un-readable', 'error');
		} else if (this.reportState.approveStudy) {
			this.reportState.approveStudy = false;
		} else {
			const existingImageQuality = this.reportState.imageQuality;
			const existingImageDefects = this.reportState.imageDefects;
			const existingImageDefectsOther = this.reportState.imageDefectsOther;
			this.clearReportState();
			this.reportState.imageQuality = existingImageQuality;
			this.reportState.imageDefects = existingImageDefects;
			this.reportState.imageDefectsOther = existingImageDefectsOther;
			this.reportState.approveStudy = true;
			this.reportState.parenchymalAbnormalities = false;
			this.reportState.pleuralAbnormalities = false;
			this.reportState.costophrenicAngleObliteration = ['No'];
			this.reportState.otherAbnormalities = false;
		}
	};

	renderImageQualityTile = (): React.ReactNode => (
		<div className="report-tile image-quality-tile">
			<div className="report-section">
				<h4>1. Image Quality</h4>
				<div className="button-group">
					{[1, 2, 3, 4].map((value) => (
						<button
							type="button"
							className={`btn ${
								this.reportState.imageQuality === value && 'active'
							}`}
							onClick={() => this.setImageQuality(value)}
							key={value}
						>
							{value !== 4 ? value : 'U/R'}
						</button>
					))}
				</div>

				{this.reportState.imageQuality === 1 && (
					<span className="bold gap">Good.</span>
				)}

				{this.reportState.imageQuality === 2 && (
					<span className="bold gap">
						Acceptable, with no technical defects likely to impair
						classification of the radiograph for pneumoconiosis.
					</span>
				)}

				{this.reportState.imageQuality === 3 && (
					<span className="bold gap">
						Acceptable, with some technical defect but still adequate for
						classification purposes.
					</span>
				)}

				{this.reportState.imageQuality === 4 && (
					<span className="bold gap">Un-readable.</span>
				)}

				{this.reportState.imageQuality !== 0
					&& this.reportState.imageQuality !== 1
					&& this.reportState.imageQuality !== 4 && (
					<div className="button-group">
						{[
							'Overexposed (dark)',
							'Underexposed (light)',
							'Artifacts',
							'Improper position',
							'Poor contrast',
							'Poor Processing',
							'Underinflation',
							'Mottle',
							'Excessive Edge Enhancement',
							'Other',
						].map((value) => (
							<button
								type="button"
								className={`btn ${
									this.reportState.imageDefects.includes(value) && 'active'
								}`}
								onClick={(): void => this.addImageDefect(value)}
								key={value}
							>
								{value}
							</button>
						))}
					</div>
				)}

				{this.reportState.imageDefects.includes('Other') && (
					<div className="comment-box">
						<span className="bold">Other Defects:</span>
						<textarea
							value={this.reportState.imageDefectsOther}
							onChange={this.handleOtherChange}
							rows={3}
						/>
					</div>
				)}
			</div>
		</div>
	);

	renderParenchymalAbnormalities = (): React.ReactNode => (
		<div className="report-tile">
			<div className="report-section">
				<h4>2A. Any parenchymal abnormalities?</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setParenchymalAbnormalities(true)}
						className={`btn ${
							this.reportState.parenchymalAbnormalities && 'active'
						}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={() => this.setParenchymalAbnormalities(false)}
						className={`btn ${
							this.reportState.parenchymalAbnormalities === false && 'active'
						}`}
					>
						No
					</button>
				</div>
			</div>

			{this.reportState.parenchymalAbnormalities && (
				<div className="report-section space">
					<h4>2B. Small opacities</h4>
					<div className="report-subsection flex ignore-cols">
						<div className="report-subsection-element">
							<h5>a. Shape / Size</h5>

							<div className="flex">
								<div>
									<h5>Primary</h5>
									<div className="button-grid grid-col-2">
										{['p', 's', 'q', 't', 'r', 'u'].map((shape) => (
											<button
												type="button"
												className={`btn small-opacity-button ${
													this.reportState.smallOpacities.shapePrimary.includes(
														shape,
													) && 'active'
												}`}
												onClick={(): void => this.addSmallOpacitiesShapePrimary(shape)}
												key={shape}
											>
												{shape}
											</button>
										))}
									</div>
								</div>

								<div>
									<h5>Secondary</h5>
									<div className="button-grid grid-col-2">
										{['p', 's', 'q', 't', 'r', 'u'].map((shape) => (
											<button
												type="button"
												className={`btn small-opacity-button ${
													this.reportState.smallOpacities.shapeSecondary.includes(
														shape,
													) && 'active'
												}`}
												onClick={(): void => this.addSmallOpacitiesShapeSecondary(shape)}
												key={shape}
											>
												{shape}
											</button>
										))}
									</div>
								</div>
							</div>
						</div>

						<div className="report-subsection-element">
							<h5>b. Zones</h5>
							<div className="button-grid grid-col-3">
								<div className="small-opacities-zones-label" />
								<span className="top-label bold">R</span>
								<span className="top-label bold">L</span>

								{['Upper', 'Middle', 'Lower'].map((height) => (
									<React.Fragment key={height}>
										<span className="bold align-right">{height}</span>

										{['R', 'L'].map((side) => {
											const zone = height[0] + side;

											return (
												<button
													type="button"
													onClick={() => this.addOpacityZone(zone)}
													className={`btn ${
														this.reportState.smallOpacities.zones.includes(
															zone,
														) && 'active'
													}`}
													key={zone}
												>
													X
												</button>
											);
										})}
									</React.Fragment>
								))}
							</div>
						</div>

						<div className="report-subsection-element">
							<h5>c. Profusions</h5>
							<div className="button-grid grid-col-3">
								{[
									'0-',
									'00',
									'01',
									'10',
									'11',
									'12',
									'21',
									'22',
									'23',
									'32',
									'33',
									'3+',
								].map((profusion) => (
									<button
										type="button"
										onClick={() => this.addOpacityProfusion(profusion)}
										className={`btn ${
											this.reportState.smallOpacities.profusion.includes(
												profusion,
											) && 'active'
										}`}
										key={profusion}
									>
										{`${profusion[0]}/${profusion[1]}`}
									</button>
								))}
							</div>
						</div>
					</div>
					{this.renderLargeOpacities()}
				</div>
			)}
		</div>
	);

	renderLargeOpacities = () : React.ReactNode => {
		const tooltips = {
			'A':'1-5cm total',
			'B':'>5cm total but less than the area of the right upper zone',
			'C':'>= the area of the right upper zone',
		};
		return (
			<div className="report-section space">
						<h4>2C. Large opacities</h4>
						<h5>Size</h5>
						<div className="button-group">
							{['O', 'A', 'B', 'C'].map((size) => (
								<button
									type="button"
									className={`btn large-opactiy-button ${
										this.reportState.largeOpacities === size && 'active'
									}`}
									onClick={() => this.addLargeOpacities(size)}
									key={size}
									data-text={tooltips[size]}
								>
									{size}
								</button>
							))}
						</div>
					</div>
		);
	}

	renderPleuralAbnormalities = (): React.ReactNode => (
		<div className="report-tile">
			<div className="report-section">
				<h4>3A. Any classifiable pleural abnormalities?</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setPleuralAbnormalities(true)}
						className={`btn ${
							this.reportState.pleuralAbnormalities && 'active'
						}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setPleuralAbnormalities(false)}
						className={`btn ${
							this.reportState.pleuralAbnormalities === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.pleuralAbnormalities && (
					<>
						<div className="report-section space">
							<h4>3B. Pleural Plaques</h4>

							<div className="report-subsection flex">
								<div className="report-subsection-element">
									<span className="bold medium">Site</span>
									<div className="button-grid grid-col-4">
										{[
											'In profile',
											'Face on',
											'Diaphragm',
											'Other site(s)',
										].map((site) => (
											<React.Fragment key={site}>
												<span className="bold align-right no-gap" key={site}>
													{site}
												</span>

												{['O', 'R', 'L'].map((grade) => (
													<button
														type="button"
														onClick={() => this.addPleuralPlaquesSite(`${site}${grade}`)}
														className={`btn ${
															this.reportState.pleuralPlaques.site.includes(
																`${site}${grade}`,
															) && 'active'
														}`}
														key={`${site}.${grade}`}
													>
														{grade}
													</button>
												))}
											</React.Fragment>
										))}
									</div>
								</div>

								<div className="report-subsection-element">
									<span className="bold medium">Calcification</span>
									<div className="button-grid grid-col-4">
										{[
											'In profile',
											'Face on',
											'Diaphragm',
											'Other site(s)',
										].map((site) => (
											<React.Fragment key={site}>
												<span className="bold align-right no-gap" key={site}>
													{site}
												</span>

												{['O', 'R', 'L'].map((grade) => (
													<button
														type="button"
														onClick={() => this.addPleuralPlaquesCalcification(
															`${site}${grade}`,
														)}
														className={`btn ${
															this.reportState.pleuralPlaques.calcification.includes(
																`${site}${grade}`,
															) && 'active'
														}`}
														key={`${site}.${grade}`}
													>
														{grade}
													</button>
												))}
											</React.Fragment>
										))}
									</div>
								</div>

								<div className="report-subsection-element">
									<span className="bold medium">
										Extent (chest wall: combined for in profile and face on)
									</span>

									<div className="flex">
										{this.renderExtentMatrix(
											'R',
											this.setExtentNumber,
											this.reportState.pleuralPlaques.extent.right.letter,
											this.reportState.pleuralPlaques.extent.right.number,
										)}

										{this.renderExtentMatrix(
											'L',
											this.setExtentNumber,
											this.reportState.pleuralPlaques.extent.left.letter,
											this.reportState.pleuralPlaques.extent.left.number,
										)}
									</div>
								</div>

								<div className="report-subsection-element">
									<span className="bold medium">Width (In profile only)</span>

									<div className="flex">
										{this.renderWidthMatrix(
											'R',
											this.setWidthNumber,
											this.reportState.pleuralPlaques.width.right.letter,
											this.reportState.pleuralPlaques.width.right.number,
										)}

										{this.renderWidthMatrix(
											'L',
											this.setWidthNumber,
											this.reportState.pleuralPlaques.width.left.letter,
											this.reportState.pleuralPlaques.width.left.number,
										)}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
				<div className="report-section space">
					<h4>3C. Costophrenic angle obliteration</h4>
					<div className="button-group">
						<button
							type="button"
							onClick={(): void => this.setCostophrenicAngleObliteration('R')}
							className={`btn ${
								this.reportState.costophrenicAngleObliteration.includes('R')
								&& 'active'
							}`}
						>
							R
						</button>

						<button
							type="button"
							onClick={(): void => this.setCostophrenicAngleObliteration('L')}
							className={`btn ${
								this.reportState.costophrenicAngleObliteration.includes('L')
								&& 'active'
							}`}
						>
							L
						</button>

						<button
							type="button"
							onClick={(): void => this.setCostophrenicAngleObliteration('No')}
							className={`btn ${
								this.reportState.costophrenicAngleObliteration.includes('No')
								&& 'active'
							}`}
						>
							No
						</button>
					</div>

					{(this.reportState.costophrenicAngleObliteration.includes('L')
						|| this.reportState.costophrenicAngleObliteration.includes('R')) && (
						<>
							<div className="report-section space">
								<h4>3D. Diffuse Pleural Thickening</h4>

								<div className="report-subsection flex">
									<div className="report-subsection-element">
										<span className="bold medium">Site</span>
										<div className="button-grid grid-col-4">
											{['In profile', 'Face on'].map((site) => (
												<React.Fragment key={site}>
													<span className="bold no-gap align-right">
														{site}
													</span>

													{['O', 'R', 'L'].map((grade) => (
														<button
															type="button"
															onClick={() => this.addCostophrenicAngleObliterationSite(
																`${site}${grade}`,
															)}
															className={`btn ${
																this.reportState.costophrenicAngleObliterations.site.includes(
																	`${site}${grade}`,
																) && 'active'
															}`}
															key={`${site}.${grade}`}
														>
															{grade}
														</button>
													))}
												</React.Fragment>
											))}
										</div>
									</div>

									<div className="report-subsection-element">
										<span className="bold medium">Calcification</span>
										<div className="button-grid grid-col-4">
											{['In profile', 'Face on'].map((site) => (
												<React.Fragment key={site}>
													<span className="bold no-gap align-right">
														{site}
													</span>

													{['O', 'R', 'L'].map((grade) => (
														<button
															type="button"
															onClick={() => this.addCostophrenicAngleObliterationsCalcification(
																`${site}${grade}`,
															)}
															className={`btn ${
																this.reportState.costophrenicAngleObliterations.calcification.includes(
																	`${site}${grade}`,
																) && 'active'
															}`}
															key={`${site}.${grade}`}
														>
															{grade}
														</button>
													))}
												</React.Fragment>
											))}
										</div>
									</div>

									<div className="report-subsection-element">
										<span className="bold medium">
											Extent (chest wall: combined for in profile and face on)
										</span>

										<div className="flex">
											{this.renderExtentMatrix(
												'R',
												this.setCostophrenicExtentNumber,
												this.reportState.costophrenicAngleObliterations.extent
													.right.letter,
												this.reportState.costophrenicAngleObliterations.extent
													.right.number,
											)}

											{this.renderExtentMatrix(
												'L',
												this.setCostophrenicExtentNumber,
												this.reportState.costophrenicAngleObliterations.extent
													.left.letter,
												this.reportState.costophrenicAngleObliterations.extent
													.left.number,
											)}
										</div>
									</div>

									<div className="report-subsection-element">
										<span className="bold medium">Width (In profile only)</span>

										<div className="flex">
											{this.renderWidthMatrix(
												'R',
												this.setCostophrenicWidthNumber,
												this.reportState.costophrenicAngleObliterations.width
													.right.letter,
												this.reportState.costophrenicAngleObliterations.width
													.right.number,
											)}
											{this.renderWidthMatrix(
												'L',
												this.setCostophrenicWidthNumber,
												this.reportState.costophrenicAngleObliterations.width
													.left.letter,
												this.reportState.costophrenicAngleObliterations.width
													.left.number,
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);

	renderExtentMatrix = (
		side: 'R' | 'L',
		numberFunction: (x: number, y: boolean) => void,
		letterProperty: string,
		numberProperty: number,
	): React.ReactNode => {
		const tooltips = {
			1:'Up  to 1/4 of lateral chest wall',
			2:'1/4 to 1/2 of lateral chest wall',
			3:'Face on O R L O R L > 1/2 of lateral chest wall',
		};
		return (
			<div className="button-grid grid-col-3">
				<button
					type="button"
					className={`btn ${
						letterProperty.includes('O') ? 'active' : 'inactive'
					}`}
				>
					O
				</button>

				<button
					type="button"
					className={`btn ${
						letterProperty.includes(side) ? 'active' : 'inactive'
					}`}
				>
					{side}
				</button>

				<div className="empty-cell" />

				{[1, 2, 3].map((number) => (
					<button
						type="button"
						onClick={() => {
							if (letterProperty.includes(side)) numberFunction(number, side === 'L');
						}}
						className={`btn ${numberProperty === number && 'active'} ${
							!letterProperty.includes(side) && 'inactive'
						}`}
						key={number}
						data-text={tooltips[number]}
					>
						{number}
					</button>
				))}
			</div>
		)};

	renderWidthMatrix = (
		side: 'R' | 'L',
		numberFunction: (x: number, y: boolean) => void,
		letterProperty: string,
		numberProperty: number,
	): React.ReactNode => {
		const tooltips = {
			1:'3 to 5 mm',
			2:'5 to 10 mm',
			3:'> 10 mm',
		};
		return (
			<div className="button-grid grid-col-3">
				<button
					type="button"
					className={`btn ${
						letterProperty.includes('O') ? 'active' : 'inactive'
					}`}
				>
					O
				</button>

				<button
					type="button"
					className={`btn ${
						letterProperty.includes(side) ? 'active' : 'inactive'
					}`}
				>
					{side}
				</button>

				<div className="empty-cell" />

				{[1, 2, 3].map((number) => (
					<button
						type="button"
						onClick={() => {
							if (letterProperty.includes(side)) numberFunction(number, side === 'L');
						}}
						className={`btn ${numberProperty === number && 'active'} ${
							!letterProperty.includes(side) && 'inactive'
						}`}
						key={number}
						data-text={tooltips[number]}
					>
						{String.fromCharCode(number + 96)}	
					</button>
				))}
			</div>
		)};

	renderOtherAbnormalities = (): React.ReactNode => (
		<div className="other-abnormalities-tile report-tile">
			<div className="report-section">
				<h4>4A. Any other abnormalities?</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setOtherAbnormalities(true)}
						className={`btn ${
							this.reportState.otherAbnormalities && 'active'
						}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setOtherAbnormalities(false)}
						className={`btn ${
							this.reportState.otherAbnormalities === false && 'active'
						}`}
					>
						No
					</button>
				</div>
			</div>

			{this.reportState.otherAbnormalities && this.renderOtherSymbols()}

			{this.reportState.otherAbnormalities && this.renderOtherConditions()}

			<div className="report-section space">
				<h4>4E. Should worker see personal physician because of findings?</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={() => this.setSeePhysician('Yes')}
						className={`btn ${
							this.reportState.seePhysician === 'Yes' && 'active'
						}`}
					>
						Yes
					</button>
					<button
						type="button"
						onClick={() => this.setSeePhysician('No')}
						className={`btn ${
							this.reportState.seePhysician === 'No' && 'active'
						}`}
					>
						No
					</button>
				</div>
			</div>
		</div>
	);

	renderOtherSymbols = (): React.ReactNode =>  {
		const tooltips = {
			'aa': 'atherosclerotic aorta',
		 	'at': 'significant apical pleural thickening',
		  	'ax': 'coalescence of small opacities - with margins of the small opacities remaining visible, whereas a large opacity demonstrates a homogeneous opaque appearance - may be recorded either in the presence or in the absence of large opacities', 
			'bu': 'bulla(e)', 
			'ca': 'cancer, thoracic malignancies excluding mesothelioma', 
			'cg': 'calcified non-pneumoconiotic nodules (e.g. granuloma) or nodes ', 
			'cn': 'calcification in small pneumoconiotic opacities', 
			'co': 'abnormality of cardiac size or shape', 
			'cp': 'cor pulmonale', 
			'cv': 'cavity',
			'di': 'marked distortion of an intrathoracic structure',
			'ef': 'pleural effusion',
			'em': 'emphysema',
			'es': 'eggshell calcification of hilar or mediastinal lymph nodes',
			'fr': 'fractured rib(s) (acute or healed)',
			'hi': 'enlargement of  non-calcified hilar or  mediastinal  lymph nodes',
			'ho': 'honeycomb lung',
			'id': 'ill-defined diaphragm border - should be recorded only if more than one-third of one hemidiaphragm is affected ',
			'ih': 'ill-defined heart border - should be recorded only if the length of theheart border affected, whether on the right or on the left side, is morethan one-third of the length of the left heart border',
			'kl': 'septal (Kerley) lines',
			'me': 'mesothelioma',
			'pa': 'plate atelectasis',
			'pb': 'parenchymal bands - significant parenchymal fibrotic stands incontinuity with the pleura',
			'pi': 'pleural thickening of an  interlobar fissure',
			'px': 'pneumothorax',
			'ra': 'rounded atelectasis',
			'rp': 'rheumatoid pneumoconiosis',
			'tb': 'tuberculosis'
		};
		
		return (
			<div className="report-section space">
				<h4>4B. Other Symbols (Obligatory)</h4>
				<div className="button-group">
					{['aa', 'at', 'ax', 'bu', 'ca', 'cg', 'cn', 'co', 'cp', 'cv',
						'di', 'ef', 'em', 'es', 'fr', 'hi', 'ho', 'id', 'ih', 'kl',
						'me', 'pa', 'pb', 'pi', 'px', 'ra', 'rp', 'tb'].map((symbol) => (
						<button
							type="button"
							onClick={() => this.addOtherSymbols(symbol)}
							className={`btn ${this.reportState.otherSymbols.includes(symbol) && 'active'}`}
							key={symbol}
							data-text={tooltips[symbol]}
						>
							{symbol}
						</button>
					))}
				</div>
			</div>
		)};

	renderOtherConditions = (): React.ReactNode => {
		const otherConditions = {
			'Abnormalities of the Diaphragm': ['Eventration', 'Hiatal hernia'],
			'Airway Disorders': [
				'Bronchovascular markings, heavy or increased',
				'Hyperinflation',
			],
			'Bony Abnormalities': [
				'Bony chest cage abnormality',
				'Fracture, healed (non-rib)',
				'Fracture, not healed (non-rib)',
				'Scoliosis',
				'Vertebral column abnormality',
			],
			'Vascular Disorders': ['Aorta, anomaly of', 'Vascular abnormality'],
			'Lung Parenchymal Abnormalities': [
				'Azygos lobe',
				'Density, lung',
				'Infiltrate',
				'Nodule, nodular lesion',
			],
			'Miscellaneous Abnormalities': [
				'Foreign body',
				'Post-surgical changes / sternal wire',
				'Cyst',
			],
		};

		return (
			<>
				<div className="report-section space">
					<h4>4C. Mark all boxes that apply</h4>
					<div className="report-subsection cols">
						{Object.keys(otherConditions).map((category) => (
							<div className="report-subsection-element" key={category}>
								<span className="bold medium" key={category}>
									{category}
								</span>

								{otherConditions[category].map((condition: string) => (
									<div
										className="other-abnormalities-col-inner"
										key={`${category}.${condition}`}
									>
										<button
											type="button"
											onClick={() => this.setOtherAbnormalitiesList(condition)}
											className={`btn ${
												this.reportState.otherAbnormalitiesList.includes(
													condition,
												) && 'active'
											}`}
										>
											x
										</button>
										<p>{condition}</p>
									</div>
								))}
							</div>
						))}
					</div>
				</div>

				<div className="report-section space">
					<h4>4D. Other Comments</h4>
					<div className="comment-box">
						<textarea
							value={this.reportState.otherComments}
							onChange={this.handleOtherCommentsChange}
							rows={5}
						/>
					</div>
				</div>
			</>
		);
	};

	@action
	setImageQuality = (value: number): void => {
		this.reportState.imageQuality = value;
		this.reportState.imageDefects = [];
		this.reportState.imageDefectsOther = '';

		// Unreadable nothing can be filled in
		if (value === 4) {
			const existingImageQuality = this.reportState.imageQuality;
			this.clearReportState();
			this.reportState.imageQuality = existingImageQuality;
			this.reportState.parenchymalAbnormalities = false;
			this.reportState.pleuralAbnormalities = false;
			this.reportState.costophrenicAngleObliteration = ['No'];
			this.reportState.otherAbnormalities = false;
		}
	};

	@action
	addImageDefect = (value: string): void => {
		if (this.reportState.imageDefects.includes(value)) {
			this.reportState.imageDefects = this.reportState.imageDefects.filter(
				(e) => e !== value,
			);

			if (value === 'Other') {
				this.reportState.imageDefectsOther = '';
			}
		} else {
			this.reportState.imageDefects.push(value);
		}
	};

	@action
	setParenchymalAbnormalities = (value: boolean): void => {
		this.reportState.parenchymalAbnormalities = value;

		if (value === false) {
			this.reportState.smallOpacities = {
				shapePrimary: '',
				shapeSecondary: '',
				zones: [],
				profusion: '',
			};
			this.reportState.largeOpacities = '';
		}

		if (this.reportState.approveStudy && value === true) this.reportState.approveStudy = false;
	};

	@action
	addSmallOpacitiesShapePrimary = (value: string): void => {
		if (this.reportState.smallOpacities.shapePrimary === value) {
			this.reportState.smallOpacities.shapePrimary = '';
		} else {
			this.reportState.smallOpacities.shapePrimary = value;
		}
	};

	@action
	addSmallOpacitiesShapeSecondary = (value: string): void => {
		if (this.reportState.smallOpacities.shapeSecondary === value) {
			this.reportState.smallOpacities.shapeSecondary = '';
		} else {
			this.reportState.smallOpacities.shapeSecondary = value;
		}
	};

	@action
	addOpacityZone = (value: string): void => {
		if (this.reportState.smallOpacities.zones.includes(value)) {
			this.reportState.smallOpacities.zones = this.reportState.smallOpacities.zones.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.smallOpacities.zones.push(value);
		}
	};

	@action
	addOpacityProfusion = (value: string): void => {
		if (this.reportState.smallOpacities.profusion === value) {
			this.reportState.smallOpacities.profusion = '';
		} else {
			this.reportState.smallOpacities.profusion = value;
		}
	};

	@action
	addLargeOpacities = (value: string): void => {
		this.reportState.largeOpacities = value;
	};

	@action
	setPleuralAbnormalities = (value: boolean): void => {
		this.reportState.pleuralAbnormalities = value;

		if (value === false) {
			this.reportState.pleuralPlaques = {
				site: [],
				calcification: [],
				extent: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
				width: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
			};
		}

		if (this.reportState.approveStudy && value === true) this.reportState.approveStudy = false;
	};

	@action
	addPleuralPlaquesSite = (value: string): void => {
		const type = value.slice(0, -1);
		const area = value.slice(-1);

		if (this.reportState.pleuralPlaques.site.includes(value)) {
			this.reportState.pleuralPlaques.site = this.reportState.pleuralPlaques.site.filter(
				(e) => e !== value,
			);
		} else {
			if (area === 'O') {
				this.reportState.pleuralPlaques.site = this.reportState.pleuralPlaques.site.filter(
					(e) => e !== `${type}R` && e !== `${type}L`,
				);
			} else if (['R', 'L'].includes(area)) {
				this.reportState.pleuralPlaques.site = this.reportState.pleuralPlaques.site.filter(
					(e) => e !== `${type}O`,
				);
			}

			this.reportState.pleuralPlaques.site.push(value);
		}

		this.checkPleuralPlaques();
	};

	@action
	addPleuralPlaquesCalcification = (value: string): void => {
		const type = value.slice(0, -1);
		const area = value.slice(-1);

		if (this.reportState.pleuralPlaques.calcification.includes(value)) {
			this.reportState.pleuralPlaques.calcification = this.reportState.pleuralPlaques.calcification.filter(
				(e) => e !== value,
			);
		} else {
			// Can only specify "O" or "R &| L"
			if (area === 'O') {
				this.reportState.pleuralPlaques.calcification = this.reportState.pleuralPlaques.calcification.filter(
					(e) => e !== `${type}R` && e !== `${type}L`,
				);
			} else if (['R', 'L'].includes(area)) {
				this.reportState.pleuralPlaques.calcification = this.reportState.pleuralPlaques.calcification.filter(
					(e) => e !== `${type}O`,
				);
			}

			this.reportState.pleuralPlaques.calcification.push(value);
		}

		this.checkPleuralPlaques();
	};

	@action
	checkPleuralPlaques = () => {
		let pleuralPlaquesCombined = this.reportState.pleuralPlaques.site.concat(
			this.reportState.pleuralPlaques.calcification,
		);
		pleuralPlaquesCombined = pleuralPlaquesCombined.map((item) => item.slice(-1));

		const setAll = (value: string, left: boolean) => {
			this.setExtentLetter(value, left);
			this.setWidthLetter(value, left);

			if (value === 'O') {
				this.setExtentNumber(0, left);
				this.setWidthNumber(0, left);
			}
		};

		if (pleuralPlaquesCombined.includes('R')) {
			setAll('R', false);
		} else {
			setAll('O', false);
		}

		if (pleuralPlaquesCombined.includes('L')) {
			setAll('L', true);
		} else {
			setAll('O', true);
		}
	};

	@action
	setExtentLetter = (value: string, left: boolean): void => {
		if (left) {
			this.reportState.pleuralPlaques.extent.left.letter = value;
		} else {
			this.reportState.pleuralPlaques.extent.right.letter = value;
		}
	};

	@action
	setExtentNumber = (value: number, left: boolean): void => {
		if (left) {
			if (this.reportState.pleuralPlaques.extent.left.number === value) {
				this.reportState.pleuralPlaques.extent.left.number = 0;
			} else {
				this.reportState.pleuralPlaques.extent.left.number = value;
			}
		} else if (this.reportState.pleuralPlaques.extent.right.number === value) {
			this.reportState.pleuralPlaques.extent.right.number = 0;
		} else {
			this.reportState.pleuralPlaques.extent.right.number = value;
		}
	};

	@action
	setWidthLetter = (value: string, left: boolean): void => {
		if (left) {
			this.reportState.pleuralPlaques.width.left.letter = value;
		} else {
			this.reportState.pleuralPlaques.width.right.letter = value;
		}
	};

	@action
	setWidthNumber = (value: number, left: boolean): void => {
		if (left) {
			if (this.reportState.pleuralPlaques.width.left.number === value) {
				this.reportState.pleuralPlaques.width.left.number = 0;
			} else {
				this.reportState.pleuralPlaques.width.left.number = value;
			}
		} else if (this.reportState.pleuralPlaques.width.right.number === value) {
			this.reportState.pleuralPlaques.width.right.number = 0;
		} else {
			this.reportState.pleuralPlaques.width.right.number = value;
		}
	};

	@action
	setCostophrenicAngleObliteration = (value: string): void => {
		if (value === 'No') {
			this.reportState.costophrenicAngleObliteration = [value];
			this.reportState.costophrenicAngleObliterations = {
				site: [],
				calcification: [],
				extent: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
				width: {
					right: {
						letter: '',
						number: 0,
					},
					left: {
						letter: '',
						number: 0,
					},
				},
			};
		} else if (
			this.reportState.costophrenicAngleObliteration.indexOf(value) > -1
		) {
			this.reportState.costophrenicAngleObliteration.splice(
				this.reportState.costophrenicAngleObliteration.indexOf(value),
				1,
			);
		} else {
			if (this.reportState.costophrenicAngleObliteration.indexOf('No') > -1) {
				this.reportState.costophrenicAngleObliteration.splice(
					this.reportState.costophrenicAngleObliteration.indexOf('No'),
					1,
				);
			}
			this.reportState.costophrenicAngleObliteration.push(value);
		}

		if (value !== 'No' && this.reportState.approveStudy) {
			this.reportState.approveStudy = false;
		}
	};

	@action
	addCostophrenicAngleObliterationSite = (value: string): void => {
		const type = value.slice(0, -1);
		const area = value.slice(-1);

		if (this.reportState.costophrenicAngleObliterations.site.includes(value)) {
			this.reportState.costophrenicAngleObliterations.site = this.reportState.costophrenicAngleObliterations.site.filter(
				(e) => e !== value,
			);
		} else {
			// Can only specify "O" or "R &| L"
			if (area === 'O') {
				this.reportState.costophrenicAngleObliterations.site = this.reportState.costophrenicAngleObliterations.site.filter(
					(e) => e !== `${type}R` && e !== `${type}L`,
				);
			} else if (['R', 'L'].includes(area)) {
				this.reportState.costophrenicAngleObliterations.site = this.reportState.costophrenicAngleObliterations.site.filter(
					(e) => e !== `${type}O`,
				);
			}

			this.reportState.costophrenicAngleObliterations.site.push(value);
		}

		this.checkCostophrenicAngleObliterations();
	};

	@action
	addCostophrenicAngleObliterationsCalcification = (value: string): void => {
		const type = value.slice(0, -1);
		const area = value.slice(-1);

		if (
			this.reportState.costophrenicAngleObliterations.calcification.includes(
				value,
			)
		) {
			this.reportState.costophrenicAngleObliterations.calcification = this.reportState.costophrenicAngleObliterations.calcification.filter(
				(e) => e !== value,
			);
		} else {
			// Can only specify "O" or "R &| L"
			if (area === 'O') {
				this.reportState.costophrenicAngleObliterations.calcification = this.reportState.costophrenicAngleObliterations.calcification.filter(
					(e) => e !== `${type}R` && e !== `${type}L`,
				);
			} else if (['R', 'L'].includes(area)) {
				this.reportState.costophrenicAngleObliterations.calcification = this.reportState.costophrenicAngleObliterations.calcification.filter(
					(e) => e !== `${type}O`,
				);
			}

			this.reportState.costophrenicAngleObliterations.calcification.push(value);
		}

		this.checkCostophrenicAngleObliterations();
	};

	@action
	checkCostophrenicAngleObliterations = () => {
		let costophrenicAngleObliterationsCombined = this.reportState.costophrenicAngleObliterations.site.concat(
			this.reportState.costophrenicAngleObliterations.calcification,
		);
		costophrenicAngleObliterationsCombined = costophrenicAngleObliterationsCombined.map(
			(item) => item.slice(-1),
		);

		const setAll = (value: string, left: boolean) => {
			this.setCostophrenicExtentLetter(value, left);
			this.setCostophrenicWidthLetter(value, left);

			if (value === 'O') {
				this.setCostophrenicExtentNumber(0, left);
				this.setCostophrenicWidthNumber(0, left);
			}
		};

		if (costophrenicAngleObliterationsCombined.includes('R')) {
			setAll('R', false);
		} else {
			setAll('O', false);
		}

		if (costophrenicAngleObliterationsCombined.includes('L')) {
			setAll('L', true);
		} else {
			setAll('O', true);
		}
	};

	@action
	setCostophrenicExtentLetter = (value: string, left: boolean): void => {
		if (left) {
			this.reportState.costophrenicAngleObliterations.extent.left.letter = value;
		} else {
			this.reportState.costophrenicAngleObliterations.extent.right.letter = value;
		}
	};

	@action
	setCostophrenicExtentNumber = (value: number, left: boolean): void => {
		if (left) {
			if (
				this.reportState.costophrenicAngleObliterations.extent.left.number
				=== value
			) {
				this.reportState.costophrenicAngleObliterations.extent.left.number = 0;
			} else {
				this.reportState.costophrenicAngleObliterations.extent.left.number = value;
			}
		} else if (
			this.reportState.costophrenicAngleObliterations.extent.right.number
				=== value
		) {
			this.reportState.costophrenicAngleObliterations.extent.right.number = 0;
		} else {
			this.reportState.costophrenicAngleObliterations.extent.right.number = value;
		}
	};

	@action
	setCostophrenicWidthLetter = (value: string, left: boolean): void => {
		if (left) {
			this.reportState.costophrenicAngleObliterations.width.left.letter = value;
		} else {
			this.reportState.costophrenicAngleObliterations.width.right.letter = value;
		}
	};

	@action
	setCostophrenicWidthNumber = (value: number, left: boolean): void => {
		if (left) {
			if (
				this.reportState.costophrenicAngleObliterations.width.left.number
				=== value
			) {
				this.reportState.costophrenicAngleObliterations.width.left.number = 0;
			} else {
				this.reportState.costophrenicAngleObliterations.width.left.number = value;
			}
		} else if (
			this.reportState.costophrenicAngleObliterations.width.right.number
				=== value
		) {
			this.reportState.costophrenicAngleObliterations.width.right.number = 0;
		} else {
			this.reportState.costophrenicAngleObliterations.width.right.number = value;
		}
	};

	@action
	setOtherAbnormalities = (value: boolean): void => {
		this.reportState.otherAbnormalities = value;

		this.reportState.otherSymbols = [];
		this.reportState.otherComments = '';

		if (this.reportState.approveStudy && value === true) this.reportState.approveStudy = false;
	};

	@action
	addOtherSymbols = (value: string): void => {
		if (this.reportState.otherSymbols.includes(value)) {
			this.reportState.otherSymbols = this.reportState.otherSymbols.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.otherSymbols.push(value);
		}
	};

	@action
	setSeePhysician = (value: string): void => {
		if (this.reportState.seePhysician === value) {
			this.reportState.seePhysician = '';
		} else {
			this.reportState.seePhysician = value;
		}

		if (this.reportState.seePhysician === 'Yes') {
			this.reportState.dateDoctorNotified = moment.utc().add(10, 'h').format('DD-MM-YYYY');
		} else {
			this.reportState.dateDoctorNotified = null;
		}
	};

	@action
	setOtherAbnormalitiesList = (value: string): void => {
		if (this.reportState.otherAbnormalitiesList.includes(value)) {
			this.reportState.otherAbnormalitiesList = this.reportState.otherAbnormalitiesList.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.otherAbnormalitiesList.push(value);
		}
	};

	@action
	handleOtherChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
		this.reportState.imageDefectsOther = e.target.value;
	};

	@action
	handleOtherCommentsChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
		this.reportState.otherComments = e.target.value;
	};

	createFormModel = async () => {
		const { report } = this.props;

		return axios
			.post(`${SERVER_URL}/generateFormModel`, {
				ReportState: JSON.stringify(this.reportState),
				ReportRequestId: report.id,
				RadiologistId: store.radiologist.id,
			})
			.then((res) => {
				return res.data;
			})
			.catch((err: AxiosError | Error) => {
				console.error(err.message);
				return null;
			});
	};

	setFormTile(tileType: FormTile) {
		if (
			this.reportState.imageQuality === 4
			&& tileType !== FormTile.PreviewReport
			&& tileType !== FormTile.IndicateImageQuality
		) {
			alert('Study has been marked un-readable', 'error');
		} else {
			const { setFormTile } = this.props;
			setFormTile(tileType);
		}
	}
}
