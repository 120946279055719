import axios, { AxiosError } from 'axios';
import { SERVER_URL } from 'Constants';
import { ReportRequestEntity, ReportSubmissionEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import alert from './ToastifyUtils';

/**
 * Clientside handler for sending status update of a report to Portal.
 * @param reportRequestId GUID of Report request to send status of.
 * @param moreReads If status is being sent because more reads are being added.
 */
export function sendStatusUpdate(reportRequestId: string, moreReads: boolean = false): void {
	axios
		.post(
			`${SERVER_URL}/api/sendStatus`,
			{
				reportRequestId,
				radiologistId: store.radiologist.id,
				moreReads,
			},
		)
		.catch((error: AxiosError) => {
			error.response && alert(error.response.data, 'error');
			console.error(error);
		});
}

/**
 * Sends the submission as an update to Portal
 * @param reportSubmissionId GUID of the ReportSubmission to send
 */
export function sendSubmissionUpdate(reportSubmissionId: string) {
	axios
		.post(
			`${SERVER_URL}/api/sendSubmission`,
			{
				ReportSubmissionId: reportSubmissionId,
			},
		)
		.catch((error: AxiosError) => {
			error.response && alert(error.response.data, 'error');
			console.error(error);
		});
}

/**
 * Fetches the latest data for the respective report
 * @param reportId The id of the report to get
 * @returns A ReportRequestEntity
 */
export async function fetchLatestReportData(reportId: string): Promise<ReportRequestEntity> {
	const response = await store.apolloClient.query({
		// using this method is important because it fetchs all of the attributes as well as the
		// primative types for the ReportRequestEntity, which is required for the CheckOuts list
		query: ReportRequestEntity.getFetchSingleQueryProfilePage(),
		fetchPolicy: 'network-only',
		variables: {
			args: [
				{
					path: 'id',
					comparison: 'equal',
					value: reportId,
				},
			],
		},
	});
	if (!response.data.reportRequestEntity) throw new Error('Axios request provided invalid return type for ReportRequestEntity');
	return response.data.reportRequestEntity;
}

/**
 * Returns the number of active checkouts for the respective report
 * @param reportId The id of hte report to query
 * @returns The number of active checkouts
 */
export async function fetchNumberOfCheckOutsForReport(reportId: string): Promise<number> {
	return fetchLatestReportData(reportId).then((value) => value?.checkOuts.length);
}

/**
 * Returns the updated report request entity
 * @param report The report request object to update
 * @returns The updated report request object
 */
export async function syncReportDataWithModel(report: ReportRequestEntity): Promise<ReportRequestEntity> {
	const response: ReportRequestEntity = await fetchLatestReportData(report.id);
	report.readsRemaining = response.readsRemaining;
	report.minimumReads = response.minimumReads;
	report.requestStatus = response.requestStatus;
	return report;
}

/**
 * Returns if the report is completed or not
 * @param report The report request entity to query
 * @returns True if the report is complete
 */
export async function isComplete(report: ReportRequestEntity): Promise<boolean> {
	return report.readsRemaining === 0 || report.requestStatus === 'COMPLETED';
}

/**
 * Returns true if the respective report has 1 or more reads remaining
 * @param report The report to query
 * @returns True if the report has 1 or more reads remaining
 */
export async function hasReadsRemaining(report: ReportRequestEntity): Promise<boolean> {
	return report.readsRemaining > 0;
}

/**
 * Returns true if the report can be checked out
 * @param report The report to query
 * @returns True if the report can be checked out
 */
export async function canBeCheckedOut(report: ReportRequestEntity): Promise<boolean> {
	return await fetchNumberOfCheckOutsForReport(report.id) >= report.readsRemaining;
}

/**
 * Verifies that the respective report is able to be submitted
 * @param report The report to query
 * @returns True if the report can be submitted
 */
export async function verifyReportCanBeSubmitted(report: ReportRequestEntity): Promise<boolean> {
	if (await isComplete(report)) {
		alert('The final read has been submitted for this report since you opened it', 'error');
	} else if (store.radiologist && report !== null && hasReadsRemaining(report)) {
		return true;
	}

	return false;
}

/**
 * Verifies that the report is able to be opened by a radiologist
 * @param report The report to query
 * @returns True if the report can be opened
 */
export async function verifyReportCanBeOpened(report: ReportRequestEntity): Promise<boolean> {
	if (await isComplete(report)) {
		alert('This report has already been completed by another radiologist', 'error');
	} else if (await canBeCheckedOut(report)) {
		alert('This report has already been opened by another user', 'error');
	} else if (store.radiologist && report !== null && hasReadsRemaining(report)) {
		return true;
	}

	return false;
}
