/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import { observable, action, } from 'mobx';
import moment from 'moment';
import { store } from 'Models/Store';
import axios, { AxiosError, AxiosResponse } from 'axios';
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
import alert from '../../Util/ToastifyUtils';
import * as ReportUtils from '../../Util/ReportUtils';
import { ReportRequestEntity } from '../../Models/Entities';
import { SERVER_URL } from '../../Constants';
import { TextArea } from "../Components/TextArea/TextArea";
import { CARDIOLOGYReportState } from './CardiologyWrappingTileTile';
import { RadioButtonGroup } from "../Components/RadioButton/RadioButtonGroup";

// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] on begin
interface ProduceCardiologyReportProps extends RouteComponentProps {
	report: ReportRequestEntity;
	next?: (id: string) => Promise<ReportRequestEntity | null>;
	completedReports?: boolean;
	// Use this to save the report submission
	createReportSubmission?: (
		reportData: {
			timeStarted: Date,
			timeCompleted: Date,
			reportState: CARDIOLOGYReportState,
			requireAppointment: boolean,
			reportStateObject: string,
		},
		report: ReportRequestEntity,
		formModel: {}
	) => Promise<boolean>;
	unassignCurrentReport?: () => void;
	submittingReport?: boolean;
	setSubmittingReport?: (value: boolean) => void;
	backToRequestList: () => void;
}


// enum used for rendering different stages of the cardiology report process
export enum FormTile {
	ReportInProgress,
	ReviewReport,
	ReportSubmitted
}

// enum used for the cardiology report findings.
export enum ReportFindings {
	notCompleted = "The report has not been completed",
	noFindings = "No extra cardiac abnormal findings",
	findings = "Additional findings – not significant",
	significantFindings = "Additional findings – significant"
}

// enum used for the cardiology report followUp.
export enum ReportFollowUp {
	noFollowUp = "Diagnostic HR-CT",
	nonContrastCt = "Low-dose CT Chest",
	postContrastCt = "Diagnostic CT Chest with Contrast"
}


// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] on begin
export default class ProduceCardiologyReportTile extends React.Component<ProduceCardiologyReportProps> {
	// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin
	@observable
	private formState: FormTile = FormTile.ReportInProgress;

	@observable
	private reportComment: string;




	@observable
	private reportState = {} as CARDIOLOGYReportState;


	private formModel: {};


	private timeStarted: Date = new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss'));

	componentDidMount(): void {
		// Open Easyviz study
		this.openStudy();

		const { report } = this.props;

		this.goToStart();

		// Unassign report on page refresh
		window.onbeforeunload = () => {
			this.unassignReport();
		};
	}

	componentWillUnmount() {
		// Unassign report when leaving report builder (navigating away)
		this.unassignReport();
	}

	componentDidUpdate(prevProps: ProduceCardiologyReportProps): void {
		const { report } = this.props;

		// If it's a new report close the EasyViz study and open the new one
		if (prevProps.report.imageId !== report.imageId) {
			this.timeStarted = new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss'));

			this.openStudy();
		}
	}


	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const { report, submittingReport } = this.props;
		contents = (
			<>
				<div className="report-container">

					{this.formState === FormTile.ReportInProgress && (
						<div className="report-sidebar">{this.renderSideBar()}</div>
					)}

					{this.formState === FormTile.ReportInProgress && (
						<div className="cardiology-report-form ">{this.renderReportForm()}</div>
					)}

					{this.formState === FormTile.ReviewReport && (
						<div className="report-form">{this.renderReportPreview()}</div>
					)}

					{this.formState === FormTile.ReportSubmitted && (
						<div className="report-form">Report Submitted</div>
					)}
				</div>

				<div className="report-footer">
					<button
						type="button"
						className="btn btn--solid"
						onClick={() => this.backToRequest()}
					>
						Back to request list
					</button>

					<button type="button" className="btn btn--solid" onClick={() => this.nextReport()}>
						Skip request
					</button>

					<div className="primary-btns">
						{this.formState === FormTile.ReportInProgress && (
							<>
								<button
									type="button"
									className="btn btn--solid"
									onClick={() => this.nextPage()}
								>
									Preview Report
								</button>
							</>
						)}
						{this.formState === FormTile.ReviewReport && (
							<>
								<button
									type="button"
									className="btn btn--solid"
									onClick={() => this.setFormTile(FormTile.ReportInProgress)}
								>
									Back
								</button>

								{submittingReport ? (
									<CustomSpinner />
								) : (
									<button
										type="button"
										className="btn btn--solid"
										onClick={() => this.submitReport()}
									>
										Submit Report
									</button>
								)}
							</>
						)}

						{this.formState === FormTile.ReportSubmitted && (
							<>
								<button
									type="button"
									className="btn btn--solid"
									onClick={() => this.nextPage()}
								>
									Next Report
								</button>
							</>
						)}
					</div>

					{this.formState !== FormTile.ReportSubmitted
						&& this.formState !== FormTile.ReportInProgress
						&& this.formState !== FormTile.ReviewReport && (
							<div className="report-details-footer">
								<button
									type="button"
									className="btn btn--solid"
									onClick={() => this.backToRequest()}
								>
									Next
								</button>
							</div>
						)}
				</div>
			</>
		)

		return contents;
	}

	renderSideBar = (): React.ReactNode => {
		const { report } = this.props;

		return (

			<div className="report-sidebar">
				<div className="personal-identifier">
					<h4>
						{report.firstName}
						{' '}
						{report.lastName}
					</h4>
				</div>


				<br />
				<div className="sidebar-button-reverse standards">
					<div className="sidebar-button-standards-container">
					</div>
					<div className="sidebar-button-study-container cardiology-restudy">
						<button
							type="button"
							className="btn btn--solid"
							onClick={(): void => {
								this.openStudy();
							}}
						>
							Reopen Study
						</button>
					</div>
				</div>
			</div>
		)
	}

	renderReportForm = (): React.ReactNode => {

		return (
			<form className='report-form'>
				<h4>1. Finding</h4>
				<div className="finding-section">
					<RadioButtonGroup
						className={'radio-test'}
						model={this.reportState}
						modelProperty={'reportFindings'}
						options={[
							{
								value: ReportFindings.noFindings,
								display: 'No extra cardiac abnormal findings',
								after: <p className="text">
									<i>
										... Visualised lungs and soft tissues are within normal limits.
									</i>
								</p>
							},
							{
								value: ReportFindings.findings,
								display: 'Additional findings – of undetermined significance',
								after: <p className="text">
									<i>
										... Following incidental findings are noted however this is unlikely to be clinically significant.
									</i>
								</p>
							},
							{
								value: ReportFindings.significantFindings,
								display: 'Significant additional findings – further evaluation required',
								after: <p className="text">
									<i>
										... Dedicated diagnostic imaging of the chest and or abdomen is advised for further characterisation.
										<br />
										Follow up is recommended.
									</i>
								</p>
							},
						]} />
				</div>

				<div className="comment-section">
					<h4>2. Comments</h4>
					<TextArea model={this} modelProperty="reportComment" />
				</div>

				<h4>3. Dedicated CT recommendations</h4>
				<div className="finding-section">
					<RadioButtonGroup
						className={'radio-test'}
						model={this.reportState}
						modelProperty={'reportFollowUp'}
						name= "findings"
						options={[
							{
								value: ReportFollowUp.noFollowUp,
								display: 'Diagnostic HR-CT',
							},
							{
								value: ReportFollowUp.nonContrastCt,
								display: 'Low-dose CT Chest',
							},
							{
								value: ReportFollowUp.postContrastCt,
								display: 'Diagnostic CT Chest with Contrast',
							},
						]} />
				</div>
			</form>
		)
	}


	renderReportPreview = (): React.ReactNode => {
		const { report } = this.props;

		return (
			<div className="report-details-container">
				<div className="report-details-col">
					<div className="report-details-pair">
						<span className="detail-label">Patient name:</span>
						<span className="detail-content">
							{report.firstName}
							{' '}
							{report.lastName}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">D.O.B:</span>
						<span className="detail-content">
							{moment(report.dateOfBirth).format('DD-MM-YYYY')}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Patient ID:</span>
						<span className="detail-content">{report.patientId}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Date of Radiograph:</span>
						<span className="detail-content">
							{moment(report.imageDate).format('DD-MM-YYYY')}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Ordering Physician:</span>
						<span className="detail-content">{report.physicianName}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Ordering Facility:</span>
						<span className="detail-content">{report.orderingFacility}</span>
					</div>
				</div>
				<div className="report-details-col">
					<div className="report-details-pair">
						<span className="detail-label">Findings:</span>
						<span className="detail-content">{this.reportState.reportFindings}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Comments:</span>
						<span className="detail-content">{this.reportComment}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Follow-Up:</span>
						<span className="detail-content">{this.reportState.reportFollowUp}</span>
					</div>
					<div className="report-details-filler"> </div>
				</div>
			</div>
		);
	};


	unassignReport = (): void => {
		const { unassignCurrentReport } = this.props;

		if (unassignCurrentReport) {
			unassignCurrentReport();
		}
	};

	backToRequest = () => {
		const { backToRequestList } = this.props;

		backToRequestList();
	};

	backPage = (value?: number): void => {
		if (value) {
			this.setFormTile(value);
		}
	};

	@action
	nextPage = async () => {
		const { report } = this.props;
		if (true) {
			this.setFormTile(FormTile.ReviewReport);
		}
	};

	goToStart = (): void => {
		this.resetReport();
		const { setSubmittingReport } = this.props;
		setSubmittingReport && setSubmittingReport(false);
	};


	nextReport = async () => {
		const { next, report } = this.props;

		if (next) {
			const nextReport = await next(report.id);

			if (nextReport !== null) {
				this.goToStart();
				this.openStudy();
			} else {
				this.backToRequest();
			}
		}
	};

	//open the Easyviz portal
	@action
	private openStudy = (): void => {
		const { report } = this.props;

		axios.post(
			`${SERVER_URL}/getStudy`, { StudyUUID: report.imageId, UserId: store.userId, },
		).then((res) => {
			window.open(res.data, "_LungScreen_ImageViewer", "top=0,left=0,width=" + window.screen.availWidth + ",height=" + window.screen.availHeight);
		}).catch((err: AxiosError) => {
			if (err.response) {
				const errMessage = err.response?.data.toString().replace(/^Error: /g, 'Easyviz: ');
				console.error(errMessage);
				alert(errMessage, 'error');
			} else {
				console.error(err);
			}
		});
	};


	@action
	setFormTile(tileType: FormTile) {
		this.formState = tileType;
	}

	@action
	submitReport = async (shouldSendBackToRequestList: boolean = true) => {
		const { createReportSubmission, report, setSubmittingReport } = this.props;

		// check that the radio button in the report is selected
		if (this.reportState.reportFindings == ReportFindings.notCompleted) {
			const errMessage = "The radiologist has not selected a radio button";
			console.error(errMessage);
			alert(errMessage, 'error');
			return;
		}

		// Create a temp variable to check report validity
		const temp = await ReportUtils.syncReportDataWithModel(report);


		//set report state
		this.reportState.reportComments = this.reportComment

		// Check that the report has been assigned
		if (temp === null) {
			alert(
				'An unknown error occured while fetching current report data',
				'error',
			);
		}

		// Verify that the report can be submitted
		if (!await ReportUtils.verifyReportCanBeSubmitted(temp)) {
			this.backToRequest();
			return;
		}

		if (createReportSubmission && setSubmittingReport) {
			setSubmittingReport(true);

			createReportSubmission(
				{
					timeStarted: this.timeStarted,
					timeCompleted: new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss')),
					reportState: this.reportState,
					requireAppointment: false,
					reportStateObject: JSON.stringify(this.reportState),

				},
				report,
				this.formModel,
			).then((success) => {
				if (!shouldSendBackToRequestList && success) {
					this.nextReport();
					setSubmittingReport(false);
				} else if (success) {
					this.backToRequest();
				}
			});
		}
	};

	@action
	resetReport() {
		//reset the cardiology report data structure 
		this.reportState.reportFindings = ReportFindings.notCompleted;
		this.reportState.reportComments = '';
	}

	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
