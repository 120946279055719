/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { action, observable, runInAction } from 'mobx';
import { IAttributeGroup, Model, IModelAttributes, attribute, entity, jsonReplacerFn } from 'Models/Model';
import * as Validators from 'Validators';
import * as Models from '../Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import { makeFetchManyToManyFunc, makeFetchOneToManyFunc, makeJoinEqualsFunc, makeEnumFetchFunction } from 'Util/EntityUtils';
import { VisitorsReportRequestEntity } from 'Models/Security/Acl/VisitorsReportRequestEntity';
import { AdminReportRequestEntity } from 'Models/Security/Acl/AdminReportRequestEntity';
import { RadiologistReportRequestEntity } from 'Models/Security/Acl/RadiologistReportRequestEntity';
import * as Enums from '../Enums';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] on begin
import { gql } from 'apollo-boost';
import { getModelName, getAttributes } from 'Util/EntityUtils';
import { lowerCaseFirst } from '../../Util/StringUtils';
// % protected region % [Add any further imports here] end

export interface IReportRequestEntityAttributes extends IModelAttributes {
	requestStatus: Enums.requestStates;
	reportModality: Enums.reportModality;
	urgent: boolean;
	patientId: string;
	firstName: string;
	lastName: string;
	dateOfBirth: Date;
	physicianName: string;
	imageId: string;
	imageDate: Date;
	sharedFolderIdentifier: string;
	minimumReads: number;
	readsRemaining: number;
	timeCompleted: Date;
	invalidatePriorRequest: string;
	orderingFacility: string;
	classificationPurpose: string;
	twoReads: boolean;

	adjudicationAttempts: Array<Models.AdjudicationAttemptEntity | Models.IAdjudicationAttemptEntityAttributes>;
	checkOuts: Array<Models.CheckOutEntity | Models.ICheckOutEntityAttributes>;
	reportSubmissions: Array<Models.ReportSubmissionEntity | Models.IReportSubmissionEntityAttributes>;
	groups: Array<Models.ReportRequestGroup | Models.IReportRequestGroupAttributes>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ReportRequestEntity', 'Report Request')
// % protected region % [Customise your entity metadata here] end
export default class ReportRequestEntity extends Model implements IReportRequestEntityAttributes {
	public static acls: IAcl[] = [
		new VisitorsReportRequestEntity(),
		new AdminReportRequestEntity(),
		new RadiologistReportRequestEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Request Status'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Request Status',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: false,
		searchable: false,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.requestStatesOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.requestStatesOptions),
		displayFunction: (attribute: Enums.requestStates) =>
			Enums.requestStatesOptions[attribute],
	})
	public requestStatus: Enums.requestStates;
	// % protected region % [Modify props to the crud options here for attribute 'Request Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Report Modality'] on begin
	/**
	 * Report Modality
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Report Modality',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.reportModalityOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.reportModalityOptions),
		displayFunction: (attr: any, that: Model): any => {
			const reportModality =
			//@ts-ignore
				that.reportModality === 'ILO' ? 'ILO' : that.reportModality;
			return (
				<span className={`report-type ${reportModality.toLowerCase()}`}>
					{reportModality}
				</span>
			);
		},
	})
	public reportModality: Enums.reportModality;
	// % protected region % [Modify props to the crud options here for attribute 'Report Modality'] end

	// % protected region % [Modify props to the crud options here for attribute 'Urgent'] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Urgent',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: false,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: (attr) => {
			if (attr) {
				return <span className="report-type urgent">Urgent</span>;
			}
			return ' ';
		},
	})
	public urgent: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Urgent'] end

	// % protected region % [Modify props to the crud options here for attribute 'Patient Id'] off begin
	/**
	 * Patient Id
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Patient Id',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public patientId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Patient Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	/**
	 * First Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] on begin
	/**
	 * Last Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Date Of Birth'] on begin
	/**
	 * Date Of Birth
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Date Of Birth',
		displayType: 'datepicker',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public dateOfBirth: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Date Of Birth'] end

	// % protected region % [Modify props to the crud options here for attribute 'Physician Name'] off begin
	/**
	 * Physician Name
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Physician Name',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public physicianName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Physician Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Image Id'] off begin
	/**
	 * Image Id
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Image Id',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public imageId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Image Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Image Date'] on begin
	/**
	 * Image Date
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Image Date',
		displayType: 'datepicker',
		order: 100,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public imageDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Image Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Shared Folder Identifier'] off begin
	/**
	 * Shared Folder Identifier
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Shared Folder Identifier',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public sharedFolderIdentifier: string;
	// % protected region % [Modify props to the crud options here for attribute 'Shared Folder Identifier'] end

	// % protected region % [Modify props to the crud options here for attribute 'Minimum Reads'] off begin
	/**
	 * Minimum Reads
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Minimum Reads',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public minimumReads: number;
	// % protected region % [Modify props to the crud options here for attribute 'Minimum Reads'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reads Remaining'] off begin
	/**
	 * Reads Remaining
	 */
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Reads Remaining',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public readsRemaining: number;
	// % protected region % [Modify props to the crud options here for attribute 'Reads Remaining'] end

	// % protected region % [Modify props to the crud options here for attribute 'Time Completed'] off begin
	/**
	 * Time Completed
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Time Completed',
		displayType: 'datepicker',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public timeCompleted: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Time Completed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Invalidate Prior Request'] off begin
	/**
	 * This will be the image id of the prior report to invalidate
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Invalidate Prior Request',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public invalidatePriorRequest: string;
	// % protected region % [Modify props to the crud options here for attribute 'Invalidate Prior Request'] end

	// % protected region % [Modify props to the crud options here for attribute 'Ordering Facility'] off begin
	/**
	 * Ordering Facility
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Ordering Facility',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public orderingFacility: string;
	// % protected region % [Modify props to the crud options here for attribute 'Ordering Facility'] end

	// % protected region % [Modify props to the crud options here for attribute 'Classification Purpose'] off begin
	/**
	 * Classification Purpose
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Classification Purpose',
		displayType: 'textfield',
		order: 170,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public classificationPurpose: string;
	// % protected region % [Modify props to the crud options here for attribute 'Classification Purpose'] end

	// % protected region % [Modify props to the crud options here for attribute 'Two Reads'] on begin
	/**
	 * If a report was created with two reads
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Two Reads',
		displayType: 'checkbox',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoReads: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Two Reads'] end

	/**
	 * Adjudication Attempt
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Adjudication Attempt'] off begin
		name: "Adjudication Attempts",
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.AdjudicationAttemptEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'adjudicationAttempts',
			oppositeEntity: () => Models.AdjudicationAttemptEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Adjudication Attempt'] end
	})
	public adjudicationAttempts: Models.AdjudicationAttemptEntity[] = [];

	/**
	 * Check Outs
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Check Out'] off begin
		name: "Check Outs",
		displayType: 'reference-multicombobox',
		order: 200,
		referenceTypeFunc: () => Models.CheckOutEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'checkOuts',
			oppositeEntity: () => Models.CheckOutEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Check Out'] end
	})
	public checkOuts: Models.CheckOutEntity[] = [];

	/**
	 * Report Submission
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Submission'] off begin
		name: "Report Submissions",
		displayType: 'reference-multicombobox',
		order: 210,
		referenceTypeFunc: () => Models.ReportSubmissionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportSubmissions',
			oppositeEntity: () => Models.ReportSubmissionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Submission'] end
	})
	public reportSubmissions: Models.ReportSubmissionEntity[] = [];

	/**
	 * Restrict to Groups
	 */
	@observable
	@attribute({isReference: true})
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Group'] on begin
		name: 'Groups',
		displayType: 'reference-multicombobox',
		order: 220,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ReportRequestGroup,
		optionEqualFunc: makeJoinEqualsFunc('groupId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'reportRequestEntity',
			oppositeEntityName: 'groupEntity',
			relationName: 'reportRequest',
			relationOppositeName: 'group',
			entity: () => Models.ReportRequestEntity,
			joinEntity: () => Models.ReportRequestGroup,
			oppositeEntity: () => Models.GroupEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Group'] end
	})
	public groups: Models.ReportRequestGroup[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IReportRequestEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IReportRequestEntityAttributes>) {
		// % protected region % [Override assign attributes here] on begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.requestStatus) {
				this.requestStatus = attributes.requestStatus;
			}
			if (attributes.reportModality) {
				this.reportModality = attributes.reportModality;
			}
			if (attributes.urgent !== undefined) {
				this.urgent = attributes.urgent;
			}
			if (attributes.patientId) {
				this.patientId = attributes.patientId;
			}
			if (attributes.firstName) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName) {
				this.lastName = attributes.lastName;
			}
			if (attributes.dateOfBirth) {
				this.dateOfBirth = moment(attributes.dateOfBirth).toDate();
			}
			if (attributes.physicianName) {
				this.physicianName = attributes.physicianName;
			}
			if (attributes.imageId) {
				this.imageId = attributes.imageId;
			}
			if (attributes.imageDate) {
				this.imageDate = moment(attributes.imageDate).toDate();
			}
			if (attributes.sharedFolderIdentifier) {
				this.sharedFolderIdentifier = attributes.sharedFolderIdentifier;
			}
			if (attributes.minimumReads !== undefined) {
				this.minimumReads = attributes.minimumReads;
			}
			if (attributes.readsRemaining !== undefined) {
				this.readsRemaining = attributes.readsRemaining;
			}
			if (attributes.timeCompleted) {
				this.timeCompleted = moment(attributes.timeCompleted).toDate();
			}
			if (attributes.invalidatePriorRequest) {
				this.invalidatePriorRequest = attributes.invalidatePriorRequest;
			}
			if (attributes.orderingFacility) {
				this.orderingFacility = attributes.orderingFacility;
			}
			if (attributes.classificationPurpose) {
				this.classificationPurpose = attributes.classificationPurpose;
			}
			if (attributes.twoReads !== undefined) {
				this.twoReads = attributes.twoReads;
			}
			if (attributes.reportSubmissions) {
				for (const model of attributes.reportSubmissions) {
					if (model instanceof Models.ReportSubmissionEntity) {
						this.reportSubmissions.push(model);
					} else {
						this.reportSubmissions.push(
							new Models.ReportSubmissionEntity(model)
						);
					}
				}
			}
			if (attributes.checkOuts) {
				for (const model of attributes.checkOuts) {
					if (model instanceof Models.CheckOutEntity) {
						this.checkOuts.push(model);
					} else {
						this.checkOuts.push(new Models.CheckOutEntity(model));
					}
				}
			}
			if (attributes.adjudicationAttempts) {
				for (const model of attributes.adjudicationAttempts) {
					if (model instanceof Models.AdjudicationAttemptEntity) {
						this.adjudicationAttempts.push(model);
					} else {
						this.adjudicationAttempts.push(
							new Models.AdjudicationAttemptEntity(model)
						);
					}
				}
			}
			if (attributes.groups) {
				for (const model of attributes.groups) {
					if (model instanceof Models.ReportRequestGroup) {
						this.groups.push(model);
					} else {
						this.groups.push(new Models.ReportRequestGroup(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		groups {
			${Models.ReportRequestGroup.getAttributes().join('\n')}
			group {
				${Models.GroupEntity.getAttributes().join('\n')}
			}
		}
		adjudicationAttempts {
			${Models.AdjudicationAttemptEntity.getAttributes().join('\n')}
		}
		checkOuts {
			${Models.CheckOutEntity.getAttributes().join('\n')}
		}
		reportSubmissions {
			${Models.ReportSubmissionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			groups: {},
			adjudicationAttempts: {},
			checkOuts: {},
			reportSubmissions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'groups',
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.patientId;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	@action
	public decrementReadsRemaining() {
		this.readsRemaining -= 1;
		if (this.readsRemaining <= 0) {
			this.readsRemaining = 0;
		}
	}

	@action
	public incrementReadsRemaining() {
		this.readsRemaining += 1;
		if (this.readsRemaining > this.minimumReads) {
			this.readsRemaining = this.minimumReads;
		}
	}

	@action setCompleted() {
		this.requestStatus = 'COMPLETED';
	}

	@action setAwaitingAdjudication() {
		this.requestStatus = 'AWAITING_MANUAL_ADJUDICATION';
	}

	public static getFetchSingleQueryProfilePage() {
		const model = new ReportRequestEntity();
		const modelName = lowerCaseFirst(getModelName(ReportRequestEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(ReportRequestEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}

	public async saveWithCheckOut() {
		const relationPath = {
			workflowStatess: {},
			adjudicationAttempts: {},
			checkOuts: {},
		};

		return this.save(relationPath);
	}
	// % protected region % [Add any further custom model features here] end
}