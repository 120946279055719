/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import moment from 'moment';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import ReportSubmissionEntity from 'Models/Entities/ReportSubmissionEntity';
import * as Enums from 'Models/Enums';
import { store } from 'Models/Store';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { IFilter } from '../Components/Collection/CollectionFilterPanel';
import { IWhereCondition } from 'Views/Components/ModelCollection/CustomModelQuery';
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] off begin
// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] off begin
export default class CompletedReportTile extends React.Component<RouteComponentProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] off begin
	// % protected region % [Add class properties here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const {
			match, location, history, staticContext,
		} = this.props;

		contents = (
			<EntityCRUD
				modelType={ReportSubmissionEntity}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
				removeViewAction
				addColumns={CompletedReportTile.addColumns}
				additionalFilters={CompletedReportTile.getAdditionalFilters()}
				customOrderBy={CompletedReportTile.orderBy()}
				extraConditions={CompletedReportTile.extraConditions()}
				removeCreateAction
				removeEditAction
				removeDeleteAction
			/>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	protected static orderBy(): IOrderByCondition<Models.ReportSubmissionEntity>[] {
		return [
			{
				path: 'created',
				descending: true,
			},
		];
	}

	protected static extraConditions(): IWhereCondition<Models.ReportSubmissionEntity>[][] {
		if (store.radiologist) {
			return [
				[
					{
						comparison: 'equal',
						path: 'radiologistId',
						value: store.radiologist.id,
					} as IWhereCondition<Model>,
				],
			];
		}
		return [];
	}

	protected static getAdditionalFilters(): IFilter<Model>[] {
		return [
			{
				path: 'reportRequest.reportModality',
				comparison: 'equal',
				value1: [] as string[],
				displayName: 'Report Template',
				displayType: 'enum-combobox',
				referenceResolveFunction: makeEnumFetchFunction(Enums.reportModalityOptions),
			} as IFilter<Model>,
			{
				path: 'imageDate',
				comparison: 'range',
				active: false,
				value1: undefined,
				value2: undefined,
				displayName: 'Image Date',
				displayType: 'datepicker',
			} as IFilter<Model>,
			{
				path: 'timeCompleted',
				comparison: 'range',
				active: false,
				value1: undefined,
				value2: undefined,
				displayName: 'Submitted Date',
				displayType: 'datepicker',
			} as IFilter<Model>,
			{
				path: 'dateOfBirth',
				comparison: 'range',
				active: false,
				value1: undefined,
				value2: undefined,
				displayName: 'Date of Birth',
				displayType: 'datepicker',
			} as IFilter<Model>,
		];
	}

	protected static addColumns(tableHeaders: ICollectionHeaderProps<ReportSubmissionEntity>[]): void {
		tableHeaders.unshift({
			displayName: 'Report Template',
			name: '',
			sortable: false,
			transformItem: (item: any) => {
				const displayFunction = (attr: any, that: any): React.ReactNode => {
					const reportModality = that.reportRequest.reportModality === 'ILO' ? 'ILO' : that.reportRequest.reportModality;
					return (
						<span className={`report-type ${reportModality.toLowerCase()}`}>
							{reportModality}
						</span>
					);
				};
				return displayFunction('', item);
			},
		});

		tableHeaders.unshift({
			displayName: ' ',
			name: '',
			sortable: false,
			transformItem: (item: any) => {
				const displayFunction = (attr: any, that: any): React.ReactNode => {
					const reportModality = that.reportRequest.reportModality === 'ILO' ? 'ILO' : that.reportRequest.reportModality;
					return <div className={`category ${reportModality.toLowerCase()}`} />;
				};

				return displayFunction('', item);
			},
		});
	}
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
