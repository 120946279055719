/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { action, observable, runInAction } from 'mobx';
import { IAttributeGroup, Model, IModelAttributes, attribute, entity, jsonReplacerFn } from 'Models/Model';
import * as Validators from 'Validators';
import * as Models from '../Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from "Util/AttributeUtils";
import { IAcl } from 'Models/Security/IAcl';
import { makeFetchManyToManyFunc, makeFetchOneToManyFunc, makeJoinEqualsFunc, makeEnumFetchFunction } from 'Util/EntityUtils';
import { VisitorsRequestLogEntity } from 'Models/Security/Acl/VisitorsRequestLogEntity';
import { AdminRequestLogEntity } from 'Models/Security/Acl/AdminRequestLogEntity';
import { RadiologistRequestLogEntity } from 'Models/Security/Acl/RadiologistRequestLogEntity';
import * as Enums from '../Enums';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { SERVER_URL } from 'Constants';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IRequestLogEntityAttributes extends IModelAttributes {
	reportRequestID: string;
	patientId: string;
	fileName: string;
	archiveTime: Date;
	portalUploadTime: Date;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RequestLogEntity', 'Request Log')
// % protected region % [Customise your entity metadata here] end
export default class RequestLogEntity extends Model implements IRequestLogEntityAttributes {
	public static acls: IAcl[] = [
		new VisitorsRequestLogEntity(),
		new AdminRequestLogEntity(),
		new RadiologistRequestLogEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'patientId',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Report Request ID'] off begin
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Report Request ID',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportRequestID: string;
	// % protected region % [Modify props to the crud options here for attribute 'Report Request ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Patient Id'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Patient Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public patientId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Patient Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'File Name'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'File Name',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public fileName: string;
	// % protected region % [Modify props to the crud options here for attribute 'File Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archive Time'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Archive Time',
		displayType: 'datetimepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public archiveTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Archive Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Portal Upload Time'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Portal Upload Time',
		displayType: 'datetimepicker',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public portalUploadTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Portal Upload Time'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IRequestLogEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRequestLogEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.reportRequestID) {
				this.reportRequestID = attributes.reportRequestID;
			}
			if (attributes.patientId) {
				this.patientId = attributes.patientId;
			}
			if (attributes.fileName) {
				this.fileName = attributes.fileName;
			}
			if (attributes.archiveTime) {
				this.archiveTime = moment(attributes.archiveTime).toDate();
			}
			if (attributes.portalUploadTime) {
				this.portalUploadTime = moment(attributes.portalUploadTime).toDate();
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						]
					},
				],
			}
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}