/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsGroupMemberReferenceManyToMany } from '../Security/Acl/VisitorsGroupMemberReferenceManyToMany';
import { AdminGroupMemberReferenceManyToMany } from '../Security/Acl/AdminGroupMemberReferenceManyToMany';
import { RadiologistGroupMemberReferenceManyToMany } from '../Security/Acl/RadiologistGroupMemberReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IParentGroupGroupMemberAttributes extends IModelAttributes {
	parentGroupId: string;
	groupMemberId: string;

	parentGroup: Models.GroupEntity | Models.IGroupEntityAttributes;
	groupMember: Models.RadiologistEntity | Models.IRadiologistEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ParentGroupGroupMember')
export default class ParentGroupGroupMember extends Model implements IParentGroupGroupMemberAttributes {
	public static acls: IAcl[] = [
		new VisitorsGroupMemberReferenceManyToMany(),
		new AdminGroupMemberReferenceManyToMany(),
		new RadiologistGroupMemberReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public parentGroupId: string;

	@observable
	@attribute()
	public groupMemberId: string;

	@observable
	@attribute({isReference: true})
	public parentGroup: Models.GroupEntity;

	@observable
	@attribute({isReference: true})
	public groupMember: Models.RadiologistEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IParentGroupGroupMemberAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.parentGroupId) {
				this.parentGroupId = attributes.parentGroupId;
			}
			if (attributes.groupMemberId) {
				this.groupMemberId = attributes.groupMemberId;
			}

			if (attributes.parentGroup) {
				if (attributes.parentGroup instanceof Models.GroupEntity) {
					this.parentGroup = attributes.parentGroup;
					this.parentGroupId = attributes.parentGroup.id;
				} else {
					this.parentGroup = new Models.GroupEntity(attributes.parentGroup);
					this.parentGroupId = this.parentGroup.id;
				}
			} else if (attributes.parentGroupId !== undefined) {
				this.parentGroupId = attributes.parentGroupId;
			}

			if (attributes.groupMember) {
				if (attributes.groupMember instanceof Models.RadiologistEntity) {
					this.groupMember = attributes.groupMember;
					this.groupMemberId = attributes.groupMember.id;
				} else {
					this.groupMember = new Models.RadiologistEntity(attributes.groupMember);
					this.groupMemberId = this.groupMember.id;
				}
			} else if (attributes.groupMemberId !== undefined) {
				this.groupMemberId = attributes.groupMemberId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}