/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { SERVER_URL } from '../../../Constants';
import { RouteComponentProps } from "react-router";
import queryString from "querystring";
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

/**
 * Logs the user out of the application
 *
 * Can take a location query param that will redirect after the logout occurs
 */
@observer
export default class Logout extends React.Component<RouteComponentProps> {
	public componentDidMount() {
		const location = queryString.parse(this.props.location.search.substring(1))['redirect'] || undefined;
		let qry = `?redirect=${location ? `${location}` : '/'}`;
		window.location.href = `${SERVER_URL}/api/authorization/logout${qry}`;
	}

	public render() {
		return null;
	}
}