/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import { Link } from 'react-router-dom';
import { store } from 'Models/Store';
import moment from 'moment';
import { AdminEntity, RadiologistEntity, ReportSubmissionEntity, User } from 'Models/Entities';
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] on begin
import * as Enums from '../../Models/Enums';
import { action, observable, runInAction } from 'mobx';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import alert from '../../Util/ToastifyUtils';
import { Combobox } from 'Views/Components/Combobox/Combobox';
export interface IProfileTileProps extends RouteComponentProps {
	userDetails?: RadiologistEntity | AdminEntity;
}
// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] on begin
export default class ProfileTile extends React.Component<IProfileTileProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin

	// % protected region % [Add class properties here] end
	@observable
	public username = ''
	@observable
	public token = '';
	public user: RadiologistEntity | AdminEntity;
	// public accessToken = '';

	async componentWillMount() {

		const { userDetails } = this.props;

		if (userDetails?.hasOwnProperty('firstName')) {
			this.user = new RadiologistEntity(userDetails);
		} else if (userDetails !== undefined) {
			this.user = new AdminEntity(userDetails);
		}

		const { 
			email, 
			mobileNumber, 
		} = this.user;
		runInAction(() => {
			this.username = userDetails?.email ?? '';
		})
		this.fetchToken();
	}

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		let user;

		const { userDetails } = this.props;
		if (userDetails?.hasOwnProperty('firstName')) {
			user = new RadiologistEntity(userDetails);
		} else if (userDetails !== undefined) {
			user = new AdminEntity(userDetails);
		}
		
		if (this.user === undefined) {
			return <></>;
		}
		
		
		let name = '';

		let reportSubmissions: ReportSubmissionEntity[];

		let reportedLastMonth: ReportSubmissionEntity[] = [];
		let reportedThisMonth: ReportSubmissionEntity[] = [];

		let reportChange;
		let reportChangeString = '';
		let reportStringStyle;
		
		if (this.user instanceof RadiologistEntity) {
			name = `${this.user.firstName} ${this.user.lastName}`;
			reportSubmissions = this.user.reportSubmissions;
			runInAction(() => {
				this.username = `${this.user.email}`;
			})

			reportedThisMonth = reportSubmissions.filter((report) => moment(report.created).isAfter(moment([moment().year(), moment().month(), 1])));
			reportedLastMonth = reportSubmissions.filter((report) => moment(report.created).isAfter(moment([moment().year(), moment().month() - 1, 1])) 
				&& moment(report.created).isBefore(moment([moment().year(), moment().month(), 1])));

			reportChange = reportedLastMonth.length - reportedThisMonth.length;

			if (reportChange < 0) {
				reportChangeString = `${Math.abs(reportChange)} more than last month`;
				reportStringStyle = 'increased';
			} else if (reportChange > 0) {
				reportChangeString = `${reportChange} less than last month`;
				reportStringStyle = 'decreased';
			} else {
				reportChangeString = 'No change from last month';
				reportStringStyle = '';
			}
		}
		
		// if the user isn't the same as the radiologists profile, don't render anything.
		if (store.userGroups[0].name === 'Admin' || store.userId === this.user.id) {
			contents = (	
				<div className="body-content">
					<div className="user-profile">
						<div className="user-profile__title">
							<h3>Profile</h3>
						</div>

						<div className="user-profile__top">
							<div className="user-profile__details-container">
								<div className="user-profile__content">
									{name.length > 0 && (
										<div className="subheading">
											<h4>{name}</h4>									
										</div>
									)}
									<div className="profile-details">
										<div className="profile-detail labels">
											{['Email:', 'Mobile:', 'Password:'].map((labelName: string) => <label htmlFor="login_username-field" key={labelName}>{labelName}</label>)}
										</div>
										<div className="profile-details values">
                                            {[this.user.email, this.user.mobileNumber, 'Reset Password'].map((value) => {
                                                if (value !== undefined) {
                                                    if (value !== 'Reset Password') {
                                                        return <p key={value}>{value}</p>;
                                                    }													
                                                    return <Link to={`/reset-password?token=${this.token}&username=${this.username}`}>Reset Password </Link>;
                                                }
                                                return <p className="profile-validation-alert" key={value}>Not Captured</p>;
                                            })}
                                        </div>
									</div>
								</div>
							</div>
							
							{this.user instanceof RadiologistEntity && (
								<>
									<div className="user-profile__history-container">
										<div className="user-profile__content">
											<div className="subheading">
												<h4>This month</h4>
												<Link to="/history">View History</Link>
											</div>
											<div className="user-profile__requests">
												<div className="total-number">
													<h3>{reportedThisMonth.length}</h3>
												</div>
												<div className="reported-requests">
													<h4>Reported Requests</h4>
													<p className={reportStringStyle}>{reportChangeString}</p>
												</div>
											</div>
										</div>
									</div>
								</>
							)}
						</div>

						{this.user instanceof RadiologistEntity && (
							<>
								<div className="user-profile__bottom">
									<div className="user-profile__inputs-container">
										<div className="user-profile__content">
										<div className="subheading"><h4>Details</h4></div>
											{this.renderCerts(this.user)}
										</div>
									</div>
									<div className="user-profile__logo-container">
										<div className="user-profile__content">
											<img src="/images/logo-h-primary.svg" alt="logo" />
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			);
		}
		// % protected region % [Override contents here] end

		return contents;
	}

	fetchToken = async () => {
		const response = await axios.post(
			`${SERVER_URL}/api/account/generate-reset-password-token`,
				{
					username: this.username,
				});
		this.setToken(response.data);
	}

	@action
	setToken = (accessToken: string): void => {
		this.token = accessToken;
	}

	// % protected region % [Add class methods here] on begin
	renderCerts = (userDetails: RadiologistEntity): React.ReactElement => {
		const MRN = { details: userDetails.medicalRegistrationNumber, expiry: userDetails.medicalRegistrationNumberExpiry };
		const DNRME = { details: userDetails.dnrmeDepartmentApprovalRegistration, expiry: userDetails.dnrmeDepartmentApprovalRegistrationExpiry };
		const NIOSH = { details: userDetails.nioshBReaderCertificate, expiry: userDetails.nioshBReaderCertificateExpiry };
		const AMII = { details: userDetails.annualMedicalIndemnityInsurance, expiry: userDetails.annualMedicalIndemnityInsuranceExpiry };
		const RANZAC = { details: userDetails.ranzacRegistration, expiry: userDetails.ranzacRegistrationExpiry };

		async function updateUser(value: string) {
			userDetails.viewingClient = value as keyof typeof Enums.viewingClientOptions;
			userDetails
				.save()
				.then(() => {
					alert(`Your Viewing Client has been successfully changed`, 'success');
				})
				.catch(err => alert(err, 'error'));
		}
		return (
			<>
					<div className="" aria-label="Viewing Client">
						<Combobox 
							model={userDetails}
							modelProperty="viewingClient"
							label={"Viewing Client"}
							options={[
								{ display: "WEBCLIENT", value: "WEBCLIENT" },
								{ display: "THINKCLIENT", value: "THINKCLIENT" },
							]}
							onChange={(event, data) => updateUser(data.value as string)}
							searchable={false}
						/>
					</div>
					<div className="detail-container" aria-label="Email Address">
					<label htmlFor="login_username-field">Medical Registration Number</label>
					<div className="detail-item">
						<div className="detail-value">
							{MRN?.details !== null ? MRN.details : <span className="detail-undefined">Incomplete. See Administrator.</span>}
						</div>
						<div className="detail-expiry">
							{MRN?.expiry !== null ? (
								moment(MRN.expiry).isBefore(moment.now()) ? (
									<span className="profile-validation-alert">{moment(MRN.expiry).format('dddd, MMMM Do YYYY')}</span>
								) : (
									moment(MRN.expiry).format('dddd, MMMM Do YYYY')
								)
							) : (
								<span className="detail-undefined">Incomplete. See Administrator.</span>
							)}
						</div>
					</div>
				</div>
				<div className="detail-container" aria-label="Email Address">
					<label htmlFor="login_username-field">DNRME Department Approval Registration</label>
					<div className="detail-item">
						<div className="detail-value">
							{DNRME?.details !== null ? DNRME.details : <span className="detail-undefined">Incomplete. See Administrator.</span>}
						</div>
						<div className="detail-expiry">
							{DNRME?.expiry !== null ? (
								moment(DNRME.expiry).isBefore(moment.now()) ? (
									<span className="profile-validation-alert">{moment(DNRME.expiry).format('dddd, MMMM Do YYYY')}</span>
								) : (
									moment(DNRME.expiry).format('dddd, MMMM Do YYYY')
								)
							) : (
								<span className="detail-undefined">Incomplete. See Administrator.</span>
							)}
						</div>
					</div>
				</div>
				<div className="detail-container" aria-label="Email Address">
					<label htmlFor="login_username-field">Niosh B-Reader Certificate</label>
					<div className="detail-item">
						<div className="detail-value">
							{NIOSH?.details !== null ? NIOSH.details : <span className="detail-undefined">Incomplete. See Administrator.</span>}
						</div>
						<div className="detail-expiry">
							{NIOSH?.expiry !== null ? (
								moment(NIOSH.expiry).isBefore(moment.now()) ? (
									<span className="profile-validation-alert">{moment(NIOSH.expiry).format('dddd, MMMM Do YYYY')}</span>
								) : (
									moment(NIOSH.expiry).format('dddd, MMMM Do YYYY')
								)
							) : (
								<span className="detail-undefined">Incomplete. See Administrator.</span>
							)}
						</div>
					</div>
				</div>
				<div className="detail-container" aria-label="Email Address">
					<label htmlFor="login_username-field">Annual Medical Indemnity Insurance</label>
					<div className="detail-item">
						<div className="detail-value">
							{AMII?.details !== null ? AMII.details : <span className="detail-undefined">Incomplete. See Administrator.</span>}
						</div>
						<div className="detail-expiry">
							{AMII?.expiry !== null ? (
								moment(AMII.expiry).isBefore(moment.now()) ? (
									<span className="profile-validation-alert">{moment(AMII.expiry).format('dddd, MMMM Do YYYY')}</span>
								) : (
									moment(AMII.expiry).format('dddd, MMMM Do YYYY')
								)
							) : (
								<span className="detail-undefined">Incomplete. See Administrator.</span>
							)}
						</div>
					</div>
				</div>
				<div className="detail-container" aria-label="Email Address">
					<label htmlFor="login_username-field">RANZAC Registration</label>
					<div className="detail-item">
						<div className="detail-value">
							{RANZAC?.details !== null ? RANZAC.details : <span className="detail-undefined">Incomplete. See Administrator.</span>}
						</div>
						<div className="detail-expiry">
							{RANZAC?.expiry !== null ? (
								moment(RANZAC.expiry).isBefore(moment.now()) ? (
									<span className="profile-validation-alert">{moment(RANZAC.expiry).format('dddd, MMMM Do YYYY')}</span>
								) : (
									moment(RANZAC.expiry).format('dddd, MMMM Do YYYY')
								)
							) : (
								<span className="detail-undefined">Incomplete. See Administrator.</span>
							)}
						</div>
					</div>
				</div>
			</>
		);
	};
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
