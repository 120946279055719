/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { IWhereCondition } from 'Views/Components/ModelCollection/CustomModelQuery';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { store } from 'Models/Store';
import { Redirect } from 'react-router';
import { action, observable, runInAction } from 'mobx';
import { ReportRequestEntity } from 'Models/Entities';
import { ReactNode } from 'react';
import moment from 'moment';
import { Document, Page } from 'react-pdf';
import axios, { AxiosError } from 'axios';
import { sendStatusUpdate, sendSubmissionUpdate } from 'Util/ReportUtils';
import { IconPositions } from '../Components/Helpers/Common';
import alert from '../../Util/ToastifyUtils';
import AdjudicationForm from './AdjudicationForm';
import { SERVER_URL } from '../../Constants';
import CustomSpinner from '../Components/Spinner/CustomSpinner';
import { ILOReportState } from './ProduceILOReportTile';
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] off begin
// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] off begin
export default class AdjudicationTileTile extends React.Component<RouteComponentProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin
	@observable
	private currentReport: ReportRequestEntity;
	
	@observable
	private adjudicationReportState: ILOReportState;
	
	@observable 
	private openAccordionItem: number;
	
	@observable base64Preview: string;
	
	@observable
	private submittingReport: boolean = false;

	private timeStarted: Date = new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss'));
	// % protected region % [Add class properties here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const {
			match, match: { path }, location, history, staticContext,
		} = this.props;

		if (!store.isAdjudicator) {
			return <Redirect exact to={`${path}/requestdashboard`} />;
		}
		
		if (!this.currentReport || this.currentReport.id === undefined) {
			contents = (
				<EntityCRUD
					modelType={Models.ReportRequestEntity}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
					additionalTableActions={this.getTableActionsMore()}
					removeViewAction
					addColumns={AdjudicationTileTile.addColumns}
					customOrderBy={AdjudicationTileTile.orderBy()}
					extraConditions={AdjudicationTileTile.extraConditions()}
					removeCreateAction
					removeEditAction
					removeDeleteAction
				/>
			);
		} else {
			const submissions: ReactNode[] = [];
			
			this.currentReport.reportSubmissions.forEach((curr) => {
				submissions.push(
					<AdjudicationForm 
						readOnly 
						firstName={curr.firstName}
						lastName={curr.lastName}
						reportStateObject={curr.reportState} 
						radiologistId={curr.radiologistId}
						completedDate={moment(curr.timeCompleted).toISOString()}
						openAccordionItemFunc={this.openAccordionItemFunc}
						openTabOverride={this.openAccordionItem}
					/>,
				);
			});
			
			contents = (
				<div className="adjudication-panel master-form">
					<AdjudicationForm
						readOnly={false}
						firstName={this.currentReport.firstName}
						lastName={this.currentReport.lastName}
						dateOfBirth={this.currentReport.dateOfBirth.toDateString()}
						urgent={this.currentReport.urgent}
						previewFunc={this.adjudicationPreview}
						openAccordionItemFunc={this.openAccordionItemFunc}
						openTabOverride={this.openAccordionItem}
						reportStateObject={this.currentReport.adjudicationAttempts[0].adjudicatedState}
						furtherReads={this.furtherReads}
						reportCount={this.currentReport.reportSubmissions.length}
						backToList={this.stopReport}
					/>
					<div className="adjudication-scrollable">
						{submissions}
					</div>
				</div>
			);
			
			if (this.adjudicationReportState && !this.base64Preview) {
				contents = this.renderReportDetails();
			}
			
			if (this.base64Preview) {
				contents = this.renderReportPreview();
			}
		}
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	protected static orderBy(): IOrderByCondition<Models.ReportRequestEntity>[] {
		return [
			{
				path: 'urgent',
				descending: true,
			},
			{
				path: 'created',
				descending: false,
			},
		];
	}

	protected static extraConditions(): IWhereCondition<Models.RadiologistEntity>[][] {
		return [
			[
				{
					comparison: 'equal',
					path: 'requestStatus',
					value: 'AWAITING_MANUAL_ADJUDICATION',
				} as IWhereCondition<Model>,
			],
		];
	}

	protected getTableActionsMore(): Array<ICollectionItemActionProps<Models.ReportRequestEntity>> {
		return [
			{
				action: (report: Models.ReportRequestEntity): void => {
					this.startReport(report);
				},
				label: 'Start Reporting',
				showIcon: true,
				icon: 'chevron-right',
				iconPos: 'icon-right' as IconPositions,
			},
		];
	}


	//open easy vis portal with report images 
	openStudy = (): void => {

		axios.post(
			`${SERVER_URL}/getStudy`, { StudyUUID: this.currentReport.imageId, UserId: store.userId, },
		).then((res) => {
			window.open(res.data, "_LungScreen_ImageViewer", "top=0,left=0,width=" + window.screen.availWidth + ",height=" + window.screen.availHeight);
		}).catch((err: AxiosError) => {
			if (err.response) {
				const errMessage = err.response?.data.replace(/^Error: /g, 'Easyviz: ');
				console.error(errMessage);
				alert(errMessage, 'error');
			} else {
				console.error(err);
			}
		});
	};

	/**
	 * Callback function for starting a report
	 * @param report
	 */
	@action
	startReport = (report: Models.ReportRequestEntity): void => {
		if (report === null) {
			alert('Something went wrong', 'error');
		} else {
			this.currentReport = report;
			this.openStudy()
		}
	};

	

	@action
	stopReport = (): void => {
		if (this.currentReport) {
			this.currentReport = new ReportRequestEntity();
		}
	};
	
	@action
	adjudicationPreview = (reportState: ILOReportState): void => {
		this.adjudicationReportState = reportState;
	};
	
	@action
	openAccordionItemFunc = (itemNumber: string[]): void => {
		if (Number.isNaN(Number(itemNumber[0]))) {
			this.openAccordionItem = 0;
		} else {
			this.openAccordionItem = Number(itemNumber[0]);
		}
	};

	protected static addColumns(tableHeaders: ICollectionHeaderProps<Models.ReportRequestEntity>[]): void {
		tableHeaders.unshift({
			displayName: ' ',
			name: '',
			sortable: false,
			transformItem: (item) => {
				const displayFunction = (_attr: string, that: ReportRequestEntity): React.ReactNode => {
					const reportModality = that.reportModality === 'ILO' ? 'ILO' : that.reportModality;
					return <div className={`category ${reportModality.toLowerCase()}`} />;
				};

				return displayFunction('', item);
			},
		});
	}

	@action
	previewReport = async () => {
		const formModel = await this.createFormModel();

		axios.post(
		`${SERVER_URL}/createIloPreviewFromModel`, 
			{"FormModel": formModel},
		).then((res) => {
			runInAction(() => {
				this.base64Preview = res.data;
			});
		});
	};
	
	// TODO: Save state so it can be loaded again 
	@action
	goBack = (): void => {
		if (this.base64Preview) {
			// Preview screen
			this.base64Preview = '';
		} else if (!this.base64Preview && this.adjudicationReportState) {
			// Details screen
			this.adjudicationReportState = null as any;
		}
	};

	@action
	public createReportSubmission = async (): Promise<void> => {
		const adjudicatedReportSubmission = new Models.ReportSubmissionEntity();
		
		// from report request
		adjudicatedReportSubmission.patientId = this.currentReport.patientId;
		adjudicatedReportSubmission.firstName = this.currentReport.firstName;
		adjudicatedReportSubmission.lastName = this.currentReport.lastName;
		adjudicatedReportSubmission.imageDate = this.currentReport.imageDate;
		adjudicatedReportSubmission.dateOfBirth = this.currentReport.dateOfBirth;
		adjudicatedReportSubmission.orderingFacility = this.currentReport.orderingFacility;
		adjudicatedReportSubmission.classificationPurpose = this.currentReport.classificationPurpose;

		// computed at submission time
		adjudicatedReportSubmission.timeStarted = this.timeStarted;
		adjudicatedReportSubmission.timeCompleted = new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss'));
		adjudicatedReportSubmission.requireAppointment = true;
		
		// radiologist
		adjudicatedReportSubmission.radiologist = store.radiologist;
		adjudicatedReportSubmission.radiologistId = store.radiologist.id;
		
		// reportRequest
		adjudicatedReportSubmission.reportRequest = this.currentReport;
		
		// Set to false if null
		adjudicatedReportSubmission.reportRequest.urgent = this.currentReport.urgent || false;
		
		adjudicatedReportSubmission.reportRequestId = this.currentReport.id;

		const formModel = await this.createFormModel();
		adjudicatedReportSubmission.formModel = formModel === null ? '' : JSON.stringify(formModel);
		
		adjudicatedReportSubmission.reportState = JSON.stringify(this.adjudicationReportState);

		// adjudicated and final
		adjudicatedReportSubmission.adjudicated = true;
		adjudicatedReportSubmission.finalReport = true;
		
		this.currentReport.setCompleted();

		adjudicatedReportSubmission.saveWithRadiologistAndReport().then(() => {
			this.transmitFinalReport(adjudicatedReportSubmission);
		});
	};

	@action
	private furtherReads = (): void => {
		if (this.currentReport.minimumReads === 2 || this.currentReport.minimumReads === 3) {
			this.currentReport.minimumReads = 5;
			this.currentReport.readsRemaining = 5 - this.currentReport.reportSubmissions.length;
			this.currentReport.requestStatus = 'IN_PROGRESS';
		}
		
		// Set to false if urgent is null
		this.currentReport.urgent = this.currentReport.urgent || false;
		
		this.currentReport.saveWithCheckOut().then(() => {
			sendStatusUpdate(this.currentReport.id, true);

			this.stopReport();
		});
	};
	
	@action
	private transmitFinalReport(reportSubmission: Models.ReportSubmissionEntity): void {
		this.submittingReport = true;
		axios
			.post(
				`${SERVER_URL}/api/entity/ReportRequestEntity/TransmitFinalReport`,
				{
					SubmissionId: reportSubmission.id,
					Adjudicated: reportSubmission.adjudicated,
				},
			)
			.then(() => {
				store.updateRadiologist();

				runInAction(() => {
					this.submittingReport = false;
				});

				sendStatusUpdate(this.currentReport.id);
				sendSubmissionUpdate(reportSubmission.id);

				this.stopReport();
			})
			.catch((error) => {
				alert(
					`Error transmitting report to Portal\n${error.response.data}`,
					'error',
				);
				console.error(
					`Error transmitting report to Portal: ${error.response.data}`,
				);

				runInAction(() => {
					this.submittingReport = false;
				});
			});
	}

	@action
	createFormModel = () => axios
		.post(`${SERVER_URL}/generateFormModel`, {
			ReportState: JSON.stringify(this.adjudicationReportState),
			ReportRequestId: this.currentReport.id,
			RadiologistId: store.radiologist.id,
			Adjudicated: true,
		})
		.then((res) => res.data)
		.catch((err: AxiosError | Error) => {
			console.error(err.message);
			return null;
		});

	renderReportDetails = (): React.ReactNode => (
		<>
			<div className="report-details-container">
				<div className="report-details-col">
					<div className="report-details-pair">
						<span className="detail-label">Patient name:</span>
						<span className="detail-content">
							{this.currentReport.firstName} 
							{' '}
							
							{this.currentReport.lastName}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">D.O.B:</span>
						<span className="detail-content">{this.currentReport.dateOfBirth.toDateString()}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Patient ID:</span>
						<span className="detail-content">{this.currentReport.patientId}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Date of Radiograph:</span>
						<span className="detail-content">{this.currentReport.imageDate.toDateString()}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Ordering Physician:</span>
						<span className="detail-content">{this.currentReport.physicianName}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Ordering Facility:</span>
						<span className="detail-content">{this.currentReport.orderingFacility}</span>
					</div>
				</div>
				<div className="report-details-col">
					<div className="report-details-pair">
						<span className="detail-label">Classification Purpose:</span>
						<span className="detail-content">{this.currentReport.classificationPurpose}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Type of reading:</span>
						<span className="detail-content">O</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Date of reading:</span>
						<span className="detail-content">{moment.utc().add(10, 'h').format('DD-MM-YYYY')}</span>
					</div>
					<div className="report-details-filler"> </div>
				</div>
			</div>
			<div className="report-footer">
				<button type="button" onClick={this.goBack} className="btn btn--solid backBtn">
					Back
				</button>
				<span className="primary-btns">
					<button type="button" onClick={this.previewReport} className="btn btn--solid">
						Preview Report
					</button>
				</span>
			</div>
		</>
	);

	renderReportPreview = (): React.ReactNode => (
		<>
			<Document className="pdf-preview" file={`data:application/pdf;base64,${this.base64Preview}`}>
				<Page scale={1.5} pageNumber={1} />
				<Page scale={1.5} pageNumber={2} />
			</Document>
			<div className="report-footer">
				<button type="button" onClick={this.goBack} className="btn btn--solid backBtn">
					Back
				</button>

				{this.submittingReport ? (
					<div className="primary-btns">
						<CustomSpinner />
					</div>
				) : (
					<div className="primary-btns">
						<button type="button" onClick={this.createReportSubmission} className="btn btn--solid">
							Submit
						</button>
					</div>
				)}
			</div>
		</>
	);
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
