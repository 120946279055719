/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import * as Models from 'Models/Entities';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { Model } from 'Models/Model';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import * as Enums from 'Models/Enums';
import { IWhereCondition } from 'Views/Components/ModelCollection/CustomModelQuery';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { store } from 'Models/Store';
import { observable, action } from 'mobx';
import FileUpload from 'Views/Components/FileUpload/FileUpload';
import Axios from 'axios';
import { IconPositions } from '../Components/Helpers/Common';
import { IFilter } from '../Components/Collection/CollectionFilterPanel';
import alert from '../../Util/ToastifyUtils';
import * as ReportUtils from '../../Util/ReportUtils';
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] on begin
interface OutstandingRequestListProps extends RouteComponentProps {
	setReport: (report: Models.ReportRequestEntity) => void;
}

interface AXTFileUpload {
	axtFile?: Blob;
	radiologistId: string;
}
// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] on begin
export default class OutstandingRequestListTile extends React.Component<OutstandingRequestListProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin
	@observable
	axtFileUpload: AXTFileUpload = {
		radiologistId: '',
	};

	@observable
	radiologists: Models.RadiologistEntity[];

	componentDidMount() {
		this.fetchRadiologists();
	}
	// % protected region % [Add class properties here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const {
			match, location, history, staticContext,
		} = this.props;

		contents = (
			<EntityCRUD
				modelType={Models.ReportRequestEntity}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
				additionalTableActions={this.getTableActionsMore()}
				removeViewAction
				addColumns={OutstandingRequestListTile.addColumns}
				removeCreateAction
				removeEditAction
				removeDeleteAction
				additionalFilters={OutstandingRequestListTile.getAdditionalFilters()}
				customOrderBy={OutstandingRequestListTile.orderBy()}
				extraConditions={OutstandingRequestListTile.extraConditions()}
				entityCollectionProps={{ variables: { compareCounts: true } }}
			/>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	protected static orderBy(): IOrderByCondition<Models.ReportRequestEntity>[] {
		return [
			{
				path: 'urgent',
				descending: true,
			},
			{
				path: 'created',
				descending: false,
			},
		];
	}


	// this function filters the report request table
	protected static extraConditions(): IWhereCondition<Models.ReportRequestEntity>[][] {
		const conditions: IWhereCondition<Model>[][] = [];
		if (store.radiologist) {
			conditions.push( 
				[
					{
						comparison: 'equal',
						path: 'requestStatus',
						value: 'IN_PROGRESS',
					} as IWhereCondition<Model>,
				],
			);
			conditions.push(
				[
					{
						comparison: 'notEqual',
						path: 'reportSubmissions.radiologistId',
						value: store.radiologist?.id,
					} as IWhereCondition<Model>,
				],
			);
			if (store.radiologist.iloCertified && !store.radiologist.icoerdCertified) {
				conditions.push(
					[
						{
							comparison: 'equal',
							path: 'reportModality',
							value: Enums.reportModalityOptions.ILO,
						} as IWhereCondition<Model>,
					],
				);
			}
			if (store.radiologist.icoerdCertified && !store.radiologist.iloCertified) {
				conditions.push(
					[
						{
							comparison: 'equal',
							path: 'reportModality',
							value: Enums.reportModalityOptions.ICOERD,
						} as IWhereCondition<Model>,
					],
				);
			}
		}
		// remove cardiology reports form report request page
		conditions.push( 
			[
				{
					path:'reportModality', 
					comparison:'notEqual', 
					value:'CARDIOLOGY'
				} as IWhereCondition<Model>,
			]
		)
		// only show reports that are in progress
		conditions.push( 
			[
				{
					comparison: 'equal',
					path: 'requestStatus',
					value: 'IN_PROGRESS',
				} as IWhereCondition<Model>,
			],
		);
		return conditions;
	}

	protected getTableActionsMore() {
		const tableActionsMore = [];
		if (store.radiologist) {
			tableActionsMore.push(
				{
					action: (report: Models.ReportRequestEntity): void => {
						this.startReport(report);
					},
					label: 'Start Reporting',
					showIcon: true,
					icon: 'chevron-right',
					iconPos: 'icon-right' as IconPositions,
				},
			);
		}
		if (store.hasBackendAccess) {
			tableActionsMore.push(
				{
					action: (report: Models.ReportRequestEntity): void => {
						this.toggleModal(report);
					},
					label: 'Upload Offline Report',
					showIcon: true,
					icon: 'upload',
					iconPos: 'icon-right' as IconPositions,
				},
			);
		}
		return tableActionsMore;
	}

	protected static getAdditionalFilters() {
		//Filter CARDIOLOGY out of the reportModalityOptions ENUM before it is passed to the
		//filter function. This stops option to filter by cardiology on the 
		//report request page where no cardiology reports are displayed.
		const filteredReportModalityOptions = Object.keys(Enums.reportModalityOptions)
            .filter(key => key != "CARDIOLOGY")
            .reduce((obj, key) => {
                obj[key] = Enums.reportModalityOptions[key];
                return obj;
            }, {});

		return [{
			path: 'reportModality',
			comparison: 'equal',
			value1: [] as string[],
			displayName: 'Report Modality',
			displayType: 'enum-combobox',
			referenceResolveFunction: makeEnumFetchFunction(filteredReportModalityOptions),
		} as IFilter<Model>];
	}
	//
	@action
	protected toggleModal(report: Models.ReportRequestEntity) {
		const radiologistOptions = [<option key="select" value="select">Select A Radiologist</option>];
		
		this.radiologists.sort((a, b) => (a.lastName[0] < b.lastName[0] ? -1 : 1))
			.forEach((r) => radiologistOptions.push(<option key={r.id} value={r.id}>{`${r.firstName} ${r.lastName}`}</option>));

		store.modal.show('AXT File Upload', 
			<div className="modal">
				<form onSubmit={(event) => this.submitAxtFile(event, report)}>
					<h4>Select AXT File</h4>
					<FileUpload 
						model={this.axtFileUpload}
						modelProperty="axtFile"
						onChange={(file: File) => {
							this.axtFileUpload.axtFile = file;
							return true;
						}}
						preview
					/>
					<h4>Select Reporting Radiologist</h4>
					<select name="radiologists" id="radiologists" onChange={(event) => this.axtFileUpload.radiologistId = event.target.value}>
						{radiologistOptions}
					</select>
					<div className="modal-controls">
						<button className="btn btn--solid" type="submit">Upload</button>
						<button className="btn btn--solid" type="button" onClick={() => store.modal.hide()}>Cancel</button>
					</div>
				</form>
			</div>);
	}

	@action
	protected fetchRadiologists = async () => {
		await Axios.get('/api/entity/RadiologistEntity')
			.then((res) => (this.radiologists = res.data))
			.catch((e) => console.error("Couldn't fetch radiologists: ", e));
	};

	protected submitAxtFile = (event: React.FormEvent<HTMLFormElement>, report: Models.ReportRequestEntity) => {
		event.preventDefault();

		if (!this.axtFileUpload.axtFile) {
			alert('Must Select AXT File', 'error');
			return;
		}

		if (!this.axtFileUpload.radiologistId || this.axtFileUpload.radiologistId === 'select') {
			alert('Must Select Reporting Radiologist', 'error');
			return;
		}

		const bodyFormData = new FormData();
		bodyFormData.append('file', this.axtFileUpload.axtFile || '');
		bodyFormData.append('reportRequestId', report.id);
		bodyFormData.append('radiologistId', this.axtFileUpload.radiologistId);

		Axios({ 
			method: 'post',
			url: '/api/entity/ReportRequestEntity/UploadExistingReportFromFile/',
			data: bodyFormData,
			headers: { 'Content-Type': 'multipart/form-data' },
		}).then((result) => {
			if (result.data != null) {
				this.onAXTUploadSuccess();
				alert('AXT File Uploaded Successfully', 'success');
			}
		}).catch((e) => {
			console.error('Failed to upload AXT file: ', e);
			alert('Failed To Upload AXT File', 'error');
		});
	};

	@action
	protected onAXTUploadSuccess() {
		this.axtFileUpload = {
			radiologistId: '',
		};
	}

	/**
	 * Callback function for starting a report
	 * @param report
	 */
	protected async startReport(report: Models.ReportRequestEntity) {
		// Sync the report with the latest model data
		const updatedReport = await ReportUtils.syncReportDataWithModel(report);

		// Verify that the report can actually be opened
		if (updatedReport != null && await ReportUtils.verifyReportCanBeOpened(updatedReport)) {
			// Assign the verified report
			const { setReport } = this.props;
			setReport(updatedReport);
		}
	}

	protected static addColumns(tableHeaders: ICollectionHeaderProps<Models.ReportRequestEntity>[]) {
		tableHeaders.unshift({
			displayName: ' ',
			name: '',
			sortable: false,
			transformItem: (item) => {
				const displayFunction = (attr: string, that: Models.ReportRequestEntity) => {
					const reportModality = that.reportModality === 'ILO' ? 'ILO' : that.reportModality;
					return <div className={`category ${reportModality.toLowerCase()}`} />;
				};

				return displayFunction('', item);
			},
		});
	}
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
