
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, useParams } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { store } from 'Models/Store';
import { gql } from 'apollo-boost';
import { StandardsImagesEntity } from '../../Models/Entities';



import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';

export interface standardImagesPageState {
	standardImageUrl: string | null;
}

@observer
export default class StandardsImagesPage extends React.Component<RouteComponentProps, standardImagesPageState> {

	constructor(props: RouteComponentProps) {
		super(props);	
		this.state = {
			standardImageUrl: null,
		}
	}

	private getImage() {
		const imageId = new URLSearchParams(window.location.search).get('id');
		if (!imageId) {
			return;
		}
		store.apolloClient
		.query({
			query: StandardsImagesEntity.queryStandards(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id', 
					comparison: 'equal',
					value: imageId,
				}],
			}
		})
		.then((d) => {
			this.setState({standardImageUrl: d.data?.standardsImagesEntitys[0]?.location});
		})
	}

	public render() {

		const contents = (
			<SecuredPage groups={['Admin', 'Radiologist']}>
				<div className="body-content">
					<div className="standards-images-page">
						{ this.state.standardImageUrl &&
						<img src={this.state.standardImageUrl} /> }
					</div>
					
				</div>
			</SecuredPage>
		);
		return contents;
	}

    componentDidMount(): void {
		this.getImage();
    }
}

