/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';
import { Button, Display, Colors, Sizes } from '../Components/Button/Button';
import { action, observable, runInAction } from 'mobx';
import { TextField } from '../Components/TextBox/TextBox';
import { IUserResult, store } from 'Models/Store';
import { SERVER_URL } from 'Constants';
import axios from 'axios';
import * as queryString from 'querystring';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { Password } from 'Views/Components/Password/Password';
import _ from 'lodash';
import { isEmail } from 'Validators/Functions/Email';
import alert from '../../Util/ToastifyUtils';
import { getErrorMessages } from 'Util/GraphQLUtils';
// % protected region % [Add any extra imports here] on begin
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
// % protected region % [Add any extra imports here] end

// % protected region % [Customise ILoginState here] on begin
interface ILoginState {
	username: string;
	password: string;
	errors: { [attr: string]: string };
}

interface IAuthenticateToken {
	code: string;
	errors: { [attr: string]: string };
}

enum ILoginPageState {
	email,
	sms
}
// % protected region % [Customise ILoginState here] end

// % protected region % [Customise defaultLoginState here] on begin
const defaultLoginState: ILoginState = {
	username: '',
	password: '',
	errors: {},
};

const defaultTokenState: IAuthenticateToken = {
	code: '',
	errors: {},
};
// % protected region % [Customise defaultLoginState here] end

// % protected region % [Add any extra constants here] off begin
// % protected region % [Add any extra constants here] end

@observer
// % protected region % [Override class signature here] off begin
export default class LoginPage extends React.Component<RouteComponentProps> {
// % protected region % [Override class signature here] end
	@observable
	private loginState: ILoginState = defaultLoginState;

	// % protected region % [Add any extra fields here] on begin
	@observable
	private loginPageState: ILoginPageState = ILoginPageState.email;

	@observable
	private awaitingCredentialCheck: boolean = false;

	@observable
	private awaitingTokenValidation: boolean = false;

	@observable authenticateToken: IAuthenticateToken = defaultTokenState;

	public renderLoginScreen() {
		return (
			<div className="body-content">
				<div className="login-container">
					<form className="login" onSubmit={this.onLoginClicked}>
						<div className="radar-branding" />
						<h2>Login</h2>
						<TextField
							id="login_username"
							className="login-username"
							model={this.loginState}
							modelProperty="username"
							label="Email"
							inputProps={{ autoComplete: 'username', type: 'email' }}
							isRequired
							errors={this.loginState.errors.username}
							autoFocus
						/>
						<Password
							id="login_password"
							className="login-password"
							model={this.loginState}
							modelProperty="password"
							label="Password"
							inputProps={{ autoComplete: 'current-password' }}
							isRequired
							errors={this.loginState.errors.password}
						/>
						{this.awaitingCredentialCheck ? (
							<CustomSpinner />
						) : (
							<ButtonGroup alignment={Alignment.HORIZONTAL} className="login-buttons">
								<Button type="submit" className="login-submit" display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: "login_submit" }}>Login</Button>
								</ButtonGroup>
							)}
						<p>
							<a className='link-forgotten-password link-rm-txt-dec' onClick={this.onForgottenPasswordClick}>Forgot your password? </a>
						</p>
					</form>
					<div className="login-image">
						<h2>Helping to save lives by detecting lung disease early.</h2>
					</div>
				</div>
			</div>
		);
	}

	public renderSmsScreen() {
		return (
			<div className="body-content">
				<div className="login-container">
					<form className="login" onSubmit={this.validateCode}>
						<div className="radar-branding" />
						<h2>Two factor authentication</h2>
						<TextField
							id="enter_code"
							className="enter-code"
							model={this.authenticateToken}
							modelProperty="code"
							label="Authentication Code"
							isRequired
							errors={this.authenticateToken.errors.token}
							autoFocus
						/>
						{this.awaitingTokenValidation ? (
							<CustomSpinner />
						) : (
								<ButtonGroup alignment={Alignment.HORIZONTAL} className="login-buttons">
									<Button type="submit" className="login-submit" display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: 'login_submit' }}>Validate</Button>
								</ButtonGroup>
							)}

						<p>
							<a className="link-forgotten-password link-rm-txt-dec" onClick={() => this.setLoginPageState(ILoginPageState.email)}>Back to login</a>
						</p>
					</form>
					<div className="login-image">
						<h2>Helping to save lives by detecting lung disease early.</h2>
					</div>
				</div>
			</div>
		);
	}
	// % protected region % [Add any extra fields here] end

	// % protected region % [Override render here] on begin
	public render() {
		let contents = null;

		if (store.loggedIn) {
			return <Redirect to="/" />;
		}

		contents = (
			<>
				{this.loginPageState === ILoginPageState.email && (
					this.renderLoginScreen()
				)}

				{this.loginPageState === ILoginPageState.sms && (
					this.renderSmsScreen()
				)}

			</>
		);
		return contents;
	}
	// % protected region % [Override render here] end

	// % protected region % [Override onLoginClicked here] on begin
	@action
	private setLoginPageState(state: ILoginPageState): void {
		this.loginPageState = state;
		this.awaitingCredentialCheck = false;
	}

	@action
	private validateCode = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.authenticateToken.errors = {};

		if (!this.authenticateToken.code) {
			this.loginState.errors.code = 'Security code is required';
		}

		if (Object.keys(this.loginState.errors).length <= 0) {
			this.awaitingTokenValidation = true;
			axios.post(
				`${SERVER_URL}/api/authorization/VerifyToken`,
				{
					username: this.loginState.username,
					password: this.loginState.password,
					code: this.authenticateToken.code,
				},
			)
				.then(({ data }) => {
					// Go to sms screen
					this.onLoginSuccess(data);
				})
				.catch(response => {
					this.onTokenValidationFailure(response);
				});
		}
	};

	@action
	private onLoginClicked = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.loginState.errors = {};

		if (!this.loginState.username) {
			this.loginState.errors.username = 'Email Address is required';
		} else if (!isEmail(this.loginState.username)) {
			this.loginState.errors.username = 'This is not a valid email address';
		}
		if (!this.loginState.password) {
			this.loginState.errors.password = 'Password is required';
		}

		if (Object.keys(this.loginState.errors).length <= 0) {
			this.awaitingCredentialCheck = true;
			axios.post(
				// 2FA Enabled
				`${SERVER_URL}/api/authorization/CheckCredentials`,
				// 2FA Disabled
				//`${SERVER_URL}/api/authorization/login`,
				{
					username: this.loginState.username,
					password: this.loginState.password,
				})
				.then(({ data }) => {
					// If we get back 'Skip' it means we don't have to use 2FA
					if (data == "Skip") {
						axios.post(
							`${SERVER_URL}/api/authorization/login`,
							{
								username: this.loginState.username,
								password: this.loginState.password,
							})
							.then(({ data }) => {
								this.onLoginSuccess(data);
							})
							.catch((response) => {
								this.onCredentialCheckFailure(response);
							});
					} else {
						// 2FA Enabled
						this.setLoginPageState(ILoginPageState.sms);
						// 2FA Disabled
						//this.onLoginSuccess(data);
					}
				})
				.catch((response) => {
					this.onCredentialCheckFailure(response);
				});
		}

	};
	// % protected region % [Override onLoginClicked here] end

	// % protected region % [Override onStartRegisterClicked here] off begin
	@action
	private onStartRegisterClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		store.routerHistory.push(`/register?${!!redirect ? `redirect=${redirect}` : ''}`);
	};
	// % protected region % [Override onStartRegisterClicked here] end

	// % protected region % [Override login success logic here] on begin
	@action
	private onLoginSuccess = (userResult: IUserResult) => {
		this.awaitingTokenValidation = false;
		store.setLoggedInUser(userResult);

		const { redirect } = queryString.parse(this.props.location.search.substring(1));

		if (redirect && !Array.isArray(redirect)) {
			store.routerHistory.push(redirect);
		} else {
			store.routerHistory.push('/');
		}
	};
	// % protected region % [Override login success logic here] end

	// % protected region % [Override onForgottenPasswordClick here] off begin
	@action
	private onForgottenPasswordClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		store.routerHistory.push(`/reset-password-request`);
	};
	// % protected region % [Override onForgottenPasswordClick here] end

	// % protected region % [Add class methods here] on begin
	@action
	private onCredentialCheckFailure = (response: any) => {
		this.awaitingCredentialCheck = false;
		const errorMessages = getErrorMessages(response).map((error: any) => {
			const message = typeof error.message === 'object'
				? JSON.stringify(error.message)
				: error.message;
			return (<p>{message}</p>);
		});
		alert(
			<div>
				<h6>Login failed</h6>
				{errorMessages}
			</div>,
			'error',
		);
	};

	@action
	private onTokenValidationFailure = (response: any) => {
		this.awaitingTokenValidation = false;
		const errorMessages = getErrorMessages(response).map((error: any) => {
			const message = typeof error.message === 'object'
				? JSON.stringify(error.message)
				: error.message;
			return (<p>{message}</p>);
		});
		alert(
			<div>
				<h6>Login failed</h6>
				{errorMessages}
			</div>,
			'error',
		);
	}
	// % protected region % [Add class methods here] end
}

// % protected region % [Add additional exports here] off begin
// % protected region % [Add additional exports here] end
