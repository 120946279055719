/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import ProfileTile from 'Views/Tiles/ProfileTile';
// % protected region % [Add any extra imports here] on begin
import { action, computed, observable } from 'mobx';
import { store } from '../../Models/Store';
import { AdminEntity, RadiologistEntity } from '../../Models/Entities';
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] off begin
// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] off begin
export default class RadiologistProfileWrappingTileTile extends React.Component<RouteComponentProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin
	@observable
	public user: RadiologistEntity | AdminEntity;

	@computed get isLoading(): boolean {
		return (this.user === undefined);
	}

	componentDidMount(): void {
		this.fetchData();
	}
	// % protected region % [Add class properties here] end

	public render() {
		let contents = (
			<>
				<ProfileTile {...this.props} />
			</>
		);

		// % protected region % [Override contents here] on begin
		const {
			match, history, location, staticContext,
		} = this.props;
		
		if (this.isLoading) {
			return <></>;
		}

		contents = (
			<>
				<ProfileTile 
					history={history} 
					location={location}
					match={match}
					staticContext={staticContext}
					userDetails={this.user}
				/>
			</>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	fetchData = async () => {
		//@ts-ignore this is here because of the id on params
		const { match: { params: { id } } } = this.props;

		try {
			// Attempt to load profile of radiologist account
			const radiologist: RadiologistEntity = (await store.apolloClient.query({
				query: RadiologistEntity.getFetchSingleQueryProfilePage(true),
				fetchPolicy: 'network-only',
				variables: {
					args: [{
						path: 'id',
						comparison: 'equal',
						value: id,
					}],
				},
			})).data.radiologistEntity;
			
			this.setUserDetails(radiologist);
		} catch {
			// Account is not a radiologist, attempt to load an admin
			try {
				const admin: AdminEntity = (await store.apolloClient.query({
					query: AdminEntity.fetchAdmin(),
					fetchPolicy: 'network-only',
					variables: {
						args: [{
							path: 'id',
							comparison: 'equal',
							value: id,
						}],
					},
				})).data.adminEntity;

				this.setUserDetails(admin);
			} catch {
				console.warn('Profile is neither radiologist or admin')
			}
		}			
	};

	@action
	setUserDetails = (userDetails: RadiologistEntity | AdminEntity): void => {
		this.user = userDetails;
	};
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
