import * as React from 'react';
import { QueryResult, Query, OperationVariables } from 'react-apollo';
import { DocumentNode } from 'graphql';
import { Model } from 'Models/Model';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { getModelName, getFetchAllQuery, getFetchAllConditional, getCustomAllQuery } from 'Util/EntityUtils';
import { observer } from 'mobx-react';
import { isOrCondition } from '../../../Util/GraphQLUtils';

export type Comparators = 'contains' | 'endsWith' | 'equal' | 'greaterThan' | 'greaterThanOrEqual' | 'in' | 'notIn' | 'lessThan' | 'lessThanOrEqual' | 'like' | 'notEqual' | 'startsWith';

export interface IOrderByCondition<T> {
	path: string;
	descending?: boolean;
}

export type CaseComparison =
	'CURRENT_CULTURE' |
	'CURRENT_CULTURE_IGNORE_CASE' |
	'INVARIANT_CULTURE' |
	'INVARIANT_CULTURE_IGNORE_CASE' |
	'ORDINAL' |
	'ORDINAL_IGNORE_CASE';

export type CaseComparisonPascalCase =
	'CurrentCulture' |
	'CurrentCultureIgnoreCase' |
	'InvariantCulture' |
	'InvariantCultureIgnoreCase' |
	'Ordinal' |
	'OrdinalIgnoreCase';

interface BaseWhereCondition<T> {
	path: string;
	comparison: Comparators;
	value: any;
}

export interface IWhereCondition<T> extends BaseWhereCondition<T> {
	case?: CaseComparison;
}

export interface IWhereConditionApi<T> extends BaseWhereCondition<T> {
	case?: CaseComparisonPascalCase;
}

export interface IModelQueryVariables<T> {
	skip?: number; 
	take?: number; 
	args?: Array<IWhereCondition<T>>;
	orderBy: Array<IOrderByCondition<T>>;
	ids?: string[];
}

export interface IModelQueryProps<T extends Model, TData = any> {
	children: (result: QueryResult<TData, OperationVariables>) => JSX.Element | null;
	model: {new(json?: {}): T};
	conditions?: Array<IWhereCondition<T>> | Array<Array<IWhereCondition<T>>>;
	ids?: string[];
	orderBy?: IOrderByCondition<T>[];
	customQuery?: DocumentNode;
	pagination: PaginationQueryOptions;
	variables?: {[key: string]: any};
}

@observer
class ModelQuery<T extends Model, TData = any> extends React.Component<IModelQueryProps<T, TData>> {
	public render() {
		const { 
			conditions, 
			model, 
			customQuery, 
			children,
			variables,
		} = this.props;

		let fetchAllQuery;
		
		if (isOrCondition(conditions)) {
			fetchAllQuery = getFetchAllConditional(model);
		} else {
			fetchAllQuery = getFetchAllQuery(model);
		}

		if (variables) {
			fetchAllQuery = getCustomAllQuery(model);
		}

		return (
			<Query 
				fetchPolicy="network-only"
				notifyOnNetworkStatusChange
				query={customQuery || fetchAllQuery} 
				variables={this.constructVariables()} 
			>
				{children}
			</Query>
		);
	}

	private constructVariables() {
		const { 
			conditions, 
			ids, 
			orderBy, 
			orderBy: orderByProp, 
			pagination,
		} = this.props;
		const { page, perPage } = pagination;

		// console.log({
		// 	skip: page * perPage,
		// 	take: perPage,
		// 	args: conditions,
		// 	orderBy: [orderBy],
		// 	ids,
		// 	...this.props.variables,
		// })

		return {
			skip: page * perPage, 
			take: perPage, 
			args: conditions,
			orderBy,
			ids,
			...this.props.variables,
		};
	}
}

export default ModelQuery;
