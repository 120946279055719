import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { ChangeEvent } from 'react';
import { store } from 'Models/Store';
import { FormTile, ReportProps } from './ProduceReportTile';
import alert from '../../Util/ToastifyUtils';
import axios, {AxiosError} from "axios";
import {SERVER_URL} from "../../Constants";

export interface ICOERDReportState {
	techniqueQuality: number;
	techniqueDefects: string[];
	techniqueDefectsOther: string;

	filmNegative: boolean;

	wellDefinedOpacities: {
		present: boolean | null;
		p: boolean | null;
		q: boolean | null;
		r: boolean | null;
		profusion: {
			ur: number | null;
			mr: number | null;
			lr: number | null;
			ul: number | null;
			ml: number | null;
			ll: number | null;
		};
	};

	irregularOpacities: {
		present: boolean | null;
		intralobular: boolean | null;
		interlobular: boolean | null;
		grade: {
			ur: number | null;
			mr: number | null;
			lr: number | null;
			ul: number | null;
			ml: number | null;
			ll: number | null;
		};
	};

	largeOpacities: boolean | null;
	largeOpacityZones: string[];

	groundGlass: boolean | null;
	groundGlassGrade: {
		ur: number | null;
		mr: number | null;
		lr: number | null;
		ul: number | null;
		ml: number | null;
		ll: number | null;
	};

	honeycombing: boolean | null;
	honeycombingGrade: {
		ur: number | null;
		mr: number | null;
		lr: number | null;
		ul: number | null;
		ml: number | null;
		ll: number | null;
	};

	emphysema: boolean | null;
	emphysemaGrade: {
		ur: number | null;
		mr: number | null;
		lr: number | null;
		ul: number | null;
		ml: number | null;
		ll: number | null;
	};

	mosaicAttenuation: boolean | null;
	mosaicAttenuationGrade: {
		ur: number | null;
		mr: number | null;
		lr: number | null;
		ul: number | null;
		ml: number | null;
		ll: number | null;
	};

	predominantParenchymal: string[];

	pleuralDisease: boolean | null;
	pleuralDiseases: string[];

	significantSolidNodules: boolean | null;
	solidLesions: string[];

	significantSubSolidNodules: boolean | null;
	subSolidLesions: string[];

	otherAbnormalities: boolean | null;
	otherAbnormalitiesList: string[];
	otherComments: string;

	impressions: string[];

	approveStudy: boolean;
	seePhysician: string;
	dateDoctorNotified: string | null;
}

@observer
export default class ProduceICOERDReportTile extends React.Component<ReportProps> {
	@observable
	public reportState: ICOERDReportState;

	private toggleStandards: Function;

	private openStudy: Function;

	constructor(props: ReportProps) {
		super(props);

		const { toggleStandards, openStudy } = this.props;
		this.toggleStandards = toggleStandards;
		this.openStudy = openStudy;
	}

	componentDidMount() {
		const { reportSnapshot } = this.props;
		if (
			reportSnapshot
			&& 'techniqueQuality' in reportSnapshot
		) {
			this.setReportState(reportSnapshot);
		} else {
			this.clearReportState();
		}
	}

	public render() {
		const { report, formState } = this.props;

		let contents = null;

		if (this.reportState) {
			contents = (
				<>
					<div className="report-sidebar">
						<div className="personal-identifier">
							<h4>
								{report.firstName} 
								{' '}
								{report.lastName}
							</h4>
							{report.urgent && (
								<span className="report-type urgent">Urgent</span>
							)}
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.IndicateTechnique)}
							role="button"
							tabIndex={0}
						>
							<div
								className={`numberCircle  ${this.techniqueDone() && 'done'}`}
							>
								1
							</div>
							<span
								className={`${
									formState === FormTile.IndicateTechnique && 'active'
								}`}
							>
								Indicate technique
							</span>
						</div>

						<div className="sidebar-button-reverse">
							<h4>Abnormalities</h4>
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.Lung)}
							role="button"
							tabIndex={0}
						>
							<div className={`numberCircle ${this.lungDone() && 'done'}`}>
								2
							</div>
							<span className={`${formState === FormTile.Lung && 'active'}`}>
								Lung
							</span>
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.Pleura)}
							role="button"
							tabIndex={0}
						>
							<div className={`numberCircle ${this.pleuraDone() && 'done'}`}>
								3
							</div>
							<span className={`${formState === FormTile.Pleura && 'active'}`}>
								Pleura
							</span>
						</div>

						<div
							className="sidebar-button"
							onClick={(): void => this.setFormTile(FormTile.OtherAbnormalities)}
							role="button"
							tabIndex={0}
						>
							<div
								className={`numberCircle ${
									this.otherAbnormalitiesDone() && 'done'
								}`}
							>
								4
							</div>
							<span
								className={`${
									formState === FormTile.OtherAbnormalities && 'active'
								}`}
							>
								Other Abnormalities
							</span>
						</div>

						<div className="sidebar-checkbox">
							<button
								onClick={() => this.setFilmNegative(!this.reportState.filmNegative)}
								type="button"
								className={`btn ${this.reportState.filmNegative && 'active'}`}
							>
								X
							</button>
							<span>Is the film completely negative?</span>
						</div>

						<br />
						<div className="sidebar-button-reverse standards">
							<div className="sidebar-button-standards-container">
								<button
									type="button"
									className="btn btn--solid"
									onClick={(): void => this.toggleStandards()}
								>
									Standards List
								</button>
							</div>
							<div className="sidebar-button-study-container">
								<button
									type="button"
									className="btn btn--solid"
									onClick={(): void => {
										this.openStudy();
									}}
								>
									Reopen Study
								</button>
							</div>
						</div>
					</div>

					<div className="report-form">
						{formState === FormTile.IndicateTechnique
							&& this.renderIndicateTechnique()}

						{formState === FormTile.Lung && this.renderLung()}

						{formState === FormTile.Pleura && this.renderPleura()}

						{formState === FormTile.OtherAbnormalities
							&& this.renderOtherAbnormalities()}
					</div>
				</>
			);
		}

		return contents;
	}

	@action
	setReportState = (reportState: ICOERDReportState): void => {
		this.reportState = reportState;
	};

	@action
	clearReportState = (): void => {
		this.reportState = {
			techniqueQuality: 0,
			techniqueDefects: [],
			techniqueDefectsOther: '',

			filmNegative: false,

			wellDefinedOpacities: {
				present: null,
				p: null,
				q: null,
				r: null,
				profusion: {
					ur: null,
					mr: null,
					lr: null,
					ul: null,
					ml: null,
					ll: null,
				},
			},
			irregularOpacities: {
				present: null,
				intralobular: null,
				interlobular: null,
				grade: {
					ur: null,
					mr: null,
					lr: null,
					ul: null,
					ml: null,
					ll: null,
				},
			},
			largeOpacities: null,
			largeOpacityZones: [],
			groundGlass: null,
			groundGlassGrade: {
				ur: null,
				mr: null,
				lr: null,
				ul: null,
				ml: null,
				ll: null,
			},
			honeycombing: null,
			honeycombingGrade: {
				ur: null,
				mr: null,
				lr: null,
				ul: null,
				ml: null,
				ll: null,
			},

			emphysema: null,
			emphysemaGrade: {
				ur: null,
				mr: null,
				lr: null,
				ul: null,
				ml: null,
				ll: null,
			},

			mosaicAttenuation: null,
			mosaicAttenuationGrade: {
				ur: null,
				mr: null,
				lr: null,
				ul: null,
				ml: null,
				ll: null,
			},

			predominantParenchymal: [],

			pleuralDisease: null,
			pleuralDiseases: [],

			significantSolidNodules: null,
			solidLesions: [],

			significantSubSolidNodules: null,
			subSolidLesions: [],

			otherAbnormalities: null,
			otherAbnormalitiesList: [],
			otherComments: '',

			impressions: [],

			approveStudy: false,
			seePhysician: 'No',
			dateDoctorNotified: null,
		};
	};

	renderIndicateTechnique = (): React.ReactNode => (
		<div className="indicate-technique-tile report-tile">
			<div className="report-section">
				<h4>1. HRCT Technique and Quality</h4>
				<div className="button-group">
					{[1, 2, 3, 4].map((value) => (
						<button
							type="button"
							className={`btn ${
								this.reportState.techniqueQuality === value && 'active'
							}`}
							onClick={() => this.setTechniqueQuality(value)}
							key={value}
						>
							{value !== 4 ? value : 'U/R'}
						</button>
					))}
				</div>

				{this.reportState.techniqueQuality === 1 && (
					<span className="bold gap">Good.</span>
				)}

				{this.reportState.techniqueQuality === 2 && (
					<span className="bold gap">
						Acceptable, with no technical defects likely to impair
						classification of the radiograph for pneumoconiosis.
					</span>
				)}

				{this.reportState.techniqueQuality === 3 && (
					<span className="bold gap">
						Acceptable, with some technical defect but still adequate for
						classification purposes.
					</span>
				)}

				{this.reportState.techniqueQuality === 4 && (
					<span className="bold gap">Un-readable.</span>
				)}

				{this.reportState.techniqueQuality !== 0
					&& this.reportState.techniqueQuality !== 1
					&& this.reportState.techniqueQuality !== 4 && (
					<div className="button-group">
						{[
							'Incomplete Available Series',
							'Slice thickness >1mm',
							'Movement artifact',
							'Low Dose Mottle',
							'Other',
						].map((value) => (
							<button
								type="button"
								className={`btn ${
									this.reportState.techniqueDefects.includes(value)
										&& 'active'
								}`}
								onClick={(): void => this.addTechniqueDefect(value)}
								key={value}
							>
								{value}
							</button>
						))}
					</div>
				)}

				{this.reportState.techniqueDefects.includes('Other') && (
					<div className="comment-box">
						<span className="bold">Other defects:</span>
						<input
							id="other-defects"
							type="text"
							value={this.reportState.techniqueDefectsOther}
							onChange={this.handleOtherTechniqueChange}
						/>
					</div>
				)}
			</div>
		</div>
	);

	renderLung = (): React.ReactNode => (
		<div className="report-tile">
			<div className="report-section">
				<h4>2.A Well defined rounded opacities</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setWellDefinedRoundedOpacities(true)}
						className={`btn ${
							this.reportState.wellDefinedOpacities.present && 'active'
						}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setWellDefinedRoundedOpacities(false)}
						className={`btn ${
							this.reportState.wellDefinedOpacities.present === false
							&& 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.wellDefinedOpacities.present && (
					<>
						<div className="button-grid grid-col-3-long">
							<div />
							<span className="bold no-gap">No</span>
							<span className="bold no-gap">Yes</span>

							<span className="bold align-right">P = &gt; 1.5mm</span>
							<button
								type="button"
								onClick={(): void => this.setWellDefinedRoundedLetter(false, 'p')}
								className={`btn ${
									this.reportState.wellDefinedOpacities.p === false && 'active'
								}`}
							>
								X
							</button>
							<button
								type="button"
								onClick={(): void => this.setWellDefinedRoundedLetter(true, 'p')}
								className={`btn ${
									this.reportState.wellDefinedOpacities.p && 'active'
								}`}
							>
								X
							</button>

							<span className="bold align-right">Q = 1.5 - 3mm</span>
							<button
								type="button"
								onClick={(): void => this.setWellDefinedRoundedLetter(false, 'q')}
								className={`btn ${
									this.reportState.wellDefinedOpacities.q === false && 'active'
								}`}
							>
								X
							</button>
							<button
								type="button"
								onClick={(): void => this.setWellDefinedRoundedLetter(true, 'q')}
								className={`btn ${
									this.reportState.wellDefinedOpacities.q && 'active'
								}`}
							>
								X
							</button>

							<span className="bold align-right">R = &lt; 3 - 10mm</span>
							<button
								type="button"
								onClick={(): void => this.setWellDefinedRoundedLetter(false, 'r')}
								className={`btn ${
									this.reportState.wellDefinedOpacities.r === false && 'active'
								}`}
							>
								X
							</button>
							<button
								type="button"
								onClick={(): void => this.setWellDefinedRoundedLetter(true, 'r')}
								className={`btn ${
									this.reportState.wellDefinedOpacities.r && 'active'
								}`}
							>
								X
							</button>
						</div>

						<h5 className="no-gap">Zones Profusion</h5>
						{this.renderGradeButtonGrid(
							this.reportState.wellDefinedOpacities.profusion,
							this.setWellDefinedProfusion,
						)}
					</>
				)}
			</div>

			<div className="report-section space">
				<h4>2.B Irregular and/or linear opacities</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setIrregularOpacities(true)}
						className={`btn ${
							this.reportState.irregularOpacities.present && 'active'
						}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setIrregularOpacities(false)}
						className={`btn ${
							this.reportState.irregularOpacities.present === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.irregularOpacities.present && (
					<>
						<div className="button-grid grid-col-3-long">
							<div />
							<span className="bold no-gap">No</span>
							<span className="bold no-gap">Yes</span>

							<span className="bold align-right">Intralobular</span>
							<button
								type="button"
								onClick={(): void => this.setIrregularIntra(false)}
								className={`btn ${
									this.reportState.irregularOpacities.intralobular === false
									&& 'active'
								}`}
							>
								X
							</button>
							<button
								type="button"
								onClick={(): void => this.setIrregularIntra(true)}
								className={`btn ${
									this.reportState.irregularOpacities.intralobular && 'active'
								}`}
							>
								X
							</button>

							<span className="bold align-right">Interlobular</span>
							<button
								type="button"
								onClick={(): void => this.setIrregularInter(false)}
								className={`btn ${
									this.reportState.irregularOpacities.interlobular === false
									&& 'active'
								}`}
							>
								X
							</button>
							<button
								type="button"
								onClick={(): void => this.setIrregularInter(true)}
								className={`btn ${
									this.reportState.irregularOpacities.interlobular && 'active'
								}`}
							>
								X
							</button>
						</div>

						<h5 className="no-gap">Grade</h5>
						{this.renderGradeButtonGrid(
							this.reportState.irregularOpacities.grade,
							this.setIrregularGrade,
						)}
					</>
				)}
			</div>

			<div className="report-section space">
				<h4>2.C Large Opacities</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setLargeOpacities(true)}
						className={`btn ${this.reportState.largeOpacities && 'active'}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setLargeOpacities(false)}
						className={`btn ${
							this.reportState.largeOpacities === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.largeOpacities && (
					<div className="button-grid grid-col-3 space">
						<div />
						<span className="bold no-gap">R</span>
						<span className="bold no-gap">L</span>

						<span className="bold align-right">U</span>
						<button
							type="button"
							onClick={(): void => this.addLargeOpacityZone('UR')}
							className={`btn ${
								this.reportState.largeOpacityZones.includes('UR') && 'active'
							}`}
						>
							X
						</button>
						<button
							type="button"
							onClick={(): void => this.addLargeOpacityZone('UL')}
							className={`btn ${
								this.reportState.largeOpacityZones.includes('UL') && 'active'
							}`}
						>
							X
						</button>

						<span className="bold align-right">M</span>
						<button
							type="button"
							onClick={(): void => this.addLargeOpacityZone('MR')}
							className={`btn ${
								this.reportState.largeOpacityZones.includes('MR') && 'active'
							}`}
						>
							X
						</button>
						<button
							type="button"
							onClick={(): void => this.addLargeOpacityZone('ML')}
							className={`btn ${
								this.reportState.largeOpacityZones.includes('ML') && 'active'
							}`}
						>
							X
						</button>

						<span className="bold align-right">L</span>
						<button
							type="button"
							onClick={(): void => this.addLargeOpacityZone('LR')}
							className={`btn ${
								this.reportState.largeOpacityZones.includes('LR') && 'active'
							}`}
						>
							X
						</button>
						<button
							type="button"
							onClick={(): void => this.addLargeOpacityZone('LL')}
							className={`btn ${
								this.reportState.largeOpacityZones.includes('LL') && 'active'
							}`}
						>
							X
						</button>
					</div>
				)}
			</div>

			<div className="report-section space">
				<h4>2.D Ground glass opacity grade</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setGroundGlass(true)}
						className={`btn ${this.reportState.groundGlass && 'active'}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setGroundGlass(false)}
						className={`btn ${
							this.reportState.groundGlass === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.groundGlass
					&& this.renderGradeButtonGrid(
						this.reportState.groundGlassGrade,
						this.setGroundGlassGrade,
					)}
			</div>

			<div className="report-section space">
				<h4>2.E Honeycombing grade</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setHoneycombing(true)}
						className={`btn ${this.reportState.honeycombing && 'active'}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setHoneycombing(false)}
						className={`btn ${
							this.reportState.honeycombing === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.honeycombing
					&& this.renderGradeButtonGrid(
						this.reportState.honeycombingGrade,
						this.setHoneycombingGrade,
					)}
			</div>

			<div className="report-section space">
				<h4>2.F Emphysema grade</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setEmphysema(true)}
						className={`btn ${this.reportState.emphysema && 'active'}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setEmphysema(false)}
						className={`btn ${
							this.reportState.emphysema === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.emphysema
					&& this.renderGradeButtonGrid(
						this.reportState.emphysemaGrade,
						this.setEmphysemaGrade,
					)}
			</div>

			<div className="report-section space">
				<h4>2.G Mosaic attenuation</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setMosaicAttenuation(true)}
						className={`btn ${this.reportState.mosaicAttenuation && 'active'}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setMosaicAttenuation(false)}
						className={`btn ${
							this.reportState.mosaicAttenuation === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.mosaicAttenuation
					&& this.renderGradeButtonGrid(
						this.reportState.mosaicAttenuationGrade,
						this.setMosaicAttenuationGrade,
					)}
			</div>

			<div className="report-section space">
				<h4>2.H Predominant and Parenchymal</h4>

				<div className="button-group">
					{['RO', 'IR', 'GGO', 'HC', 'EM', 'LO'].map((parenchymal) => (
						<button
							type="button"
							className={`btn ${
								this.reportState.predominantParenchymal.includes(parenchymal)
								&& 'active'
							}`}
							onClick={(): void => this.addPredominantParenchymal(parenchymal)}
							key={parenchymal}
						>
							{parenchymal}
						</button>
					))}
				</div>
			</div>
		</div>
	);

	renderPleura = (): React.ReactNode => (
		<div className="report-tile">
			<div className="report-section">
				<h4>3.A CT Findings of Pleural Disease</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setPleuralDisease(true)}
						className={`btn ${this.reportState.pleuralDisease && 'active'}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setPleuralDisease(false)}
						className={`btn ${
							this.reportState.pleuralDisease === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.pleuralDisease && (
					<div className="button-group">
						{['Plaque', 'Calcification', 'Fluid'].map((disease) => (
							<button
								type="button"
								onClick={(): void => this.addPleuralDisease(disease)}
								className={`btn ${
									this.reportState.pleuralDiseases.includes(disease) && 'active'
								}`}
								key={disease}
							>
								{disease}
							</button>
						))}
					</div>
				)}
			</div>

			<div className="report-section space">
				<h4>
					3.B Significant Solid Nodules (
					{'>'}
5mm or 
					{' '}
					{'>'}
80mm3)
				</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setSignificantSolidNodules(true)}
						className={`btn ${
							this.reportState.significantSolidNodules && 'active'
						}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setSignificantSolidNodules(false)}
						className={`btn ${
							this.reportState.significantSolidNodules === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.significantSolidNodules && (
					<div className="report-subsection flex ignore-cols">
						{[1, 2, 3].map((lesionNo) => (
							<div className="report-subsection-element" key={lesionNo}>
								<div className="button-grid grid-col-3">
									<span className="bold title">
Lesion
										{lesionNo}
									</span>
									<div />
									<span className="bold no-gap header">R</span>
									<span className="bold no-gap header">L</span>

									{['u', 'm', 'l'].map((section) => (
										<React.Fragment key={section}>
											<span className="bold no-gap align-right">
												{section.toUpperCase()}
											</span>
											{['r', 'l'].map((side) => (
												<button
													type="button"
													onClick={(): void => this.addSolidLesion(
														`sn${lesionNo}${section}${side}`,
													)}
													className={`btn ${
														this.reportState.solidLesions.includes(
															`sn${lesionNo}${section}${side}`,
														) && 'active'
													}`}
													key={`${section}.${side}`}
												>
													X
												</button>
											))}
										</React.Fragment>
									))}
								</div>
							</div>
						))}
					</div>
				)}
			</div>

			<div className="report-section space">
				<h4>
3.C Significant Subsolid Nodules (
					{'>'}
5mm)
				</h4>
				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setSignificantSubSolidNodules(true)}
						className={`btn ${
							this.reportState.significantSubSolidNodules && 'active'
						}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setSignificantSubSolidNodules(false)}
						className={`btn ${
							this.reportState.significantSubSolidNodules === false && 'active'
						}`}
					>
						No
					</button>
				</div>

				{this.reportState.significantSubSolidNodules && (
					<div className="report-subsection flex ignore-cols">
						{[1, 2, 3].map((lesionNo) => (
							<div className="report-subsection-element" key={lesionNo}>
								<div className="button-grid grid-col-3">
									<span className="bold title">
Lesion
										{lesionNo}
									</span>
									<div />
									<span className="bold no-gap header">R</span>
									<span className="bold no-gap header">L</span>

									{['u', 'm', 'l'].map((section) => (
										<React.Fragment key={section}>
											<span className="bold no-gap align-right">
												{section.toUpperCase()}
											</span>
											{['r', 'l'].map((side) => (
												<button
													type="button"
													onClick={(): void => this.addSubSolidLesion(
														`ssn${lesionNo}${section}${side}`,
													)}
													className={`btn ${
														this.reportState.subSolidLesions.includes(
															`ssn${lesionNo}${section}${side}`,
														) && 'active'
													}`}
													key={`${section}.${side}`}
												>
													X
												</button>
											))}
										</React.Fragment>
									))}
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);

	// Renders a dynamic grid of buttons used for grade/profusion selection
	renderGradeButtonGrid = (
		reportProperty: Record<string, any>,
		onClick: Function,
	): React.ReactNode => (
		<div className="grade-matrix-container">
			{['r', 'l'].map((side) => (
				<div className="grade-matrix" key={side}>
					<div className="button-grid grid-col-5">
						<span className="header bold no-gap">{side.toUpperCase()}</span>

						{['u', 'm', 'l'].map((section) => (
							<React.Fragment key={section}>
								<span className="bold no-gap">{section.toUpperCase()}</span>
								{[0, 1, 2, 3].map((grade) => (
									<button
										type="button"
										onClick={(): void => onClick(grade, `${section}${side}`)}
										className={`btn matrix-button ${
											reportProperty[`${section}${side}`] === grade && 'active'
										}`}
										key={grade}
									>
										{grade}
									</button>
								))}
							</React.Fragment>
						))}
					</div>
				</div>
			))}
			<div className="grade-sum">
				<span>Sum Grade</span>
				<span className="bold large">{this.getGradeSum(reportProperty)}</span>
			</div>
		</div>
	);

	renderOtherAbnormalities = (): React.ReactNode => (
		<div className="report-tile">
			<div className="report-section">
				<h4>4A. Any other abnormalities?</h4>

				<div className="button-group">
					<button
						type="button"
						onClick={(): void => this.setOtherAbnormalities(true)}
						className={`btn ${this.reportState.otherAbnormalities && 'active'}`}
					>
						Yes
					</button>

					<button
						type="button"
						onClick={(): void => this.setOtherAbnormalities(false)}
						className={`btn ${
							this.reportState.otherAbnormalities === false && 'active'
						}`}
					>
						No
					</button>
				</div>
			</div>

			{this.reportState.otherAbnormalities && this.renderOtherConditions()}

			<div className="report-section space">
				<h4>4D. Impression</h4>

				<div className="button-list">
					{[
						'No HRCT evidence of lung or pleural disease attributable to occupational exposure.',
						'Abnormalities are present that may be due to occupational exposure.',
						'Progress assessment of known disease.',
						'Other significant abnormalities have been demonstrated which will require further clinical consideration.',
					].map((impression) => (
						<div className="button-row" key={impression}>
							<button
								type="button"
								onClick={() => this.addImpression(impression)}
								className={`btn ${
									this.reportState.impressions.includes(impression) && 'active'
								}`}
							>
								x
							</button>
							<p>{impression}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);

	renderOtherConditions = () => {
		// prettier-ignore
		const otherConditions = {
			'Airways Disorder': [
				'Bronchiectasis',
				'Bronchial wall thickening',
				'Bronchial opacification',
			],
			'Changes of Prior Surgery': [
				'Cardiac surgery',
				'Lung surgery',
				'Breast surgery',
			],
			Diaphragm: [
				'Eventration',
				'Hiatal hernia ',
			],
			'Lung Parenchyma': [
				'Azygos lobe',
				'Consolidation',
				'Atelectasis',
			],
			'Lymph Nodes': [
				'Enlarged >10mm short axis',
				'Hiatal hernia',
			],
			'Thoracic Cage Abnormalities': [
				'Fractures rib, sternum or clavicles',
				'Scoliosis',
				'Vertebral wedging >20%',
			],
			'Vascular Disorders': [
				'Aorta or major branches',
				'Pericardial effusion',
				'Coronary calcification',
			],

		};

		return (
			<>
				<div className="report-section space">
					<h4>4B. Mark all boxes that apply</h4>
					<div className="report-subsection cols">
						{Object.keys(otherConditions).map((category) => (
							<div className="report-subsection-element" key={category}>
								<span className="label bold">{category}</span>

								{otherConditions[category].map((condition: string) => (
									<div
										className="other-abnormalities-col-inner"
										key={condition}
									>
										<button
											type="button"
											onClick={() => this.setOtherAbnormalitiesList(condition)}
											className={`btn ${
												this.reportState.otherAbnormalitiesList.includes(
													condition,
												) && 'active'
											}`}
										>
											x
										</button>
										<p>{condition}</p>
									</div>
								))}
							</div>
						))}
					</div>
				</div>

				<div className="report-section space">
					<h4>4C. Other Comments</h4>
					<div className="comment-box">
						<textarea
							value={this.reportState.otherComments}
							onChange={this.handleOtherCommentsChange}
							rows={3}
						/>
					</div>
				</div>
			</>
		);
	};

	//
	//
	//      END RENDER FUNCTIONS
	//
	//-------------------------------
	//
	//      HELPER FUNCTIONS
	//
	//

	validateReport = (): boolean => {
		if (
			this.techniqueDone()
			&& this.lungDone()
			&& this.pleuraDone()
			&& this.otherAbnormalitiesDone()
		) {
			return true;
		} 
		let jointErrors: string[] = [];

		jointErrors = jointErrors.concat(this.techniqueErrors());
		jointErrors = jointErrors.concat(this.lungErrors());
		jointErrors = jointErrors.concat(this.pleuraErrors());
		jointErrors = jointErrors.concat(this.otherAbnormalitiesErrors());

		// Show all errors up to a defined number
		const maxErrors = 5;
		const numErrors =				jointErrors.length <= maxErrors ? jointErrors.length : maxErrors;

		// If there are too many errors, print the max - 1, and then say how many more
		// ie. maxErrors = 3 means it will show 2 errors, with the third toast saying "and x more"
		if (numErrors >= maxErrors) {
			for (let i = 0; i < maxErrors - 1; i++) {
				alert(`${jointErrors[i]}`, 'error');
			}

			alert(`...and ${jointErrors.length - maxErrors + 1} more...`, 'error');
		} else {
			for (let i = 0; i < numErrors; i++) {
				alert(`${jointErrors[i]}`, 'error');
			}
		}

		return false;
	};

	techniqueErrors = (): string[] => {
		const errors: string[] = [];

		if (this.reportState.techniqueQuality === 0) {
			errors.push('Indicate technique');
		}

		if (
			this.reportState.techniqueQuality === 2
			|| this.reportState.techniqueQuality === 3
		) {
			if (this.reportState.techniqueDefects.length === 0) {
				errors.push('Indicate technique defect(s)');
			}

			if (
				this.reportState.techniqueDefects.includes('Other')
				&& this.reportState.techniqueDefectsOther.length === 0
			) {
				errors.push('Indicate other defect(s)');
			}
		}
		return errors;
	};

	techniqueDone = (): boolean => this.techniqueErrors().length === 0;

	lungErrors = (): string[] => {
		const errors: string[] = [];

		if (this.reportState.wellDefinedOpacities.present === null) errors.push('Indicate well defined opacities');

		if (this.reportState.wellDefinedOpacities.present) {
			if (
				this.reportState.wellDefinedOpacities.p === null
				|| this.reportState.wellDefinedOpacities.q === null
				|| this.reportState.wellDefinedOpacities.r === null
			) {
				errors.push('Indicate well defined opacities predominant size');
			}

			if (
				this.reportState.wellDefinedOpacities.profusion.ur === null
				|| this.reportState.wellDefinedOpacities.profusion.mr === null
				|| this.reportState.wellDefinedOpacities.profusion.lr === null
				|| this.reportState.wellDefinedOpacities.profusion.ul === null
				|| this.reportState.wellDefinedOpacities.profusion.ml === null
				|| this.reportState.wellDefinedOpacities.profusion.ll === null
			) {
				errors.push('Indicate well defined opacities profusion');
			}
		}

		if (this.reportState.irregularOpacities.present === null) errors.push('Indicate irregular opacities');

		if (this.reportState.irregularOpacities.present) {
			if (
				this.reportState.irregularOpacities.interlobular === null
				|| this.reportState.irregularOpacities.intralobular === null
			) {
				errors.push('Indicate irregular opacities predominant type');
			}

			if (
				this.reportState.irregularOpacities.grade.ur === null
				|| this.reportState.irregularOpacities.grade.mr === null
				|| this.reportState.irregularOpacities.grade.lr === null
				|| this.reportState.irregularOpacities.grade.ul === null
				|| this.reportState.irregularOpacities.grade.ml === null
				|| this.reportState.irregularOpacities.grade.ll === null
			) {
				errors.push('Indicate irregular opacities grade');
			}
		}

		if (this.reportState.largeOpacities === null) errors.push('Indicate large opacities');

		if (this.reportState.largeOpacities) {
			if (this.reportState.largeOpacityZones.length === 0) {
				errors.push('Indicate large opacity zones');
			}
		}

		if (this.reportState.groundGlass === null) errors.push('Indicate ground glass');

		if (this.reportState.groundGlass) {
			if (
				this.reportState.groundGlassGrade.ur === null
				|| this.reportState.groundGlassGrade.mr === null
				|| this.reportState.groundGlassGrade.lr === null
				|| this.reportState.groundGlassGrade.ul === null
				|| this.reportState.groundGlassGrade.ml === null
				|| this.reportState.groundGlassGrade.ll === null
			) {
				errors.push('Indicate ground glass grade');
			}
		}

		if (this.reportState.honeycombing === null) errors.push('Indicate honeycombing');

		if (this.reportState.honeycombing) {
			if (
				this.reportState.honeycombingGrade.ur === null
				|| this.reportState.honeycombingGrade.mr === null
				|| this.reportState.honeycombingGrade.lr === null
				|| this.reportState.honeycombingGrade.ul === null
				|| this.reportState.honeycombingGrade.ml === null
				|| this.reportState.honeycombingGrade.ll === null
			) {
				errors.push('Indicate honeycombing grade');
			}
		}

		if (this.reportState.emphysema === null) errors.push('Indicate emphysema');

		if (this.reportState.emphysema) {
			if (
				this.reportState.emphysemaGrade.ur === null
				|| this.reportState.emphysemaGrade.mr === null
				|| this.reportState.emphysemaGrade.lr === null
				|| this.reportState.emphysemaGrade.ul === null
				|| this.reportState.emphysemaGrade.ml === null
				|| this.reportState.emphysemaGrade.ll === null
			) {
				errors.push('Indicate emphysema grade');
			}
		}

		if (this.reportState.mosaicAttenuation === null) errors.push('Indicate mosaic attenuation');

		if (this.reportState.mosaicAttenuation) {
			if (
				this.reportState.mosaicAttenuationGrade.ur === null
				|| this.reportState.mosaicAttenuationGrade.mr === null
				|| this.reportState.mosaicAttenuationGrade.lr === null
				|| this.reportState.mosaicAttenuationGrade.ul === null
				|| this.reportState.mosaicAttenuationGrade.ml === null
				|| this.reportState.mosaicAttenuationGrade.ll === null
			) {
				errors.push('Indicate mosaic attenuation grade');
			}
		}

		return errors;
	};

	lungDone = (): boolean => this.lungErrors().length === 0;

	pleuraErrors = (): string[] => {
		const errors: string[] = [];

		if (this.reportState.pleuralDisease === null) {
			errors.push('Indicate pleural disease found');
		}

		if (
			this.reportState.pleuralDisease
			&& this.reportState.pleuralDiseases.length === 0
		) {
			errors.push('Indicate pleural disease(s)');
		}

		if (this.reportState.significantSolidNodules === null) {
			errors.push('Indicate significant solid nodules present');
		}

		if (this.reportState.significantSolidNodules) {
			if (this.reportState.solidLesions.length === 0) {
				errors.push('Indicate solid lesions');
			}
		}

		if (this.reportState.significantSubSolidNodules === null) {
			errors.push('Indicate significant sub-solid nodules');
		}
		
		if (this.reportState.significantSubSolidNodules) {
			if (this.reportState.subSolidLesions.length === 0) {
				errors.push('Indicate sub-solid lesions');
			}
		}

		return errors;
	};

	pleuraDone = (): boolean => this.pleuraErrors().length === 0;

	otherAbnormalitiesErrors = (): string[] => {
		const errors: string[] = [];

		if (this.reportState.otherAbnormalities === null) {
			errors.push('Indicate other abnormalities present');
		}

		if (this.reportState.otherAbnormalities) {
			if (
				this.reportState.otherAbnormalitiesList.length === 0
				&& this.reportState.otherComments === ''
			) {
				errors.push('Indicate other abnormalities');
			}
		}

		return errors;
	};

	otherAbnormalitiesDone = (): boolean => this.otherAbnormalitiesErrors().length === 0;

	@action
	setTechniqueQuality = (value: number): void => {
		this.reportState.techniqueQuality = value;
		this.reportState.techniqueDefects = [];
		this.reportState.techniqueDefectsOther = '';

		// Unreadable nothing can be filled in
		if (value === 4) {
			const existingTechniqueQuality = this.reportState.techniqueQuality;
			this.clearReportState();
			this.reportState.techniqueQuality = existingTechniqueQuality;

			// Lung
			this.setWellDefinedRoundedOpacities(false);
			this.setIrregularOpacities(false);
			this.setLargeOpacities(false);
			this.setGroundGlass(false);
			this.setHoneycombing(false);
			this.setEmphysema(false);
			this.setMosaicAttenuation(false);
			this.reportState.predominantParenchymal = [];

			// Pleura
			this.setPleuralDisease(false);
			this.setSignificantSolidNodules(false);
			this.setSignificantSubSolidNodules(false);

			// Other
			this.setOtherAbnormalities(false);
		}
	};

	@action
	addTechniqueDefect = (value: string): void => {
		if (this.reportState.techniqueDefects.includes(value)) {
			this.reportState.techniqueDefects = this.reportState.techniqueDefects.filter(
				(e) => e !== value,
			);

			if (value === 'Other') {
				this.reportState.techniqueDefectsOther = '';
			}
		} else {
			this.reportState.techniqueDefects.push(value);
		}
	};

	@action
	setFilmNegative = (value: boolean): void => {
		if (value) {
			this.reportState.wellDefinedOpacities.present = false;
			this.reportState.irregularOpacities.present = false;
			this.reportState.largeOpacities = false;
			this.reportState.groundGlass = false;
			this.reportState.honeycombing = false;
			this.reportState.emphysema = false;
			this.reportState.mosaicAttenuation = false;
			this.reportState.pleuralDisease = false;
			this.reportState.significantSolidNodules = false;
			this.reportState.significantSubSolidNodules = false;
			this.reportState.otherAbnormalities = false;

			this.setFormTile(FormTile.OtherAbnormalities);
		}

		this.reportState.filmNegative = value;
	};

	@action
	setIrregularIntra = (value: boolean) => {
		this.reportState.irregularOpacities.intralobular = value;
	};

	@action
	setIrregularInter = (value: boolean) => {
		this.reportState.irregularOpacities.interlobular = value;
	};

	@action
	setWellDefinedProfusion = (value: number, letter: string): void => {
		if (this.reportState.wellDefinedOpacities.profusion[letter] === value) {
			this.reportState.wellDefinedOpacities.profusion[letter] = null;
		} else {
			this.reportState.wellDefinedOpacities.profusion[letter] = value;
		}
	};

	@action
	setIrregularGrade = (value: number, letter: string): void => {
		if (this.reportState.irregularOpacities.grade[letter] === value) {
			this.reportState.irregularOpacities.grade[letter] = null;
		} else {
			this.reportState.irregularOpacities.grade[letter] = value;
		}
	};

	@action
	setLargeOpacities = (value: boolean): void => {
		if (!value) {
			this.reportState.largeOpacityZones = [];
		}

		this.reportState.largeOpacities = value;
	};

	@action
	addLargeOpacityZone = (value: string): void => {
		if (this.reportState.largeOpacityZones.includes(value)) {
			this.reportState.largeOpacityZones = this.reportState.largeOpacityZones.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.largeOpacityZones.push(value);
		}
	};

	@action
	setGroundGlass = (value: boolean): void => {
		if (!value) {
			this.reportState.groundGlassGrade = {
				ur: null,
				mr: null,
				lr: null,
				ul: null,
				ml: null,
				ll: null,
			};
		}

		this.reportState.groundGlass = value;
	};

	@action
	setHoneycombing = (value: boolean): void => {
		if (!value) {
			this.reportState.honeycombingGrade = {
				ur: null,
				mr: null,
				lr: null,
				ul: null,
				ml: null,
				ll: null,
			};
		}

		this.reportState.honeycombing = value;
	};

	@action
	setGroundGlassGrade = (value: number, letter: string): void => {
		if (this.reportState.groundGlassGrade[letter] === value) {
			this.reportState.groundGlassGrade[letter] = null;
		} else {
			this.reportState.groundGlassGrade[letter] = value;
		}
	};

	@action
	setHoneycombingGrade = (value: number, letter: string): void => {
		if (this.reportState.honeycombingGrade[letter] === value) {
			this.reportState.honeycombingGrade[letter] = null;
		} else {
			this.reportState.honeycombingGrade[letter] = value;
		}
	};

	@action
	setEmphysema = (value: boolean): void => {
		if (!value) {
			this.reportState.emphysemaGrade = {
				ur: null,
				mr: null,
				lr: null,
				ul: null,
				ml: null,
				ll: null,
			};
		}

		this.reportState.emphysema = value;
	};

	@action
	setMosaicAttenuation = (value: boolean): void => {
		this.reportState.mosaicAttenuation = value;
	};

	@action
	setPleuralDisease = (value: boolean): void => {
		if (!value) {
			this.reportState.pleuralDiseases = [];
		}

		this.reportState.pleuralDisease = value;
	};

	@action
	addPleuralDisease = (value: string): void => {
		if (this.reportState.pleuralDiseases.includes(value)) {
			this.reportState.pleuralDiseases = this.reportState.pleuralDiseases.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.pleuralDiseases.push(value);
		}
	};

	@action
	setSignificantSolidNodules = (value: boolean): void => {
		this.reportState.significantSolidNodules = value;
	};

	@action
	setSignificantSubSolidNodules = (value: boolean): void => {
		this.reportState.significantSubSolidNodules = value;
	};

	@action
	addSolidLesion = (value: string): void => {
		if (this.reportState.solidLesions.includes(value)) {
			this.reportState.solidLesions = this.reportState.solidLesions.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.solidLesions.push(value);
		}
	};

	@action
	addSubSolidLesion = (value: string): void => {
		if (this.reportState.subSolidLesions.includes(value)) {
			this.reportState.subSolidLesions = this.reportState.subSolidLesions.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.subSolidLesions.push(value);
		}
	};

	@action
	setEmphysemaGrade = (value: number, letter: string): void => {
		if (this.reportState.emphysemaGrade[letter] === value) {
			this.reportState.emphysemaGrade[letter] = null;
		} else {
			this.reportState.emphysemaGrade[letter] = value;
		}
	};

	@action
	setMosaicAttenuationGrade = (value: number, letter: string): void => {
		if (this.reportState.mosaicAttenuationGrade[letter] === value) {
			this.reportState.mosaicAttenuationGrade[letter] = null;
		} else {
			this.reportState.mosaicAttenuationGrade[letter] = value;
		}
	};

	@action
	setOtherAbnormalities = (value: boolean): void => {
		this.reportState.otherAbnormalities = value;

		this.reportState.otherComments = '';
	};

	@action
	setOtherAbnormalitiesList = (value: string): void => {
		if (this.reportState.otherAbnormalitiesList.includes(value)) {
			this.reportState.otherAbnormalitiesList = this.reportState.otherAbnormalitiesList.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.otherAbnormalitiesList.push(value);
		}
	};

	@action
	setWellDefinedRoundedOpacities = (value: boolean): void => {
		if (!value) {
			this.reportState.wellDefinedOpacities = {
				present: false,
				p: null,
				q: null,
				r: null,
				profusion: {
					ur: null,
					mr: null,
					lr: null,
					ul: null,
					ml: null,
					ll: null,
				},
			};
		} else {
			this.reportState.wellDefinedOpacities.present = value;
		}
	};

	@action
	setIrregularOpacities = (value: boolean): void => {
		if (!value) {
			this.reportState.irregularOpacities = {
				present: false,
				interlobular: null,
				intralobular: null,
				grade: {
					ur: null,
					mr: null,
					lr: null,
					ul: null,
					ml: null,
					ll: null,
				},
			};
		} else {
			this.reportState.irregularOpacities.present = value;
		}
	};

	@action
	setWellDefinedRoundedLetter = (value: boolean, letter: string): void => {
		this.reportState.wellDefinedOpacities[letter] = value;
	};

	@action
	addPredominantParenchymal = (value: string): void => {
		if (this.reportState.predominantParenchymal.includes(value)) {
			this.reportState.predominantParenchymal = this.reportState.predominantParenchymal.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.predominantParenchymal.push(value);
		}
	};

	@action
	handleOtherTechniqueChange = (e: ChangeEvent<HTMLInputElement>): void => {
		this.reportState.techniqueDefectsOther = e.target.value;
	};

	@action
	handleOtherCommentsChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
		this.reportState.otherComments = e.target.value;
	};

	@action
	addImpression = (value: string): void => {
		if (this.reportState.impressions.includes(value)) {
			this.reportState.impressions = this.reportState.impressions.filter(
				(e) => e !== value,
			);
		} else {
			this.reportState.impressions.push(value);
		}
	};

	getGradeSum = (obj: {
		[id: string]: number
	}): number => {
		let sum = 0;

		Object.keys(obj).forEach((key) => {
			if (obj[key]) {
				sum += obj[key];
			}
		});

		return sum;
	};

	//
	//
	//      END HELPER FUNCTIONS
	//
	//------------------------------
	//
	//      Preview creation
	//
	//

	createFormModel = async () => {
		const { report } = this.props;
		
		return axios
			.post(`${SERVER_URL}/generateFormModel`, {
				ReportState: JSON.stringify(this.reportState),
				ReportRequestId: report.id,
				RadiologistId: store.radiologist.id,
			})
			.then((res) => {
				console.log(res.data);
				return res.data;
			})
			.catch((err: AxiosError | Error) => {
				console.error(err.message);
				return null;
			});
	};

	setFormTile(tileType: FormTile) {
		if (
			this.reportState.techniqueQuality === 4
			&& tileType !== FormTile.PreviewReport
			&& tileType !== FormTile.IndicateTechnique
		) {
			alert('Study has been marked un-readable', 'error');
		} else {
			const { setFormTile } = this.props;
			setFormTile(tileType);
		}
	}
}
