/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import ProduceReportTile from 'Views/Tiles/ProduceReportTile';
import ReportSubmittedTile from 'Views/Tiles/ReportSubmittedTile';
import OutstandingRequestListTile from 'Views/Tiles/OutstandingRequestListTile';
// % protected region % [Add any extra imports here] on begin
import { observable, action } from 'mobx';
import { ReportRequestEntity, CheckOutEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import moment from 'moment';
import * as Models from 'Models/Entities';
import axios, { AxiosResponse } from 'axios';
import { Redirect } from 'react-router';
import { SERVER_URL } from 'Constants';
import { reportModalityOptions } from 'Models/Enums';
import { sendStatusUpdate, sendSubmissionUpdate } from 'Util/ReportUtils';
import alert from '../../Util/ToastifyUtils';
import * as ReportUtils from '../../Util/ReportUtils';

import { ILOReportState } from './ProduceILOReportTile';
import { ICOERDReportState } from './ProduceICOERDReportTile';
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] on begin
// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] off begin
export default class RequestDashboardWrappingTileTile extends React.Component<RouteComponentProps> {
// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin
	@observable
	public produceReport: ReportRequestEntity;

	@observable
	public reportSubmission: Models.ReportSubmissionEntity;

	@observable
	public currentCheckOut: CheckOutEntity;

	@observable
	public reportSubmitted: ReportRequestEntity;

	@observable
	public submittingReport: boolean = false;
	
	@observable
	public completedReports: boolean;
	// % protected region % [Add class properties here] end

	public render() {
		let contents;
		// let contents = (
		// 	<>
		// 		<OutstandingRequestListTile {...this.props} />
		// 		<ProduceReportTile {...this.props} />
		// 		<ReportSubmittedTile {...this.props} />
		// 	</>
		// );

		// % protected region % [Override contents here] on begin
		const { match, history, location } = this.props;
		
		if (this.produceReport && this.produceReport.id !== undefined) {
			contents = (
				<ProduceReportTile
					match={match}
					history={history}
					location={location}
					report={this.produceReport}
					next={this.nextReport}
					completedReports={this.completedReports}
					createReportSubmission={this.createReportSubmission}
					unassignCurrentReport={this.unassignCurrentReport}
					submittingReport={this.submittingReport}
					setSubmittingReport={this.setSubmittingReport}
					backToRequestList={this.backToRequestList}
				/>
			);
		} else if (this.reportSubmitted) {
			contents = (
				<ReportSubmittedTile
					match={match}
					history={history}
					location={location}
				/>
			);
		} else {
			contents = (
				<OutstandingRequestListTile 
					match={match}
					history={history}
					location={location}
					setReport={this.setReport} 
				/>
			);
		}

		if (!store.verifiedCerts) {
			contents = <Redirect to={`/profile/${store.userId}`} />;
		}
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	@action
	public setSubmittingReport = (value: boolean) => {
		this.submittingReport = value;
	};

	@action
	public backToRequestList = () => {
		this.produceReport = new ReportRequestEntity();
	};
	
	@action
	public createReportSubmission = async (reportData: {
		timeStarted: Date,
		timeCompleted: Date,
		reportState: ILOReportState | ICOERDReportState | undefined,
		requireAppointment: boolean,
		reportStateObject: string,
	}, report: ReportRequestEntity, formModel: {}): Promise<boolean> => {
		const reportSubmission = new Models.ReportSubmissionEntity();

		// from report request
		reportSubmission.patientId = report.patientId;
		reportSubmission.firstName = report.firstName;
		reportSubmission.lastName = report.lastName;
		reportSubmission.imageDate = report.imageDate;
		reportSubmission.dateOfBirth = report.dateOfBirth;
		reportSubmission.orderingFacility = report.orderingFacility;
		reportSubmission.classificationPurpose = report.classificationPurpose;

		// computed at submission time
		reportSubmission.timeStarted = reportData.timeStarted;
		reportSubmission.timeCompleted = reportData.timeCompleted;
		reportSubmission.requireAppointment = reportData.requireAppointment;

		// reportState
		reportSubmission.reportState = JSON.stringify(reportData.reportState);

		// radiologist
		reportSubmission.radiologist = store.radiologist;
		reportSubmission.radiologistId = store.radiologist.id;

		// reportRequest
		reportSubmission.reportRequest = report;
		reportSubmission.reportRequestId = report.id;
		
		reportSubmission.formModel = JSON.stringify(formModel);
		
		// ReportSubmission will only be a final report if it's a single read submission. Even the final read of a
		// TwoRead report won't be the "final" report because that status is reserved for the final adjudicated report
		reportSubmission.finalReport = !report.twoReads;

		this.reportSubmission = reportSubmission;
		
		// Return true if submitting report doesn't encounter an error
		return await this.submitReport() !== null;
	};
	
	private async submitReport(): Promise<ReportRequestEntity | null> {
		// Get latest reportRequest information
		await ReportUtils.syncReportDataWithModel(this.produceReport);

		// Get initial reads remaining so we can check if it's changed after adjudication
		const initialReads = this.produceReport.readsRemaining;

		// Check that the report has been updated
		if (this.produceReport === null) {
			alert('Error fetching report details from the server.', 'error');
			return this.produceReport;
		}
		
		// Verify that the report can be submitted
		if (!await ReportUtils.verifyReportCanBeSubmitted(this.produceReport)) {
			return this.produceReport;
		}

		// Decrement reads remaining
		this.produceReport.decrementReadsRemaining();
		
		// Save report submission so the backend logic can retrieve it
		await this.reportSubmission.saveWithRadiologistAndReport();
		await this.produceReport.saveWithCheckOut();
		
		// Single read (ICOERD or single ILO)
		if (!this.produceReport.twoReads) {
			const transmissionSucceeded = await this.transmitFinalReport(this.reportSubmission);
			
			if (!transmissionSucceeded) {
				return null;
			}
		}

		// Double read (or more)
		if (this.produceReport.twoReads && this.produceReport.reportModality === reportModalityOptions.ILO) {
			// LUNSD-160 Go for adjudication after every read and handle the ReadsRemaining logic on the serverside
			const adjudicationSucceeded = await this.adjudicateIloReport(this.produceReport);

			if (!adjudicationSucceeded) {
				return null;
			}
		}

		// Get updated radiologist and report details
		await store.updateRadiologist();
		await ReportUtils.syncReportDataWithModel(this.produceReport);

		// If readsRemaining is the same as it was before adjudication, then
		// adjudication failed and another read was added
		// 1 -> (decrement) -> 0 -> (adjudication) -> (adjudication failed) -> 1 read added
		const readsAdded = initialReads === this.produceReport.readsRemaining;
		sendStatusUpdate(this.produceReport.id, readsAdded);
		sendSubmissionUpdate(this.reportSubmission.id);

		return this.produceReport;
	}

	private adjudicateIloReport(report: ReportRequestEntity): Promise<boolean> {
		return axios
			.post(
				`${SERVER_URL}/api/entity/ReportRequestEntity/AdjudicateILOReport`,
				{
					ReportId: report.id,
					UserId: store.radiologist.id,
				},
			)
			.then(() => true)
			.catch(async (error) => {
				this.produceReport.incrementReadsRemaining();
				await this.produceReport.saveWithCheckOut();
				await this.reportSubmission.delete();

				this.setSubmittingReport(false);

				alert(
					`Error adjudicating report\n${error.response.data}`,
					'error',
				);
				console.error(
					`Error adjudicating report: ${error.response.data}`,
				);
				return false;
			});
	}

	private async transmitFinalReport(reportSubmission: Models.ReportSubmissionEntity): Promise<boolean> {		
		return axios
			.post(
				`${SERVER_URL}/api/entity/ReportRequestEntity/TransmitFinalReport`,
				{
					SubmissionId: reportSubmission.id,
					Adjudicated: reportSubmission.adjudicated,
				},
			)
			.then(async () => {
				this.produceReport.setCompleted();
				await this.produceReport.saveWithCheckOut();
				await store.updateRadiologist();

				return true;
			})
			.catch(async (error) => {
				this.produceReport.incrementReadsRemaining();
				await this.produceReport.saveWithCheckOut();
				this.reportSubmission.delete();

				this.setSubmittingReport(false);

				alert(
					`Error transmitting report to Portal\n${error.response.data}`,
					'error',
				);
				console.error(
					`Error transmitting report to Portal: ${error.response.data}`,
				);
				return false;
			});
	}

	@action
	public setCompletedReports(): void {
		this.completedReports = true;
	}

	@action
	public nextReport = async (currentReportId: string): Promise<ReportRequestEntity | null> => axios.post(
		`${SERVER_URL}/api/entity/ReportRequestEntity/NextReport`,
		{
			reportId: currentReportId,
			userId: store.userId,
			inMemoryIds: store.inMemoryQueue.map((r) => r.id),
		},
	).then(async (res: AxiosResponse) => {
		if (res.data === '') {
			this.setCompletedReports();
				
			this.backToRequestList();

			return null;
		} 
		await this.unassignCurrentReport();

		const reportRequest = new ReportRequestEntity(res.data);

		if (reportRequest.id === currentReportId) {
			this.backToRequestList();

			return null;
		} 
		this.setReport(reportRequest);
					
		return res.data as ReportRequestEntity;
	}).catch((response) => {
		console.error(response);
		return null;
	});

	@action
	public setReport = (report: ReportRequestEntity): void => {
		if (store.radiologist) {
			if (this.checkExclusionLogic(report)) {
				this.checkoutReport(report);
				this.produceReport = report;
			}
		}
	};

	private checkExclusionLogic = (report: ReportRequestEntity): boolean => this.checkCertifications() && this.checkReadsLimit(report) && this.checkAssignedLimit(report) && this.checkReportLimit();

	// check if the radiologist has the required certificates and that they have not expired. If not, return false and an alert with the reason.
	private checkCertifications = (): boolean => {
		const hasMedicalRegistrationNumber = !!store.radiologist.medicalRegistrationNumber;
		const expiredMedicalRegistrationNumber = moment(store.radiologist.medicalRegistrationNumberExpiry).isBefore(moment.now());
		const hasDNRMEDepartmentApprovalRegistration = !!store.radiologist.dnrmeDepartmentApprovalRegistration;
		const expiredDNRMEDepartmentApprovalRegistration = moment(store.radiologist.dnrmeDepartmentApprovalRegistrationExpiry).isBefore(moment.now());
		const hasNioshBReaderCertificate = !!store.radiologist.nioshBReaderCertificate;
		const expiredNioshBReaderCertificate = moment(store.radiologist.nioshBReaderCertificateExpiry).isBefore(moment.now());
		const hasAnnualMedicalIndemnityInsurance = !!store.radiologist.annualMedicalIndemnityInsurance;
		const expiredAnnualMedicalIndemnityInsurance = moment(store.radiologist.annualMedicalIndemnityInsuranceExpiry).isBefore(moment.now());
		const hasRanzacRegistration = !!store.radiologist.ranzacRegistration;
		const expiredRanzacRegistration = moment(store.radiologist.ranzacRegistrationExpiry).isBefore(moment.now());

		if (hasMedicalRegistrationNumber && hasDNRMEDepartmentApprovalRegistration && hasNioshBReaderCertificate && hasAnnualMedicalIndemnityInsurance && hasRanzacRegistration) {
			if (expiredMedicalRegistrationNumber || expiredDNRMEDepartmentApprovalRegistration || expiredNioshBReaderCertificate || expiredAnnualMedicalIndemnityInsurance || expiredRanzacRegistration) {
				alert('Certification expired. See administrator to update.', 'error');
				return false;
			}
			return true;
		}

		alert('Missing certification. See administrator to update.', 'error');
		return false;
	};
	
	// check that the report still needs reads.
	private checkReadsLimit = (report: ReportRequestEntity): boolean => {
		if (report.readsRemaining > 0) {
			return true;
		}

		alert('Required Reports Fulfilled.', 'error');
		return false;
	};

	// check that the number of radiologists who have checked out the report is less than the reads remaining. If not, unless that radiologist is one of the checkedOut id's, return false
	private checkAssignedLimit = (report: ReportRequestEntity): boolean => {
		const checkedOut = report.checkOuts || [];
		if (report.readsRemaining > checkedOut.length) {
			return true;
		}

		if (store.userId) {
			if (checkedOut.filter((checkOut) => checkOut.radiologistId === store.userId).length) {
				return true;
			}
		}

		alert('Report Has Been Checked Out By Another User.', 'error');
		return false;
	};

	// check that the radiologist has not hit their periodic report limit
	private checkReportLimit = (): boolean => {
		let startPeriod: moment.Moment;
		const endPeriod = moment();
		switch (store.radiologist.reportTimespan) {
			case 'DAILY':
				startPeriod = moment().subtract(1, 'day');
				break;
			case 'WEEKLY':
				startPeriod = moment().subtract(1, 'week');
				break;
			case 'MONTHLY':
				startPeriod = moment().subtract(1, 'month');
				break;
			default:
				startPeriod = moment().subtract(1, 'month');
				break;
		}
		const limit = store.radiologist.reportLimit || 0;
		const submittedReports = store.radiologist.reportSubmissions;
		const reportsInPeriod = submittedReports.filter((report) => moment(startPeriod).isBefore(moment(report.created)) && moment(endPeriod).isAfter(moment(report.created)));
		if (reportsInPeriod.length < limit) {
			return true;
		}
		
		alert('Exceeded report limit.', 'error');
		return false;
	};

	// assign a radiologist to a report request.
	@action
	public checkoutReport = (report: ReportRequestEntity): Promise<void> => store.updateRadiologist()
		.then(action(() => {
			if (store.userId) {
				const checkedOut = report.checkOuts || [];
				if (!report.urgent) {
					report.urgent = false;
				}
					
				const reportAlreadyCheckedOut = checkedOut.filter((checkOut) => checkOut.radiologistId === store.userId).length > 0;
					
				const radiologistHasExistingCheckout = store.radiologist.checkOut != null;
		
				if (radiologistHasExistingCheckout || reportAlreadyCheckedOut) {
					return this.unassignCurrentReport(store.radiologist.checkOut);
				}
			}
			
			return null;
		}))
		.then(action(async () => {
			const checkOut = new Models.CheckOutEntity();
			checkOut.reportRequest = report;
			checkOut.reportRequestId = report.id; 
			checkOut.radiologist = store.radiologist;
			checkOut.radiologistId = store.radiologist.id;
			checkOut.expiry = moment.utc().add(30, 'minutes').toDate();
			await checkOut.saveWithRadiologistAndReport();
			this.currentCheckOut = checkOut;
		}));

	public unassignCurrentReport = async (checkout = this.currentCheckOut): Promise<void> => {
		if (checkout) {
			await checkout.delete();
		} else {
			alert('Error Removing Check Out', 'error');
		}
	};
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
