/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
// % protected region % [Add any extra imports here] on begin
import { observable, action } from 'mobx';
import moment from 'moment';
import axios, {AxiosError, AxiosResponse} from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import { store } from 'Models/Store';
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
import alert from '../../Util/ToastifyUtils';
import * as ReportUtils from '../../Util/ReportUtils';
import { ReportRequestEntity } from '../../Models/Entities';
import { SERVER_URL } from '../../Constants';

import ProduceILOReportTile, { ILOReportState } from './ProduceILOReportTile';
import ProduceICOERDReportTile, { ICOERDReportState } from './ProduceICOERDReportTile';
import { gql } from 'apollo-boost';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// % protected region % [Add any extra imports here] end

// % protected region % [Add any custom interface here] on begin
interface ProduceReportProps extends RouteComponentProps {
	report: ReportRequestEntity;
	next?: (id: string) => Promise<ReportRequestEntity | null>;
	completedReports?: boolean;
	// Use this to save the report submission
	createReportSubmission?: (
		reportData: {
			timeStarted: Date,
			timeCompleted: Date,
			reportState: ILOReportState | ICOERDReportState | undefined,
			requireAppointment: boolean,
			reportStateObject: string,
		},
		report: ReportRequestEntity,
		formModel: {}
	) => Promise<boolean>;
	unassignCurrentReport?: () => void;
	submittingReport?: boolean;
	setSubmittingReport?: (value: boolean) => void;
	backToRequestList: () => void;
}

export enum FormTile {
	IndicateImageQuality,
	ParenchymalAbnormalities,
	PleuralAbnormalities,
	OtherAbnormalities,
	ReportDetails,
	PreviewReport,
	ReportSubmitted,
	IndicateTechnique,
	Lung,
	Pleura,
}

export interface ReportProps {
	report: ReportRequestEntity;
	formState: FormTile;
	setPreview?: () => void;
	setFormTile: Function;
	toggleStandards: Function;
	openStudy: Function;
	base64Preview: string;
	setBase64Preview: Function;
	reportSnapshot: ICOERDReportState | ILOReportState | undefined;
}

interface RefObject<T> {
	readonly current: T | null;
}

function queryStandards() {
	return gql`
		query standardsImagesEntity($args: [WhereExpressionGraph]) {
			standardsImagesEntitys(where: $args) {
				id
				name
				location
			}
		}
	`;
}

interface StandardImageEntity {
	id: string,
	name: string, 
	location: string
}

// % protected region % [Add any custom interface here] end

@observer
// % protected region % [Add any customisations to default class definition here] on begin
export default class ProduceReportTile extends React.Component<ProduceReportProps> {
	// % protected region % [Add any customisations to default class definition here] end
	// % protected region % [Add class properties here] on begin
	@observable
	private formState: FormTile;

	private base64Preview: string = '';

	private timeStarted: Date = new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss'));

	private ICORef: RefObject<ProduceICOERDReportTile>;

	private ILORef: RefObject<ProduceILOReportTile>;

	private reportSnapshot: ICOERDReportState | ILOReportState | undefined;

	private formModel: {};


	private standards: Array<StandardImageEntity> = [];

	constructor(props: ProduceReportProps) {
		super(props);

		this.setFormTile = this.setFormTile.bind(this);
		this.ILORef = React.createRef<ProduceILOReportTile>();
		this.ICORef = React.createRef<ProduceICOERDReportTile>();
	}

	componentDidMount(): void {
		// Open Easyviz study
		this.openStudy();

		const { report } = this.props;
		this.goToStart(report.reportModality);

		// Unassign report on page refresh
		window.onbeforeunload = () => {
			this.unassignReport();
		};

		this.getStandards();
	}

	componentWillUnmount() {
		// Unassign report when leaving report builder (navigating away)
		this.unassignReport();
	}

	componentDidUpdate(prevProps: ProduceReportProps): void {
		const { report } = this.props;

		// If it's a new report close the EasyViz study and open the new one
		if (prevProps.report.imageId !== report.imageId) {
			this.timeStarted = new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss'));

			this.openStudy();
		}
	}

	private async getStandards() {
		store.apolloClient
			.query({
				query: queryStandards()
			})
			.then((d) => {
				d.data.standardsImagesEntitys.forEach((element: StandardImageEntity) => this.standards.push(element));
			})
	}
	// % protected region % [Add class properties here] end

	public render() {
		let contents = null;

		// % protected region % [Override contents here] on begin
		const { report, submittingReport } = this.props;

		const reportProps: ReportProps = {
			report,
			formState: this.formState,
			setFormTile: this.setFormTile,
			toggleStandards: this.toggleStandards,
			openStudy: this.openStudy,
			base64Preview: this.base64Preview,
			setBase64Preview: this.setBase64Preview,
			reportSnapshot: this.reportSnapshot,
		};

		contents = (
			<>
				<div className="report-container">
					{this.formState !== FormTile.ReportDetails
						&& this.formState !== FormTile.PreviewReport && (
						<>
							{report.reportModality === 'ILO' && (
								<ProduceILOReportTile
									ref={this.ILORef}
									{...reportProps}
								/>
							)}

							{report.reportModality === 'ICOERD' && (
								<ProduceICOERDReportTile
									ref={this.ICORef}
									{...reportProps}
								/>
							)}
						</>
					)}

					{this.formState === FormTile.ReportDetails && (
						<div className="report-form">{this.renderReportDetails()}</div>
					)}

					{this.formState === FormTile.PreviewReport && (
						<div className="report-form">{this.renderReportPreview()}</div>
					)}
				</div>

				<div className="report-footer">
					<button
						type="button"
						className="btn btn--solid"
						onClick={() => this.backToRequest()}
					>
						Back to request list
					</button>

					<button type="button" className="btn btn--solid" onClick={() => this.nextReport()}>
						Skip request
					</button>

					<div className="primary-btns">
						{this.formState === FormTile.ReportDetails && (
							<>
								<button
									type="button"
									className="btn btn--solid"
									onClick={() => this.backPage()}
								>
									Back
								</button>
								<button
									type="button"
									className="btn btn--solid"
									onClick={() => this.previewReport()}
								>
									Preview Report
								</button>
							</>
						)}

						{this.formState === FormTile.PreviewReport && (
							<>
								<button
									type="button"
									className="btn btn--solid"
									onClick={(): void => this.backPage(3)}
								>
									Back
								</button>

								{submittingReport ? (
									<CustomSpinner />
								) : (
									<button
										type="button"
										className="btn btn--solid"
										onClick={() => this.submitReport()}
									>
										Submit Report
									</button>
								)}
							</>
						)}

						{this.formState === FormTile.ReportSubmitted && (
							<>
								<button
									type="button"
									className="btn btn--solid"
									onClick={() => this.nextReport()}
								>
									Next Report
								</button>
							</>
						)}
					</div>

					{this.formState !== FormTile.ReportSubmitted
						&& this.formState !== FormTile.ReportDetails
						&& this.formState !== FormTile.PreviewReport && (
						<div className="report-details-footer">
							<button
								type="button"
								className="btn btn--solid"
								onClick={() => this.nextPage()}
							>
									Next
							</button>
						</div>
					)}
				</div>
			</>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	// % protected region % [Add class methods here] on begin
	unassignReport = (): void => {
		const { unassignCurrentReport } = this.props;

		if (unassignCurrentReport) {
			unassignCurrentReport();
		}
	};

	@action
	nextPage = async () => {
		const { report } = this.props;

		if (
			report.reportModality === 'ICOERD'
			&& this.ICORef.current?.validateReport()
			&& this.ICORef.current?.reportState
		) {
			this.reportSnapshot = this.ICORef.current?.reportState;
			this.formModel = await this.ICORef.current?.createFormModel();

			this.setFormTile(FormTile.ReportDetails);
		} else if (
			report.reportModality === 'ILO'
			&& this.ILORef.current?.validateReport()
			&& this.ILORef.current?.reportState
		) {
			this.reportSnapshot = this.ILORef.current?.reportState;
			this.formModel = await this.ILORef.current?.createFormModel();

			this.setFormTile(FormTile.ReportDetails);
		}
	};

	@action
	previewReport = (): void => {
		const { report } = this.props;
		let url = '';
		
		if (
			report.reportModality === 'ICOERD'
			&& this.formModel
			&& 'TechniqueQuality1' in this.formModel
		) {
			url = `${SERVER_URL}/createIcoPreviewFromModel`;
		} else if (
			report.reportModality === 'ILO'
			&& this.formModel
			&& 'FILM_QUALITY' in this.formModel
		) {
			url = `${SERVER_URL}/createIloPreviewFromModel`;
		}

		if (url.length !== 0) {
			axios
				.post(url, {
					"FormModel": this.formModel
				})
				.then((res: AxiosResponse<string>) => {
					this.base64Preview = res.data;
					if (this.base64Preview) {
						this.setFormTile(FormTile.PreviewReport);
					}
				})
				.catch((err) => {
					alert('An error occured creating the report preview.', 'error');
					console.error(err);
				});
		} else {
			alert(
				'An error occured creating the report preview. Go back and try again.',
				'error',
			);
		}
	};

	goToStart = (modality: string): void => {
		if (modality === 'ILO') {
			this.ILORef.current && this.ILORef.current.clearReportState();
			this.setFormTile(FormTile.IndicateImageQuality);
		} else if (modality === 'ICOERD') {
			this.ICORef.current?.clearReportState();
			this.setFormTile(FormTile.IndicateTechnique);
		}

		const { setSubmittingReport } = this.props;
		setSubmittingReport && setSubmittingReport(false);
	};

	backPage = (value?: number): void => {
		if (value) {
			this.setFormTile(value);
		} else {
			this.setFormTile(FormTile.OtherAbnormalities);
		}
	};

	openStudy = (): void => {
		const { report } = this.props;

		axios.post(
			`${SERVER_URL}/getStudy`, { StudyUUID: report.imageId, UserId: store.userId, },
		).then((res) => {
			window.open(res.data, "_LungScreen_ImageViewer", "top=0,left=0,width=" + window.screen.availWidth + ",height=" + window.screen.availHeight);
		}).catch((err: AxiosError) => {
			if (err.response) {
				const errMessage = err.response.data.Message;
				console.error(errMessage);
				alert(errMessage, 'error');
			} else {
				console.error(err);
			}
		});
	};

	openStandard = (standardId: string): void => {
		const { report } = this.props;

		axios.post(
			`${SERVER_URL}/getStudy`, { StandardUUID: standardId, StudyUUID: report.imageId, UserId: store.userId, },
		).then((res) => {
			window.open(res.data);
		}).catch((err: AxiosError) => {
			if (err.response) {
				const errMessage = err.response?.data.replace(/^Error: /g, 'Easyviz: ');
				console.error(errMessage);
				alert(errMessage, 'error');
			} else {
				console.error(err);
			}
		});
	};

	@action
	toggleStandards = (): void => {
		store.modal.show(
			'Standards List',
			<>
				<h4>Standards List</h4>
				<div className="standards__list">
					{this.standards.map((standard) => (
						<div
							className="standard-button"
							onClick= { () => {
								var newWindow = window.open(`/standards?id=${standard.id}`, "_blank", "top=500,left=500,width=800,height=auto");
							}}
							role="button"
							tabIndex={0}
							key={standard.name}
						>
							<span>{standard.name}</span>
						</div>
					))}
				</div>
				<div className="modal-controls">
					<button
						className="btn btn--solid"
						type="button"
						onClick={() => store.modal.hide()}
					>
						Close
					</button>
				</div>
			</>,
		);
	};

	nextReport = async () => {
		const { next, report } = this.props;

		if (next) {
			const nextReport = await next(report.id);

			if (nextReport !== null) {
				this.goToStart(nextReport.reportModality);
				this.openStudy();
			} else {
				this.backToRequest();
			}
		}
	};

	backToRequest = () => {
		const { backToRequestList } = this.props;

		backToRequestList();
	};

	@action
	submitReport = async (shouldSendBackToRequestList: boolean = true) => {
		const { createReportSubmission, report, setSubmittingReport } = this.props;

		// Create a temp variable to check report validity
		const temp = await ReportUtils.syncReportDataWithModel(report);

		// Check that the report has been assigned
		if (temp === null) {
			alert(
				'An unknown error occured while fetching current report data',
				'error',
			);
		}

		// Verify that the report can be submitted
		if (!await ReportUtils.verifyReportCanBeSubmitted(temp)) {
			this.backToRequest();
			return;
		}
		
		if (createReportSubmission && setSubmittingReport) {
			setSubmittingReport(true);

			createReportSubmission(
				{
					timeStarted: this.timeStarted,
					timeCompleted: new Date(moment.utc().add(10, 'h').format('yyyy-MM-DD HH:mm:ss')),
					reportState: this.reportSnapshot,
					requireAppointment: true,
					reportStateObject: JSON.stringify(this.reportSnapshot),
				},
				report,
				this.formModel,
			).then((success) => {
				if (!shouldSendBackToRequestList && success) {
					this.reportSnapshot = undefined;
					this.nextReport();
					setSubmittingReport(false);
				} else if (success) {
					this.backToRequest();
				}
			});
		}
	};

	renderReportDetails = (): React.ReactNode => {
		const { report } = this.props;

		return (
			<div className="report-details-container">
				<div className="report-details-col">
					<div className="report-details-pair">
						<span className="detail-label">Patient name:</span>
						<span className="detail-content">
							{report.firstName} 
							{' '}
							{report.lastName}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">D.O.B:</span>
						<span className="detail-content">
							{moment(report.dateOfBirth).format('DD-MM-YYYY')}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Patient ID:</span>
						<span className="detail-content">{report.patientId}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Date of Radiograph:</span>
						<span className="detail-content">
							{moment(report.imageDate).format('DD-MM-YYYY')}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Ordering Physician:</span>
						<span className="detail-content">{report.physicianName}</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Ordering Facility:</span>
						<span className="detail-content">{report.orderingFacility}</span>
					</div>
				</div>
				<div className="report-details-col">
					<div className="report-details-pair">
						<span className="detail-label">Classification Purpose:</span>
						<span className="detail-content">
							{report.classificationPurpose}
						</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Type of reading:</span>
						<span className="detail-content">B</span>
					</div>
					<div className="report-details-pair">
						<span className="detail-label">Date of reading:</span>
						<span className="detail-content">
							{moment.utc().add(10, 'h').format('DD-MM-YYYY')}
						</span>
					</div>
					<div className="report-details-filler"> </div>
				</div>
			</div>
		);
	};

	renderReportPreview = (): React.ReactNode => (
		<Document className="pdf-preview" file={`data:application/pdf;base64,${this.base64Preview}`}>
			<Page scale={1.2} pageNumber={1} />
			<Page scale={1.2} pageNumber={2} />
		</Document>
	);

	@action
	setFormTile(tileType: FormTile) {
		this.formState = tileType;
	}

	@action
	setBase64Preview(data: string) {
		this.base64Preview = data;
	}

	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
