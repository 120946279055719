/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { Model, IModelAttributes, attribute, entity } from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import { VisitorsGroupReferenceManyToMany } from '../Security/Acl/VisitorsGroupReferenceManyToMany';
import { AdminGroupReferenceManyToMany } from '../Security/Acl/AdminGroupReferenceManyToMany';
import { RadiologistGroupReferenceManyToMany } from '../Security/Acl/RadiologistGroupReferenceManyToMany';

// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IReportRequestGroupAttributes extends IModelAttributes {
	reportRequestId: string;
	groupId: string;

	reportRequest: Models.ReportRequestEntity | Models.IReportRequestEntityAttributes;
	group: Models.GroupEntity | Models.IGroupEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ReportRequestGroup')
export default class ReportRequestGroup extends Model implements IReportRequestGroupAttributes {
	public static acls: IAcl[] = [
		new VisitorsGroupReferenceManyToMany(),
		new AdminGroupReferenceManyToMany(),
		new RadiologistGroupReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public reportRequestId: string;

	@observable
	@attribute()
	public groupId: string;

	@observable
	@attribute({isReference: true})
	public reportRequest: Models.ReportRequestEntity;

	@observable
	@attribute({isReference: true})
	public group: Models.GroupEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IReportRequestGroupAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.reportRequestId) {
				this.reportRequestId = attributes.reportRequestId;
			}
			if (attributes.groupId) {
				this.groupId = attributes.groupId;
			}

			if (attributes.reportRequest) {
				if (attributes.reportRequest instanceof Models.ReportRequestEntity) {
					this.reportRequest = attributes.reportRequest;
					this.reportRequestId = attributes.reportRequest.id;
				} else {
					this.reportRequest = new Models.ReportRequestEntity(attributes.reportRequest);
					this.reportRequestId = this.reportRequest.id;
				}
			} else if (attributes.reportRequestId !== undefined) {
				this.reportRequestId = attributes.reportRequestId;
			}

			if (attributes.group) {
				if (attributes.group instanceof Models.GroupEntity) {
					this.group = attributes.group;
					this.groupId = attributes.group.id;
				} else {
					this.group = new Models.GroupEntity(attributes.group);
					this.groupId = this.group.id;
				}
			} else if (attributes.groupId !== undefined) {
				this.groupId = attributes.groupId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}