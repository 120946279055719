/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import { Store, store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import React from 'react';
import { RadiologistEntity } from 'Models/Entities';
// % protected region % [Add any extra imports here] end

export function getFrontendNavLinks(pageProps: RouteComponentProps): ILink[][] {
	// % protected region % [Add any logic before displaying page links here] on begin
	const bottomNavSection = [
		{ label: 'Report Requests', path: '/requestdashboard', className: 'requests-nav-btn' },
		{ label: 'History', path: '/history', className: 'history-nav-btn' },
		{ label: 'Profile', path: `/profile/${store.userId}`, className: 'profile-nav-btn' },
		{ label: 'Logout', path: '/logout', className: 'logout-nav-btn' },
	];
	
	if (store.isAdjudicator) {
		bottomNavSection.unshift({
			label: 'Adjudication Requests',
			path: '/adjudicationpage',
			className: 'adjudication-nav-btn',
		});
	}
	
	if(store.isCardiology || store.hasBackendAccess){
		bottomNavSection.unshift({
			label: 'Cardiology Requests', 
			path: '/cardiology', 
			className: 'requests-nav-btn'
		});

	}


	// % protected region % [Add any logic before displaying page links here] end
	return [
		[
			// % protected region % [Customise top nav section here] on begin
			
			// % protected region % [Customise top nav section here] end
		],
		[
			// % protected region % [Customise middle nav section here] on begin
			// % protected region % [Customise middle nav section here] end
		],
		[
			// % protected region % [Customise bottom nav section here] on begin
			...bottomNavSection,
			// % protected region % [Customise bottom nav section here] end
		],
	];
}
